import React, { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MdAddCircle, MdDeleteOutline } from "react-icons/md";
import { Tag } from "primereact/tag";
function AlcoholOptionsList({ data, onDelete, onEdit, header, filters }) {
  //FIXME supporting data ---edit FS_Options
  const featuresetOptionsForList = [
    { label: "CAS", value: "CAS" },
    { label: "ALCOHOL", value: "ALCOHOL" },
    { label: "DMS", value: "DMS" },
  ];

  const InputType = [
    { label: "Number", value: "number" },
    { label: "Radio", value: "radio" },
    { label: "Dropdown", value: "dropdown" },
  ];

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [selectedFsOption, setSelectedFsOption] = useState(null);
  const [EditDialogVisible, setEditDialogVisible] = useState(false);
  const [menuOptions, setmenuOptions] = useState([{ id: 1 }]);
  //Opens delete dialog
  const openDeleteDialog = (rowData) => {
    setDeleteDialogVisible(true);
    setSelectedFsOption(rowData);
  };

  //TODO handle the delete call here
  const handleConfirmDelete = () => {
    try {
      onDelete(selectedFsOption?.featureset_option_id);
      setDeleteDialogVisible(false);
    } catch (error) {
      setDeleteDialogVisible(false);
    }
  };

  //FIXME opening the Edit Dialog -------- Edit FS-Options Function
  const openEditDialog = (rowData) => {
    setEditDialogVisible(true);
    setSelectedFsOption(rowData);
    let menuData = rowData.featureset_option_dropmenu;
    setmenuOptions(menuData);
  };

  //TODO handle Edit here-------Edit FS-Options Function

  const handleDetailsOptions2 = (field, value) => {
    // Create a copy of the existing detailsOptions array
    //condition to set the menuoption as empty array when user selects anything accept dropdown;
    if (field === "featureset_option_dropmenu" && value !== "dropdown") {
      setmenuOptions([]);
    }
    setSelectedFsOption((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleMenuOptions = (index, field, value) => {
    // Create a copy of the existing detailsOptions array
    const newMenuOptions = [...menuOptions];
    // If the index is within the array bounds, update the specific field
    if (newMenuOptions[index]) {
      newMenuOptions[index][field] = value;
    } else {
      // If the index is out of bounds, create a new object at that index and set the field
      newMenuOptions[index] = { [field]: value };
    }
    // Update the state with the modified detailsOptions array
    setmenuOptions(newMenuOptions);
  };

  const handleAddMoreMenu = () => {
    // Add a new empty object to the detailsOptions array
    setmenuOptions([...menuOptions, { id: menuOptions.length + 1 }]);
  };

  const handleConfirmEdit = () => {
    try {
      selectedFsOption.featureset_option_dropmenu = JSON.stringify(menuOptions);
      if (selectedFsOption.featureset_option_input_type === "dropdown") {
        onEdit(selectedFsOption?.featureset_option_id, selectedFsOption);
      } else {
        selectedFsOption.featureset_option_dropmenu = JSON.stringify([]);
        onEdit(selectedFsOption?.featureset_option_id, selectedFsOption);
      }
      setEditDialogVisible(false);
    } catch (error) {
      setEditDialogVisible(false);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEdit
          title="Edit"
          //TODO add the edit click here
          onClick={() => openEditDialog(rowData)}
          className="mr-2 cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          //TODO add the delete click here
          onClick={() => openDeleteDialog(rowData)}
          className="mx-2 cursor-pointer text-red-600"
        />
      </div>
    );
  };

  const DeleteDialogBox = () => {
    return (
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => {
          setDeleteDialogVisible(false);
        }}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={() => {
                handleConfirmDelete();
              }}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={() => {
                setDeleteDialogVisible(false);
              }}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          Are you sure you want to delete this device?
        </div>
      </Dialog>
    );
  };

  //TODO --fix the edit UI - Edit FS-Options Dialog Box

  return (
    <div className="card">
      <DeleteDialogBox />
      <Dialog
        visible={EditDialogVisible}
        onHide={() => {
          setEditDialogVisible(false);
        }}
        style={{ width: "35vw", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit Featureset Option"
        className="p-fluid dark:bg-gray-900"
        footer={
          <div>
            <button
              type="submit"
              className="ml-auto flex items-center  rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
              onClick={() => {
                handleConfirmEdit();
              }}
            >
              Update
            </button>
          </div>
        }
      >
        <div className="flex flex-col justify-between gap-4 rounded-lg">
          <div className="flex flex-wrap justify-between gap-3 rounded px-5 py-2">
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-2">
              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  <InputText
                    id={`featureset_option_name`}
                    name={`featureset_option_name`}
                    className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                    onChange={(e) =>
                      handleDetailsOptions2(
                        "featureset_option_name",
                        e.target.value
                      )
                    }
                    value={selectedFsOption?.featureset_option_name}
                  />
                  <label
                    htmlFor={`featureset_option_name`}
                    className="dark:text-gray-300"
                  >
                    Name
                  </label>
                </span>
              </div>
              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  <InputText
                    id="featureset_option_label"
                    name="featureset_option_label"
                    className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                    onChange={(e) =>
                      handleDetailsOptions2(
                        "featureset_option_label",
                        e.target.value
                      )
                    }
                    value={selectedFsOption?.featureset_option_label}
                  />
                  <label
                    htmlFor="featureset_option_label"
                    className="dark:text-gray-300"
                  >
                    Label
                  </label>
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-2">
              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  <InputText
                    id="featureset_option_placeholder"
                    name="featureset_option_placeholder"
                    className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                    onChange={(e) =>
                      handleDetailsOptions2(
                        "featureset_option_placeholder",
                        e.target.value
                      )
                    }
                    value={selectedFsOption?.featureset_option_placeholder}
                  />
                  <label
                    htmlFor="featureset_option_placeholder"
                    className="dark:text-gray-300"
                  >
                    Placeholder
                  </label>
                </span>
              </div>
              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  <Dropdown
                    id="featureset_option_input_type"
                    name="featureset_option_input_type"
                    options={InputType}
                    className={`p-dropdown h-9 border text-sm `}
                    onChange={(e) =>
                      handleDetailsOptions2(
                        "featureset_option_input_type",
                        e.value
                      )
                    }
                    value={
                      selectedFsOption?.featureset_option_input_type || null
                    }
                  />
                  <label
                    htmlFor="featureset_option_for"
                    className="dark:text-gray-300"
                  >
                    Input type
                  </label>
                </span>
              </div>
            </div>

            {/* TODO till line 219 solve the dropdown value option */}

            {selectedFsOption?.featureset_option_input_type === "dropdown" ? (
              <div
                className={`mx-auto mt-8 flex w-full flex-wrap items-center`}
              >
                {menuOptions?.map((MenuOptions, key) => (
                  <>
                    <div className="grid grid-cols-2 gap-4 xl:grid-cols-2">
                      <span className="p-float-label">
                        <InputText
                          id="featureset_option_dropmenu_key"
                          name="featureset_option_dropmenu_key"
                          className="my-4 flex-1 border py-1.5 pl-2 text-sm dark:bg-navy-200"
                          onChange={(e) => {
                            handleMenuOptions(key, "label", e.target.value);
                          }}
                          value={menuOptions[key].label}
                        />
                        <label
                          htmlFor="featureset_option_dropmenu_key"
                          className="dark:text-gray-300"
                        >
                          Option Label :
                        </label>
                      </span>

                      <span className="p-float-label">
                        <InputText
                          id="featureset_option_dropmenu_value"
                          name="featureset_option_dropmenu_value"
                          className=" my-4 flex-1 border py-1.5 pl-2 text-sm dark:bg-navy-200"
                          onChange={(e) => {
                            handleMenuOptions(key, "value", e.target.value);
                          }}
                          value={menuOptions[key].value}
                        />
                        <label
                          htmlFor="featureset_option_dropmenu_value"
                          className="dark:text-gray-300"
                        >
                          Option Value
                        </label>
                      </span>
                    </div>
                  </>
                ))}

                <MdAddCircle
                  className="align-center mr-2 w-[100%] text-lg text-gray-700"
                  onClick={handleAddMoreMenu}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-2">
              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  {selectedFsOption?.featureset_option_input_type ===
                  "radio" ? (
                    <InputText
                      required
                      type="number"
                      id="featureset_option_default_value"
                      name="featureset_option_default_value"
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      min={0}
                      max={1}
                      onChange={(e) => {
                        parseInt(e.target.value);
                        if (e.target.value < 0) e.target.value = 0;
                        if (e.target.value > 1) e.target.value = 1;
                        handleDetailsOptions2(
                          "featureset_option_default_value",
                          e.target.value
                        );
                      }}
                      value={parseInt(
                        selectedFsOption?.featureset_option_default_value
                      )}
                    />
                  ) : (
                    <InputText
                      type="number"
                      id="featureset_option_default_value"
                      name="featureset_option_default_value"
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      value={selectedFsOption?.featureset_option_default_value}
                      onChange={(e) =>
                        handleDetailsOptions2(
                          "featureset_option_default_value",
                          e.target.value
                        )
                      }
                    />
                  )}

                  <label
                    htmlFor="featureset_option_default_value"
                    className="dark:text-gray-300"
                  >
                    Default Value
                  </label>
                </span>
              </div>

              <div className={`mx-auto mt-8 w-full`}>
                <span className="p-float-label">
                  <InputText
                    id="featureset_group_by"
                    name="featureset_group_by"
                    className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                    onChange={(e) =>
                      handleDetailsOptions2(
                        "featureset_group_by",
                        e.target.value
                      )
                    }
                    value={selectedFsOption?.featureset_group_by}
                  />
                  <label
                    htmlFor="featureset_group_by"
                    className="dark:text-gray-300"
                  >
                    Group by
                  </label>
                </span>
              </div>
            </div>
            <div
              className={`mx-auto mt-8 grid w-full grid-cols-2 md:grid-cols-2`}
            >
              <span className="p-float-label">
                <Dropdown
                  id="featureset_option_for"
                  name="featureset_option_for"
                  options={featuresetOptionsForList}
                  className={`p-dropdown h-9 border text-sm `}
                  onChange={(e) =>
                    handleDetailsOptions2("featureset_option_for", e.value)
                  }
                  value={selectedFsOption?.featureset_option_for || null}
                />
                <label
                  htmlFor="featureset_option_for"
                  className="dark:text-gray-300"
                >
                  Option for
                </label>
              </span>
            </div>
          </div>
        </div>
      </Dialog>
      <DataTable
        value={data}
        removableSort
        paginator
        header={header}
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "featureset_option_default_value",
          "featureset_option_for",
          "featureset_option_label",
          "featureset_option_name",
          "featureset_option_input_type",
        ]}
        emptyMessage="No Option found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>
        <Column
          field="featureset_option_for"
          header="Category"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>
        <Column
          field="featureset_option_name"
          header="Name"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>
        <Column
          field="featureset_option_label"
          header="Label"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>

        <Column
          header="Type"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
          body={(rowData) => {
            return (
              <Tag
                value={rowData.featureset_option_input_type}
                className="h-5 rounded-sm bg-gray-200 text-xs font-normal text-gray-800"
              />
            );
          }}
        ></Column>
        <Column
          field="featureset_option_default_value"
          header="Default value"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200 "
          style={{
            minWidth: "6rem",
            textAlign: "left",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
        ></Column>
      </DataTable>
    </div>
  );
}

export default AlcoholOptionsList;
