import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ContactsList from "./components/ContactsList";
// import ContactsGrid from "./components/ContactGrid";
import { Toast } from "primereact/toast";
import { FiPlus } from "react-icons/fi";
import { Dropdown } from "primereact/dropdown";
import PreloaderList from "components/skeleton-preloader/PreloaderList";

const Contacts = () => {
  const token = Cookies.get("token");
  const user_uuid = Cookies.get("user_uuid");
  const [isDialog, setIsDialog] = useState(false);
  const [addData, setAddData] = useState({});
  const [contactsData, setContactsData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [refresh, setRefresh] = useState(false);
  const toastRef = useRef(null);
  // const [isListView, setIsListView] = useState(
  //   localStorage.getItem("viewPreference") === "grid" ? false : true
  // );
  const [loaded, setLoaded] = useState(false);
  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to check for exactly 10 digits
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const validateForm = () => {
    let errors = {};

    if (!addData.contact_name) {
      errors.contact_name = "Name is required";
    }

    if (!addData.contact_info) {
      errors.contact_info = "Field is required";
    }

    return errors;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getContact`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setLoaded(true);
        const formatedData = res.data.data.map((item, ind) => ({
          ...item,
          serialNo: ind + 1,
          full_name: item.contact_name,
        }));
        setContactsData(formatedData);
      })
      .catch((err) => {
        console.log(err);
        setLoaded(true);
      });
  }, [refresh, token, user_uuid]);

  const openDialog = () => {
    setIsDialog(true);
  };

  const closeDialog = () => {
    setIsDialog(false);
    setFormErrors({});
  };

  //onChange function
  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedFormErrors = { ...formErrors };
    updatedFormErrors[name] = undefined;

    setFormErrors(updatedFormErrors);
    setAddData({ ...addData, [name]: value });
  };
  // const handleToggleView = () => {
  //   const newView = !isListView;
  //   setIsListView(newView);
  //   // Store the view preference in localStorage
  //   localStorage.setItem("viewPreference", newView ? "list" : "grid");
  // };

  //handleSubmit

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (
      !isValidPhoneNumber(addData.contact_info) &&
      addData.contact_type === "Phone"
    ) {
      toastRef.current.show({
        severity: "warn",
        summary: "Invalid Phone Number",
        detail: "Please enter a 10-digit valid phone number.",
        life: 3000,
      });
      return;
    }

    if (Object.keys(errors).length === 0) {
      if (addData.contact_primary !== "1") {
        addData.contact_primary = "2";
      }

      axios
        .post(`${process.env.REACT_APP_AWS_URL1}/addContact`, addData, {
          headers: { Authorization: `${token}` },
        })
        .then(() => {
          setRefresh(!refresh);
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: `Contact ${addData.contact_name} added successfully!`,
            life: 3000,
          });
          closeDialog();
        })
        .catch((err) => {
          if (err.response.request.status === 400) {
            toastRef.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "Contact already exists with the provided email or mobile",
              life: 3000,
            });
          } else {
            toastRef.current.show({
              severity: "error",
              summary: "Error",
              detail: "Failed to add contact. Please try again.",
              life: 3000,
            });
          }
        });
    } else {
      toastRef.current.show({
        severity: "warn",
        summary: "Incomplete form",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
    }
  };

  //edit contacts api
  const editContacts = (contact_uuid, editData) => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/editContact/${contact_uuid}`,
        {
          contact_name: editData.contact_name,
          contact_primary: editData.contact_primary,
          contact_type: editData.contact_type,
          contact_info: editData.contact_info,
          contact_active: editData.contact_active,
        },

        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(() => {
        setRefresh(!refresh);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Contact ${editData.contact_name} updated successfully!`,
          life: 3000,
        });
      })
      .catch((err) => {
        if (err.response.request.status === 400) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "Contact already exists with the provided email or mobile",
            life: 3000,
          });
        } else {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to edit contact. Please try again.",
            life: 3000,
          });
        }
      });
  };

  const deleteContact = (contact_uuid, contact_name) => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/deleteContact/${contact_uuid}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then(() => {
        setRefresh(!refresh);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Contact ${contact_name} deleted successfully!`,
          life: 3000,
        });
      })
      .catch(() => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete contact. Please try again.",
          life: 3000,
        });
      });
  };

  const optionsAdd = [
    { label: "Phone", value: "Phone" },
    { label: "Email", value: "Email" },
  ];

  return (
    <>
      <Toast ref={toastRef} position="top-right" />

      <div className="flex-col justify-between">
        <div className="flex justify-between">
          <div>
            <h4 className="text-dark pt-3 text-xl font-bold dark:text-white">
              Contacts
            </h4>
          </div>
          {/* <div className="pt-2">
            <button
              className={`${
                isListView === true
                  ? "list-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                  : "list-btn bg-white px-2 py-1  dark:bg-gray-150 "
              }`}
              onClick={handleToggleView}
            >
              <BsListUl />
            </button>
            <button
              className={`${
                isListView === false
                  ? "grid-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                  : "grid-btn bg-white px-2 py-1  dark:bg-gray-150 "
              }`}
              onClick={handleToggleView}
            >
              <BsGrid />
            </button>
          </div> */}
        </div>
        <div>
          <button
            className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            onClick={openDialog}
          >
            <FiPlus />
            &nbsp;New Contact
          </button>
        </div>

        {/* dialog to add contact */}
        <Dialog
          visible={isDialog}
          onHide={closeDialog}
          style={{ width: "30%", height: "fit-content" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header="Fill the details"
          modal
          className="p-fluid dark:bg-gray-900"
        >
          <form onSubmit={handleSubmit} className="dark:text-gray-900">
            <div className="mx-auto mt-8 ">
              <span className={`p-float-label `}>
                <InputText
                  id="contact_name"
                  name="contact_name"
                  onChange={handleChange}
                  className={`border py-1.5 pl-2 text-sm ${
                    formErrors.contact_name ? "border-red-600" : ""
                  }`}
                />
                <label
                  htmlFor="contact_name"
                  className="text-sm dark:text-gray-300"
                >
                  Full Name
                </label>
              </span>
              {formErrors.contact_name && (
                <small className="text-red-600">
                  {formErrors.contact_name}
                </small>
              )}
            </div>
            {/*creating radio button here*/}
            <div className="mx-auto mt-7 grid w-full grid-cols-2 md:grid-cols-2 2xl:grid-cols-2">
              <span className="p-float-label">
                <Dropdown
                  id="contact_type"
                  name="contact_type"
                  options={optionsAdd}
                  optionLabel="label"
                  optionValue="value"
                  className={`p-dropdown h-8 border`}
                  onChange={handleChange}
                  value={addData.contact_type}
                />
                <label htmlFor="device_type" className="dark:text-gray-300">
                  Contact Type
                </label>
              </span>
              {addData.contact_type === "Email" ? (
                <>
                  <span className={`p-float-label`}>
                    <InputText
                      id="contact_email"
                      name="contact_info"
                      type="email"
                      onChange={handleChange}
                      className={`border py-1 pl-2${
                        formErrors.contact_info ? "border-red-600" : ""
                      }`}
                    />
                    <label
                      htmlFor="contact_info"
                      className="text-sm dark:text-gray-300"
                    >
                      Email
                    </label>
                  </span>
                  {formErrors.contact_info && (
                    <small className="text-red-600">
                      {formErrors.contact_info}
                    </small>
                  )}
                </>
              ) : (
                <>
                  <span className={`p-float-label `}>
                    <InputText
                      id="contact_mobile"
                      name="contact_info"
                      keyfilter="pint"
                      onChange={handleChange}
                      className={`border py-1 pl-2 ${
                        formErrors.contact_info ? "border-red-600" : ""
                      }`}
                    />
                    <label
                      htmlFor="contact_info"
                      className="text-sm dark:text-gray-300"
                    >
                      Mobile Number
                    </label>
                  </span>

                  {formErrors.contact_info && (
                    <small className="text-red-600">
                      {formErrors.contact_info}
                    </small>
                  )}
                </>
              )}
            </div>
            {/* primary contact or not */}
            <div className="mx-auto mt-3 flex flex-wrap gap-3">
              <span className="align-center ml-2 flex w-[100%] flex-wrap">
                <input
                  type="checkbox"
                  onChange={handleChange}
                  name="contact_primary"
                  id="contact_primary"
                  value="1"
                ></input>
                <label
                  htmlFor="contact_primary"
                  className="ml-2 text-xs text-blue-600 dark:text-gray-300"
                >
                  Set this as primary contact
                </label>
              </span>
            </div>
            {/* Primary contact ended */}

            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
              >
                Add Contact
              </button>
            </div>
          </form>
        </Dialog>
        {loaded ? (
          // isListView ? (
          <div className="mt-[-4px]">
            <ContactsList
              contactsData={contactsData}
              editContacts={editContacts}
              deleteContact={deleteContact}
            />
          </div>
        ) : (
          //   ) : (
          //     <div className="mt-[-4px]">
          //       <ContactsGrid
          //         data={contactsData}
          //         onEditContact={editContacts}
          //         onDeleteContact={deleteContact}
          //       />
          //     </div>
          //   )
          <div className="mt-6">
            <PreloaderList />
          </div>
        )}
      </div>
    </>
  );
};

export default Contacts;
