import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
// import { CiMenuKebab } from "react-icons/ci";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiInputChecked, TiTick } from "react-icons/ti";
import { MdDeleteOutline } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import { MultiSelect } from "primereact/multiselect";
const CustomersList = ({ data, onDelete, onUpdate }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [customerData, setCustomerData] = useState(data);
  const toastRef = useRef(null);
  const toastErr = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const user_type = Cookies.get("user_type");
  // function formatDateToYYYYMMDD(timeString) {
  //   // Extract date and time components
  //   const [datePart, timePart] = timeString.split(" ");
  //   const [year, month, day] = datePart.split("-");
  //   const [hours, minutes, seconds] = timePart.split(":").map(Number);

  //   // Add 5 hours and 30 minutes
  //   let newHours = (hours + 5) % 24;
  //   let newMinutes = (minutes + 30) % 60;
  //   let newDay = parseInt(day);
  //   let newMonth = parseInt(month);
  //   let newYear = parseInt(year);

  //   // Adjust newHours and newDay if necessary
  //   if (newHours + 5 >= 24) {
  //     newHours -= 24;
  //     newDay += 1;
  //     if (newDay > 31) {
  //       newDay = 1;
  //       newMonth += 1;
  //       if (newMonth > 12) {
  //         newMonth = 1;
  //         newYear += 1;
  //       }
  //     }
  //   }

  //   // Format the new date and time
  //   const newDateString = `${newDay.toString().padStart(2, "0")}-${newMonth
  //     .toString()
  //     .padStart(2, "0")}-${newYear} `;
  //   const newTimeString = `${newHours.toString().padStart(2, "0")}:${newMinutes
  //     .toString()
  //     .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  //   // Combine the new date and time
  //   const newTime = newDateString + newTimeString;

  //   return newTime;
  // }

  const StatusOptions = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  useEffect(() => {
    setCustomerData(
      data.map((customer, index) => ({
        ...customer,
        serialNo: index + 1,
      }))
    );
  }, [data]);

  // Edit Customer Dialog code
  const EditCustomerDialog = ({ visible, onHide, customer }) => {
    const [editedCustomerData, setEditedCustomerData] = useState(
      customer || {}
    );
    const [isUpdating, setIsUpdating] = useState(false);

    const onSave = async () => {
      if (
        editedCustomerData.first_name === "" ||
        editedCustomerData.last_name === ""
      ) {
        toastRef.current.show({
          severity: "warn",
          summary: "Missing field",
          detail: "Field cannot be empty",
          life: 3000,
        });
        return;
      }

      try {
        setIsUpdating(true);
        await onUpdate(editedCustomerData.user_id, editedCustomerData);
        setEditDialogVisible(false);
      } catch (error) {
        setIsUpdating(false);
      }
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;

      setEditedCustomerData((prevState) => {
        const updatedData = { ...prevState, [name]: value };

        if (name === "first_name") {
          updatedData.full_name = `${value} ${prevState.last_name || ""}`;
        } else if (name === "last_name") {
          updatedData.full_name = `${prevState.first_name || ""} ${value}`;
        }

        return updatedData;
      });
    };

    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Edit Customer"
        footer={
          <div>
            <Button
              label={isUpdating ? "Updating..." : "Update"}
              className="ml-auto rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
              disabled={isUpdating}
              onClick={onSave}
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <span className="p-float-label mt-6">
              <InputText
                id="first_name"
                name="first_name"
                value={editedCustomerData?.first_name || ""}
                onChange={handleInputChange}
                className={`border py-1.5 pl-2 text-sm ${
                  !editedCustomerData.first_name ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="first_name" className="dark:text-gray-300">
                First Name
              </label>
            </span>
            <span className="p-float-label mt-6">
              <InputText
                id="last_name"
                name="last_name"
                value={editedCustomerData?.last_name || ""}
                onChange={handleInputChange}
                className={`border py-1.5 pl-2 text-sm ${
                  !editedCustomerData.last_name ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="last_name" className="dark:text-gray-300">
                Last Name
              </label>
            </span>
            <span className="p-float-label mt-6">
              <Dropdown
                id="status"
                name="user_is_active"
                options={StatusOptions}
                value={editedCustomerData?.user_is_active || ""}
                onChange={handleInputChange}
                className={`h-9 border pl-0 text-sm ${
                  !editedCustomerData.user_is_active ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="status" className="dark:text-gray-300">
                Status
              </label>
            </span>
          </div>
        </div>
      </Dialog>
    );
  };

  // Delete Customer Dialog code
  const DeleteCustomerDialog = ({ visible, onHide, customer }) => {
    const handleConfirmDelete = async () => {
      try {
        // Send the delete request using the onDelete prop
        await onDelete(customer.user_id);
        data.map((ele) => {
          if (ele.user_id === customer.user_id) {
            ele.user_is_active = customer.user_is_active;
          }
          return null;
        });
        // If the delete is successful, update the customerData state to remove the deleted customer

        onHide();
      } catch (error) {
        onHide();
        toastErr.current.show({
          severity: "danger",
          summary: "Error",
          detail: "Error while deleting",
          life: 3000,
        });
      }
    };

    // Delete dialog
    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleConfirmDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          Are you sure you want to delete {selectedCustomer?.full_name}?
        </div>
      </Dialog>
    );
  };

  // Global Filter
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="mb-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  // Action menu
  const actionBodyTemplate = (rowData) => {
    const handleEdit = () => {
      setEditedCustomer(rowData);
      setEditDialogVisible(true);
    };

    const handleDelete = () => {
      setSelectedCustomer(rowData);
      setDeleteDialogVisible(true);
    };

    return (
      <div className="flex text-lg">
        <FaRegEdit
          title="Edit"
          onClick={handleEdit}
          className="mr-2 cursor-pointer text-gray-700"
        />
        {rowData.user_is_active !== 0 && (
          <RiDeleteBin6Line
            title="Delete"
            onClick={
              rowData.user_is_active === 2 ? handleDelete : console.log()
            }
            className={`mx-2 cursor-pointer ${
              rowData.user_is_active === 2 ? "text-red-600" : "text-gray-500"
            }`}
          />
        )}
      </div>
    );
  };

  // status body
  const getStatusSeverity = (option) => {
    switch (option) {
      case 1:
        return "success";

      case 2:
        return "danger";

      default:
        return "null";
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData.user_is_active === 1
            ? "Active"
            : rowData.user_is_active === 2
            ? "Deactive"
            : "Deleted"
        }
        severity={getStatusSeverity(rowData.user_is_active)}
        className="h-5 rounded-sm text-xs font-normal"
      />
    );
  };

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
  ];

  const [selected, setSelected] = useState([]);

  const assignVehicles = () => {
    return (
      <>
        <MultiSelect
          placeholder="Select vehicles"
          maxSelectedLabels={2}
          options={options}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          className="md:w-20rem h-7 w-fit border"
          selectAllLabel="Select all vehicles"
        />
        <button
          type="submit"
          className="ml-2 rounded-full bg-green-500 p-0.5 text-white"
        >
          <TiTick />
        </button>
      </>
    );
  };
  // Customers List
  return (
    <div>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <Toast ref={toastErr} className="bg-red-400" />

      <DataTable
        removableSort
        value={customerData}
        selection={selectedCustomer}
        onSelectionChange={(e) => setSelectedCustomer(e.value)}
        dataKey="user_id"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        filterDisplay="menu"
        header={header}
        filters={filters}
        globalFilterFields={[
          "full_name",
          "email",
          "org_name",
          "phone",
          "user_status",
          "userTypeName",
        ]}
        emptyMessage="No customers found."
      >
        <Column
          key="user_uuid"
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        />
        <Column
          key="user_uuid"
          field="full_name"
          header="Name"
          style={{ minWidth: "8rem", padding: "5px" }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          body={(rowData) => (
            <>
              {rowData?.first_name?.charAt(0).toUpperCase() +
                rowData?.first_name?.slice(1)}{" "}
              {rowData?.last_name?.charAt(0).toUpperCase() +
                rowData?.last_name?.slice(1)}
            </>
          )}
        />
        <Column
          key="user_uuid"
          field="email"
          header="Email"
          style={{ minWidth: "8rem", padding: "5px" }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
        />
        {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <Column
            key="user_uuid"
            field="org_name"
            header="Company Name"
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "12rem", padding: "5px" }}
          />
        )}

        {/* <Column
          key="user_uuid"
          field="last_login"
          header="Last Login"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          body={(rowData) => (
            <div>
              <span>
                {rowData.last_login
                  ? formatDateToYYYYMMDD(rowData.last_login)
                  : null}
              </span>
            </div>
          )}
        /> */}
        <Column
          key="user_uuid"
          field="email_verified"
          header="Verified"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "5rem", padding: "5px" }}
          body={(rowData) => (
            <div>
              <span>
                {rowData.email_verified === 1 ? (
                  <TiInputChecked className={`h-6 w-6 text-green-600`} />
                ) : (
                  <TiInputChecked className={`h-6 w-6 text-red-500`} />
                )}
              </span>
            </div>
          )}
        />
        <Column
          key="user_uuid"
          field="userTypeName"
          header="Type"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "6rem", padding: "5px" }}
          body={(rowData) => (
            <Tag
              className="mr-2 bg-gray-200 text-gray-800"
              icon="pi pi-tag"
              style={{
                width: "fit-content",
                height: "20px",
              }}
              value={rowData.userTypeName}
            />
          )}
        />

        <Column
          key="user_uuid"
          field="user_status"
          header="Status"
          body={statusBodyTemplate}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "5rem", padding: "5px" }}
        />
        <Column
          key="user_uuid"
          header="Assign vehicles"
          body={assignVehicles}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "5rem", padding: "5px" }}
        />
        <Column
          key="user_uuid"
          header="Action"
          headerStyle={{ width: "9rem", textAlign: "left", padding: "5px" }}
          bodyStyle={{ textAlign: "left", overflow: "visible", padding: "5px" }}
          body={actionBodyTemplate}
          className="border-b dark:bg-navy-800 "
        />
      </DataTable>
      <EditCustomerDialog
        visible={editDialogVisible}
        onHide={() => setEditDialogVisible(false)}
        customer={editedCustomer}
      />
      <DeleteCustomerDialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        customer={selectedCustomer}
      />
    </div>
  );
};

export default CustomersList;
