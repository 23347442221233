import React, { useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Calendar } from "primereact/calendar";
import { MdDeleteOutline } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
import { BsFillCpuFill } from "react-icons/bs";

export default function SimList({ data, onEditSim, onDeleteSim }) {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [editData, setEditData] = useState();

  const [selectedDevice, setSelectedDevice] = useState({});
  const availability = [
    { name: "All", key: "0" },
    { name: "Linked", key: "1" },
    { name: "Unlinked", key: "2" },
  ];
  const [selected, setSelected] = useState(availability[0]);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const toastRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({
    sim_number: false,
  });
  const [viewVisible, setViewVisible] = useState(false);
  const [DataTobeShown, setDataTobeShown] = useState({});

  //Global search logic
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  //TODO view dialog
  const openViewDialog = (rowData) => {
    setViewVisible(true);
    setDataTobeShown(rowData);
  };

  const clearSearch = () => {
    setGlobalFilterValue(""); // Clear the search input value
    const _filters = { ...filters };
    _filters["global"].value = null; // Clear the global filter value
    setFilters(_filters);
  };

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based, so add 1 and pad with '0' if needed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //   const parseDateStringToDateObject = (dateString) => {

  //     if (dateString.length > 10) {
  //         return dateString;
  //     } else {
  //       console.log("getting" , dateString);

  //         console.log("getting2:::", `${dateString} 00:00:00.0000`)

  //         return `${dateString} 00:00:00.0000`;
  //     }
  // };

  //Opens delete dialog
  const openDeleteDialog = (rowData) => {
    setDeleteDialogVisible(true);
    setSelectedDevice(rowData);
  };

  //Searchbox
  const renderHeader = () => {
    return (
      <div className="my-2 flex justify-between  dark:bg-gray-950">
        <div className="flex-column flex gap-3">
          {availability?.map((item, ind) => (
            <div className="px-2" key={ind}>
              <input
                type="radio"
                id={`deviceType_${ind}`}
                value={item.key}
                checked={selected.key === item.key}
                onChange={() => setSelected(item)}
              />
              <label htmlFor={`deviceType_${ind}`} className="font-normal">
                &nbsp;{item.name}
              </label>
            </div>
          ))}
        </div>

        <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
          <i className="pi pi-search pl-2 dark:text-gray-300" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
          />
          {globalFilterValue && (
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
              onClick={clearSearch}
            />
          )}
        </span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEye
          title="View"
          onClick={() => openViewDialog(rowData)}
          className="mr-4 cursor-pointer text-gray-700"
        />
        <FaRegEdit
          title="Edit"
          onClick={() => openDialog(rowData)}
          className="mr-2 cursor-pointer text-gray-700"
        />

        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.sim_is_active === 2
              ? openDeleteDialog(rowData)
              : console.log();
          }}
          className={`mx-2 cursor-pointer ${
            rowData.sim_is_active === 2 ? "text-red-600" : "text-gray-500"
          }`}
        />
      </div>
    );
  };

  const handleConfirmDelete = () => {
    try {
      onDeleteSim(selectedDevice?.sim_id);
      setDeleteDialogVisible(false);
    } catch (error) {
      setDeleteDialogVisible(false);
    }
  };

  const header = renderHeader();

  //Delete dialog
  // const DeleteDeviceDialog = ({ visible, onHide }) => {
  //   const handleConfirmDelete = () => {
  //     try {
  //         onDeleteSim(selectedDevice?.key);
  //       onHide();
  //     } catch (error) {
  //       console.error(error);
  //       onHide();
  //     }
  //   };
  //   return (
  //   //    <Dialog
  //   //   visible={visible}
  //   //   onHide={onHide}
  //   //   header="Confirm Delete"
  //   //   footer={
  //   //     <div>
  //   //       <Button
  //   //         label="Delete"
  //   //         icon="pi pi-check"
  //   //         className="mr-2 bg-red-500 px-2 py-1 text-sm text-white dark:hover:bg-red-500 dark:hover:text-white"
  //   //         onClick={()=>{handleConfirmDelete()}}
  //   //       />
  //   //       <Button
  //   //         label="Cancel"
  //   //         icon="pi pi-times"
  //   //         className="bg-gray-600 px-2 py-1 text-sm text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
  //   //         onClick={onHide}
  //   //       />
  //   //     </div>
  //   //   }
  //   // >
  //   //   <div>Are you sure you want to delete this device?</div>
  //   // </Dialog>
  //   );
  // };

  // Opens edit dialog
  const openDialog = (rowData) => {
    setIsDialogVisible(true);

    // console.log("newRowData : ", rowData);
    setEditData(rowData);
  };
  const optionsActive = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  //Closes edit dialog
  const closeDialog = () => {
    setIsDialogVisible(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editData.sim_activation_date = editData.sim_activation_date.slice(0, 10);
    editData.sim_validity = editData.sim_validity.slice(0, 10);
    if (
      e.sim_number === "" ||
      e.sim_data_pack === "" ||
      e.sim_is_active === "" ||
      e.sim_tag === "" ||
      e.simValidityDate === "" ||
      e.sim_validity === ""
    ) {
      setValidationErrors({
        sim_number: !e.sim_number,
        sim_data_pack: !e.sim_data_pack,
        sim_is_active: !e.sim_is_active,
        sim_tag: !e.sim_tag,
        simValidityDate: !e.simValidityDate,
        sim_validity: !e.sim_validity,
      });
    } else {
      const dataEditted = {
        sim_data_pack: editData.sim_data_pack,
        sim_tag: editData.sim_tag,
        sim_activation_date: editData.sim_activation_date,
        sim_validity: editData.sim_validity,
        sim_is_active: editData.sim_is_active,
        sim_amount: editData.sim_amount,
      };
      onEditSim(editData.sim_id, dataEditted);
      closeDialog();
    }
  };

  const handleChange = (e) => {
    const value = e.target ? e.target.value : e.value;
    const name = e.target ? e.target.name : e.name;
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleChangeDate = (value, name) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  // Status body
  const getStatusSeverity = (option) => {
    switch (option) {
      case 1:
        return "success";

      case 2:
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.sim_is_active === 1 ? "Active" : "Deactive"}
        severity={getStatusSeverity(rowData.sim_is_active)}
        className="h-5 rounded-sm text-xs font-normal"
      />
    );
  };

  const availabilityTemplate = (rowData) => {
    return (
      <BsFillCpuFill
        className={`h-5 w-5 ${
          rowData.linked === null ? "text-gray-600" : "text-green-600"
        }`}
      />
    );
  };
  //edit dialog
  return (
    <div className="card">
      <Dialog
        visible={viewVisible}
        onHide={() => {
          setViewVisible(false);
        }}
        style={{ width: "35rem", height: "fit-content" }}
        header="Sim Details"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <div className="grid grid-cols-2 2xl:grid-cols-2">
          <div>
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Number
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown.sim_number}
            </p>
          </div>
          <div>
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Tag
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown.sim_tag}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 2xl:grid-cols-2">
          <div className="mt-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Amount
            </p>
            <p className="overflow-hidden break-words rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown.sim_amount}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Validity
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {new Date(DataTobeShown?.sim_validity).toLocaleDateString(
                "en-GB"
              )}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 2xl:grid-cols-2">
          <div className="mt-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Assigned To
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown.linked_device}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Data Pack
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown.sim_data_pack}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Status
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {DataTobeShown?.sim_is_active === 1 ? "Active" : "Deactive"}
            </p>
          </div>
          <div className="my-2">
            <p className="py-1 pl-2 text-xs font-semibold dark:text-gray-400">
              Activation Date
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {new Date(DataTobeShown?.sim_activation_date).toLocaleDateString(
                "en-GB"
              )}
            </p>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={isDialogVisible}
        onHide={closeDialog}
        style={{ width: "35rem", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit the Sim card"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit} className="mx-auto">
          <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div
              className={`mt-6 ${validationErrors.sim_number ? "p-error" : ""}`}
            >
              <span className="p-float-label">
                <InputText
                  id="sim_number"
                  name="sim_number"
                  disabled
                  onChange={handleChange}
                  value={editData ? editData.sim_number : null}
                  className={`ml-2 border py-1.5 pl-2 text-sm ${
                    validationErrors.sim_number ? "border-red-600" : ""
                  }`}
                />
                <label htmlFor="sim_number" className="dark:text-gray-300">
                  Sim Number
                </label>
              </span>
              {validationErrors.sim_number && (
                <small className="text-red-600">Device ID is required</small>
              )}
            </div>
            <div
              className={`mt-6 ${
                validationErrors.sim_data_pack ? "p-error" : ""
              }`}
            >
              <span className="p-float-label">
                <InputText
                  id="sim_data_pack"
                  name="sim_data_pack"
                  onChange={handleChange}
                  value={editData?.sim_data_pack}
                  className={`ml-2 border py-1.5 pl-2 text-sm ${
                    validationErrors.sim_data_pack ? "border-red-600" : ""
                  }`}
                />
                <label htmlFor="sim_data_pack" className="dark:text-gray-300">
                  Data Pack
                </label>
              </span>
              {validationErrors.sim_data_pack && (
                <small className="text-red-600">Data pack is required</small>
              )}
            </div>
            <div
              className={`mt-6 ${validationErrors.sim_tag ? "p-error" : ""}`}
            >
              <span className="p-float-label">
                <InputText
                  id="sim_tag"
                  name="sim_tag"
                  onChange={handleChange}
                  value={editData?.sim_tag}
                  className={`ml-2 border py-1.5 pl-2 text-sm ${
                    validationErrors.sim_tag ? "border-red-600" : ""
                  }`}
                />
                <label htmlFor="sim_tag" className="dark:text-gray-300">
                  Sim Tag
                </label>
              </span>
              {validationErrors.sim_tag && (
                <small className="text-red-600">Sim Tag is required</small>
              )}
            </div>
            <div className={`mt-6`}>
              <span className="p-float-label">
                <InputText
                  id="sim_amount"
                  name="sim_amount"
                  max={20000}
                  className={`p-dropdown ml-2 h-9 border pl-2 text-sm `}
                  onChange={handleChange}
                  value={editData?.sim_amount}
                />
                <label htmlFor="device_type" className="dark:text-gray-300">
                  Sim Amount
                </label>
              </span>
            </div>

            <div className="mt-6">
              <span className="p-float-label">
                <Calendar
                  inputId="start_date"
                  name="sim_activation_date"
                  value={
                    editData ? new Date(editData.sim_activation_date) : null
                  }
                  onChange={(e) => {
                    const formattedDate = formatDateToYYYYMMDD(e.value);
                    handleChangeDate(formattedDate, "sim_activation_date");
                  }}
                  className={`ml-2 h-9 rounded-lg border text-sm`}
                />
                <label htmlFor="start_date" className="dark:text-gray-300">
                  From
                </label>
              </span>
            </div>
            <div className="mt-6">
              <span className="p-float-label">
                <Calendar
                  inputId="end_date"
                  value={editData ? new Date(editData.sim_validity) : null}
                  onChange={(e) => {
                    const formattedDate = formatDateToYYYYMMDD(e.value);
                    handleChangeDate(formattedDate, "sim_validity");
                  }}
                  className={`ml-2 h-9 rounded-lg border text-sm`}
                />
                <label htmlFor="start_date" className="dark:text-gray-300">
                  To
                </label>
              </span>
            </div>
            <div className="mt-6">
              <span className="p-float-label">
                <Dropdown
                  id="status"
                  name="sim_is_active"
                  options={optionsActive}
                  optionLabel="label"
                  optionValue="value"
                  onChange={handleChange}
                  value={editData?.sim_is_active}
                  className="h-8 border"
                />
                <label htmlFor="status" className="dark:text-gray-300">
                  Status
                </label>
              </span>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="ml-auto flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      {/* List View  */}
      <DataTable
        value={
          selected.key === "2"
            ? data.filter((item) => item.linked === null)
            : selected.key === "1"
            ? data.filter((item) => item.linked !== null)
            : data
        }
        removableSort
        paginator
        header={header}
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "sim_number",
          "sim_amount",
          "sim_tag",
          "sim_data_pack",
          "linked_device",
        ]}
        emptyMessage="No simcards found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} simcards"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-5 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "3rem",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            paddingTop: "0px",
            paddingLeft: "2rem",
            paddingBottom: "0px",
          }}
        ></Column>
        <Column
          field="sim_number"
          header="Sim Number"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "10rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
        <Column
          header="Sim Tag"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "6rem",
            padding: "5px 0px 5px 0.5rem",
          }}
          body={(rowData) => (
            <Tag
              className="mr-2 bg-gray-200 text-gray-800"
              icon="pi pi-tag"
              style={{
                width: "fit-content",
                height: "25px",
              }}
              value={rowData.sim_tag}
            />
          )}
        ></Column>
        <Column
          field="sim_activation_date"
          header="Activation Date"
          sortable
          body={(rowData) => {
            const simActivationDate = rowData.sim_activation_date
              ? new Date(rowData.sim_activation_date)
              : null;

            return simActivationDate
              ? simActivationDate.toLocaleDateString("en-GB")
              : "--";
          }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "8rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
        <Column
          field="sim_validity"
          header="Sim Validity"
          sortable
          body={(rowData) => {
            const simValidityDate = rowData.sim_validity
              ? new Date(rowData.sim_validity)
              : null;

            return simValidityDate
              ? simValidityDate.toLocaleDateString("en-GB")
              : "--";
          }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "8rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
        <Column
          field="sim_data_pack"
          header="Data Pack"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "6rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
        <Column
          field="linked_device"
          header="Device"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "8rem",
            padding: "5px 0px 5px 0.5rem",
          }}
          body={(rowData) => {
            return <>{rowData.linked_device ? rowData.linked_device : "--"}</>;
          }}
        ></Column>

        <Column
          header="Status"
          body={statusBodyTemplate}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "7rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
        <Column
          field="linked"
          header="Linked"
          body={availabilityTemplate}
          bodyStyle={{
            overflow: "visible",
            padding: "5px 0px 5px 1rem",
            textAlign: "left",
          }}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "7rem", padding: "5px" }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "9rem",
            padding: "5px 0px 5px 0.5rem",
          }}
        ></Column>
      </DataTable>
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => {
          setDeleteDialogVisible(false);
        }}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={() => {
                handleConfirmDelete();
              }}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={() => {
                setDeleteDialogVisible(false);
              }}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          Are you sure you want to delete this device?
        </div>
      </Dialog>
    </div>
  );
}
