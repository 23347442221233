import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

//-->>fetching all the actionsOptions

//-->> tracker function Main

export default async function mainTracker(action_info, action_id) {
  const token = Cookies.get("token");
  const upa_id = uuidv4();
  const user_id = Cookies.get("user_id");
  const cookiesData = Cookies.get("session_data");
  let sessionData = JSON.parse(cookiesData);

  const session_id = sessionData.session_id;
  const action_time = new Date().getTime();
  try {
    axios.post(
      `${process.env.REACT_APP_AWS_URL1}/trackSessions`,
      { upa_id, user_id, session_id, action_time, action_id, action_info },
      { headers: { Authorization: token } }
    );
  } catch (err) {
    console.log(err);
  }
}

//use this function whenever the track session must be called
