import React from "react";
import Chart from "react-apexcharts";

const ColumnChart = (props) => {
  return (
    <div>
      <Chart
        options={props.options}
        series={props.series}
        type="bar"
        width={500}
        height={320}
      />
    </div>
  );
};

export default ColumnChart;
