import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import Cookies from "js-cookie";

export function SidebarLinks(props) {
  let location = useLocation();

  const { routes } = props;
  const { hover } = props;

  const getUserTypeFromCookie = () => Cookies.get("user_type");
  const [userType, setUserType] = useState(getUserTypeFromCookie());

  useEffect(() => {
    const handleCookieChange = () => {
      setUserType(getUserTypeFromCookie());
    };

    // Update user type when component mounts
    setUserType(getUserTypeFromCookie());

    // Listen for changes in cookies
    window.addEventListener("storage", handleCookieChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleCookieChange);
    };
  }, [userType]); // Add userType to the dependency array

  const activeRoute = useCallback(
    (routeName) => {
      return location.pathname.includes(routeName);
    },
    [location.pathname]
  );

  const filteredRoutes = useMemo(() => {
    return routes.filter((route) => {
      if (
        (route.name === "Organization" &&
          userType !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e") ||
        (route.name === "Users" &&
          userType === "f0003b02-9e34-11ee-9fc8-0a33c87d103e") ||
        (route.name === "Sim Cards" &&
          userType !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e") ||
        (route.name === "Reports" &&
          userType === "3df557db-9e3c-11ee-9fc8-0a33c87d103e") ||
        (route.name === "Alert Triggers" &&
          userType === "3df557db-9e3c-11ee-9fc8-0a33c87d103e")
      ) {
        return false;
      }
      return true;
    });
  }, [routes, userType]);

  const createLinks = useMemo(() => {
    return filteredRoutes.map((route, index) => (
      <Link key={index} to={route.path}>
        {route.icon && (
          <div
            className={`relative mb-3.5 flex flex-col items-start text-left`}
          >
            <li
              className={`tooltip-button-${index} my-auto flex items-center px-6 py-[5px]`}
              key={index}
            >
              <span
                className={`hover:text-gray-700 ${
                  activeRoute(route.path) === true
                    ? "font-bold text-activeLink dark:text-white"
                    : "font-2xl text-gray-600"
                }`}
                style={{ verticalAlign: "middle" }}
              >
                {route.icon && route.icon}
              </span>
              {hover && (
                <h1
                  className={`ml-2  ${
                    activeRoute(route.path) === true
                      ? "font-bold text-activeLink dark:text-white"
                      : "font-2xl text-gray-600"
                  }`}
                  style={{ verticalAlign: "middle" }}
                >
                  {route.title}
                </h1>
              )}
              {!hover && (
                <Tooltip
                  target={`.tooltip-button-${index}`}
                  className="custom-tooltip"
                  mouseTrackLeft={12}
                >
                  {route.title}
                </Tooltip>
              )}
            </li>
            {activeRoute(route.path) ? (
              <div className="absolute right-0 top-[4px] h-7 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
            ) : null}
          </div>
        )}
      </Link>
    ));
  }, [filteredRoutes, activeRoute, hover]);

  return createLinks;
}

export default SidebarLinks;
