import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [updateData, setUpdateData] = useState(false);
  const [featureset, setFeatureset] = useState(true);
  const [device, setDevice] = useState(true);
  const [corel, setCorel] = useState(true);
  const [org, setOrg] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [reports, setReports] = useState(false);

  const updateFS = () => setFeatureset(!featureset);
  const updateOrg = () => setOrg(!org);
  const updateCorel = () => setCorel(!corel);
  const updateDevice = () => setDevice(!device);
  const updateFunc = () => setUpdateData((prev) => !prev);
  const resetState = () => setUpdateData(false);
  const updateTrigger = () => setTrigger(!trigger);
  const updateReports = () => setReports(!reports);

  return (
    <AppContext.Provider
      value={{
        updateData,
        updateFunc,
        updateFS,
        updateDevice,
        device,
        featureset,
        resetState,
        updateCorel,
        corel,
        updateOrg,
        updateTrigger,
        reports,
        updateReports,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
