import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AddFeatureSet from "./AddFeatureSet";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import EditFeatureset from "./EditFeatureset";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { MdDeleteOutline } from "react-icons/md";

const FeatureList = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisible1, setIsDialogVisible1] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [myData, setMyData] = useState();
  const [fs, setFs] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const toastRef = useRef(null);
  const token = Cookies.get("token");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const { featureset, updateFS, updateData, resetState } =
    useContext(AppContext);

  //get list of featureset

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getFeatureset`,

        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        const formattedData = res.data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
          key: index + 1,
        }));
        setData(formattedData);
        setLoaded(false);
      })
      .catch(() => {
        setLoaded(false);
      });

    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "VehicleTypeLookup" },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setVehicleTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, fs, featureset]);

  //opens delete dialog
  const openDeleteDialog = (rowData) => {
    setSelectedFeature(rowData);
    setIsDeleteDialogVisible(true);
  };

  //closes delete dialog
  const closeDeleteDialog = () => {
    setFs(updateData);
    setIsDeleteDialogVisible(false);
  };

  const statusBodyTemplate = (rowData) => {
    return rowData.featureset_status === 1 ? (
      <Tag
        value={"Active"}
        severity={"success"}
        className="h-5 rounded-sm text-xs font-normal"
      ></Tag>
    ) : (
      <Tag
        value={"Deactive"}
        severity={"danger"}
        className="h-5 rounded-sm text-xs font-normal"
      ></Tag>
    );
  };
  //delete api call
  const handleDeleteConfirmation = async () => {
    if (selectedFeature) {
      try {
        await axios.put(
          `${process.env.REACT_APP_AWS_URL2}/deleteFeatureset/${selectedFeature?.featureset_uuid}`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
        updateFS();
        closeDeleteDialog();
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Feature Set ${selectedFeature?.featureset_name} deleted successfully`,
          life: 3000,
        });
      } catch (error) {
        closeDeleteDialog();

        if (error.response) {
          // Server responded with a status code other than 2xx
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message || "An error occurred",
            life: 3000,
          });
        } else if (error.request) {
          // The request was made but no response was received
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "No response received from the server",
            life: 3000,
          });
        } else {
          // Something happened in setting up the request
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while deleting feature set",
            life: 3000,
          });
        }
      }
    }
  };

  //closes add dialog
  const closeDialog = () => {
    setIsDialogVisible(false);
    resetState();
  };
  //opens edit dialog
  const openDialog1 = (rowData) => {
    setMyData(rowData); // Set the rowData to myData state
    setIsDialogVisible1(true);
  };
  //closes edit dialog
  const closeDialog1 = () => {
    setIsDialogVisible1(false);
    resetState();
  };

  //global search dialog
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  //searchbox
  const header = (
    <div className="mb-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEdit
          title="Edit"
          onClick={() => openDialog1(rowData)}
          className="mr-2 cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.featureset_status === 2
              ? openDeleteDialog(rowData)
              : console.log();
          }}
          className={`mx-2 cursor-pointer ${
            rowData.featureset_status === 2 ? "text-red-600" : "text-gray-500"
          }`}
        />
      </div>
    );
  };

  return (
    <>
      {/* edit dialog */}
      <Dialog
        visible={isDialogVisible1}
        onHide={closeDialog1}
        style={{ width: "70vw", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Featureset Details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <EditFeatureset
          parameters={{ propValue: myData }}
          toastRef={toastRef}
          closeDialog1={closeDialog1}
          vehicleTypes={vehicleTypes}
        />
      </Dialog>
      {/* add dialog */}
      <Dialog
        visible={isDialogVisible}
        onHide={closeDialog}
        style={{ width: "70vw", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <p className="text-right text-sm text-red-400">
          All Fields Are Required<span className="text-red-500">**</span>
        </p>
        <AddFeatureSet />
      </Dialog>

      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      {/* delete dialog */}
      <Dialog
        visible={isDeleteDialogVisible}
        onHide={closeDeleteDialog}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDeleteConfirmation}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={closeDeleteDialog}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {selectedFeature?.featureset_name}?
          </span>
        </div>
      </Dialog>
      {/* List View */}
      {loaded ? (
        <PreloaderList />
      ) : (
        <DataTable
          removableSort
          value={data}
          dataKey="featureset_uuid"
          paginator
          rows={20}
          rowsPerPageOptions={[20, 35, 50]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          filterDisplay="menu"
          filters={filters}
          globalFilterFields={[
            "featureset_name",
            "featureset_option_for",
            "featureset_users",
          ]}
          emptyMessage="No featureset found."
          header={header}
        >
          <Column
            field="serialNo"
            header="#"
            className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{
              minWidth: "4rem",
              paddingTop: "5px",
              paddingLeft: "2rem",
              paddingBottom: "5px",
            }}
            bodyStyle={{
              textAlign: "left",
              overflow: "visible",
              paddingTop: "0px",
              paddingLeft: "2rem",
              paddingBottom: "0px",
            }}
          />
          <Column
            field="featureset_name"
            header="Featureset Name"
            style={{ minWidth: "12rem", padding: "5px" }}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          />
          <Column
            header="Featureset Type"
            body={(rowData) => {
              const vehicleTypeId = rowData.featureset_type;
              const matchedVehicle = Array.isArray(vehicleTypes)
                ? vehicleTypes.find(
                    (type) => type.vehicleTypeId === vehicleTypeId
                  )
                : null;
              return (
                <Tag
                  className="mr-2 bg-gray-200 text-gray-800"
                  icon="pi pi-tag"
                  style={{
                    width: "fit-content",
                    height: "25px",
                  }}
                  value={
                    matchedVehicle ? matchedVehicle.vehicleTypeName : "Unknown"
                  }
                />
              );
            }}
            style={{ minWidth: "16rem", padding: "5px" }}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          />
          <Column
            field="featureset_status"
            header="Featureset Status"
            body={statusBodyTemplate}
            sortable
            style={{ minWidth: "8rem", padding: "5px" }}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          />
          <Column
            field="featureset_option_for"
            header="Featureset For"
            sortable
            style={{ minWidth: "6rem", padding: "5px" }}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          />
          <Column
            body={actionBodyTemplate}
            header="Action"
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "8rem", padding: "5px" }}
          />
        </DataTable>
      )}
    </>
  );
};

export default FeatureList;
