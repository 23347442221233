import React, { useEffect, useState, useRef, useContext } from "react";
import { FiPlus } from "react-icons/fi";
import CoRelationsList from "./components/AnalyticsList";
import Cookies from "js-cookie";
import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import DTV from "./components/DTV";
import { TabPanel, TabView } from "primereact/tabview";
import UTV from "./components/UTV";
import { AppContext } from "context/AppContext";

const Corelations = () => {
  const token = Cookies.get("token");
  const [dataDTV, setDataDTV] = useState([]);
  const [dataUTV, setDataUTV] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const { corel } = useContext(AppContext);
  const toastRef = useRef();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getCoRelationsUserData_DTV`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          const formattedData = res.data.data.map((item, index) => {
            const user = item.user_linked
              ? `${item.user_linked[0].first_name} ${item.user_linked[0].last_name}`
              : "Not Assigned";
            return {
              ...item,
              serialNo: index + 1,
              key: index + 1,
              user: user,
            };
          });
          setDataDTV(formattedData);
        }
        setLoaded(true);
      })
      .catch((err) => {
        setLoaded(true);
        if (err.response && err.response.data.message) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      });
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getCoRelationsUserData_UTV`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data.length > 0) {
          const formattedData = res.data.data.map((item, index) => {
            return {
              ...item,
              serialNo: index + 1,
              key: index + 1,
              user: item.first_name + " " + item.last_name,
            };
          });
          setDataUTV(formattedData);
        }
        setLoaded(true);
      })
      .catch((err) => {
        setLoaded(true);
        if (err.response && err.response.data.message) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      });
  }, [token, reload, corel]);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setReload(true);
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
        Connections
      </h4>
      <button
        className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        onClick={openDialog}
      >
        <FiPlus />
        &nbsp;New Connection
      </button>
      {loaded ? (
        <CoRelationsList dataDTV={dataDTV} dataUTV={dataUTV} toast={toastRef} />
      ) : (
        <PreloaderList />
      )}
      <Dialog
        visible={dialogVisible}
        onHide={closeDialog}
        style={{ width: "40%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <TabView>
          <TabPanel
            header="Assign device to vehicle"
            className="text-sm duration-500 ease-in-out"
          >
            <DTV closeDialog={closeDialog} toastRef={toastRef} />
          </TabPanel>
          <TabPanel
            header="Step 2 - Assign vehicle to user"
            className="text-sm duration-500 ease-in-out"
          >
            <UTV closeDialog={closeDialog} toastRef={toastRef} />
          </TabPanel>
        </TabView>
      </Dialog>
    </>
  );
};

export default Corelations;
