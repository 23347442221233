import React from "react";
import ReactApexChart from "react-apexcharts";

const GraphLoad = ({ data }) => {
  const options2 = {
    series: [
      {
        name: "Load",
        data: data.load,
        // data:[
        //   14, 20, 25, 15, 25, 28, 25, 29, 23, 26, 38, 15, 25, 28, 38,
        //   17, 22, 24, 21, 27, 29, 32, 26, 23, 28, 25, 31, 27, 34, 26, 29
        // ],
      },
    ],
    chart: {
      height: 350,
      type: "area",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF1654"],
    stroke: {
      width: [4],
    },
    xaxis: {
      type: "datetime",
      categories: data.time,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FF1654",
        },
        labels: {
          style: {
            colors: "#FF1654",
          },
          formatter: function (val) {
            return `${val.toFixed(1)}T`; // Format to 1 decimal and append "Liters"
          },
        },
        title: {
          text: "Load",
          style: {
            color: "#FF1654",
          },
        },
      },
    ],
    tooltip: {
      enabled: true, // Enable the tooltip
      // x: {
      //   format: 'yyyy-MM-dd', // Format the x-axis date in tooltip
      // },
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)}T`; // Custom formatting for y-axis value (Fuel in liters)
        },
        title: {
          formatter: () => "Load: ", // Tooltip title for y-axis
        },
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };
  return (
    <ReactApexChart
      options={options2}
      series={options2.series}
      type="area"
      height={350}
    />
  );
};

export default GraphLoad;
