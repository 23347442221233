import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MdClear } from "react-icons/md";
import { Toast } from "primereact/toast";
import axios from "axios";
import Cookies from "js-cookie";

const AddGeofence = () => {
  const searchBoxRef = useRef(null); // Ref for the search input
  const [data, setData] = useState([]);
  const [center, setCenter] = useState({
    lat: 18.562967720872596,
    lng: 73.80555463867105,
  }); // Default center
  const [zoom, setZoom] = useState(12);
  const toastRef = useRef(null);
  const token = Cookies.get("token");
  const StatusOptions = [
    { label: "Active", value: "1" },
    { label: "Deactive", value: "2" },
  ];
  const [polygonCoords, setPolygonCoords] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [drawingEnabled, setDrawingEnabled] = useState(true);
  const drawingManagerRef = useRef(null);
  const mapRef = useRef(null);
  const polygonDrawnRef = useRef(false);

  const CONTAINER_STYLE = {
    width: "100%",
    height: "400px",
    border: "2px #8994BC solid",
    borderRadius: "10px 10px 10px 10px",
    zIndex: "0",
  };

  //add geofence
  const handleSubmit = (e) => {
    e.preventDefault();

    if (polygonCoords.length !== 0) {
      axios
        .post(
          `${process.env.REACT_APP_AWS_URL1}/addGeofenceData`,
          { ...data, geofenceData: polygonCoords },
          {
            headers: { Authorization: token },
          }
        )
        .then((res) => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 3000,
          });
          setTimeout(() => {
            window.location.href = "/geofence";
          }, [2000]);
        })
        .catch((err) => {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        });
    } else {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please select geofence region on maps",
        life: 3000,
      });
    }
  };

  // Get the user's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setZoom(15); // Adjust zoom level based on user's location
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // Handle drawing completion
  // Handle drawing completion
  const handleDrawingComplete = useCallback((event) => {
    if (event.type === window.google?.maps.drawing.OverlayType.POLYGON) {
      const polygon = event.overlay;
      const path = polygon.getPath();
      const newCoords = path.getArray().map((coord) => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));

      // Check if polygon has more than 7 points
      if (newCoords.length > 7) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "You cannot draw more than 7 coordinates.",
          life: 3000,
        });
        polygon.setMap(null); // Remove the polygon
        return;
      }

      setPolygonCoords(newCoords);
      setPolygons((prevPolygons) => [...prevPolygons, polygon]);
      polygonDrawnRef.current = true;
      setDrawingEnabled(false);

      window.google?.maps.event.addListener(polygon.getPath(), "set_at", () =>
        updatePolygonCoords(polygon)
      );
      window.google?.maps.event.addListener(
        polygon.getPath(),
        "insert_at",
        () => updatePolygonCoords(polygon)
      );
    }
  }, []);

  // Update polygon coordinates
  const updatePolygonCoords = (polygon) => {
    const path = polygon.getPath();
    const newCoords = path.getArray().map((coord) => ({
      lat: coord.lat(),
      lng: coord.lng(),
    }));
    setPolygonCoords(newCoords);
  };

  // Initialize Drawing Manager
  const handleMapLoad = useCallback(
    (map) => {
      mapRef.current = map;

      // Initialize Search Box
      if (searchBoxRef.current) {
        const searchBox = new window.google.maps.places.SearchBox(
          searchBoxRef.current
        );

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
          searchBoxRef.current
        );

        map.addListener("bounds_changed", () => {
          searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();

          if (places.length === 0) {
            return;
          }

          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];

          const bounds = new window.google.maps.LatLngBounds();

          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              return;
            }

            const icon = {
              url: place.icon,
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(25, 25),
            };

            markers.push(
              new window.google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
              })
            );

            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });

          map.fitBounds(bounds);
        });
      }

      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google?.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google?.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillColor: "#FF0000",
          fillOpacity: 0.4,
          strokeColor: "#FF0000",
          strokeOpacity: 1,
          strokeWeight: 2,
          editable: false,
          draggable: false,
        },
      });
      drawingManager.setMap(map);
      drawingManager.addListener("overlaycomplete", handleDrawingComplete);
      drawingManagerRef.current = drawingManager;
    },
    [handleDrawingComplete]
  );

  useEffect(() => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setOptions({
        drawingMode: drawingEnabled
          ? window.google?.maps.drawing.OverlayType.POLYGON
          : null,
        drawingControlOptions: {
          drawingModes: drawingEnabled
            ? [window.google?.maps.drawing.OverlayType.POLYGON]
            : [],
        },
      });
    }
  }, [drawingEnabled]);

  const handleClearPolygon = () => {
    setPolygonCoords([]);
    polygons.forEach((polygon) => {
      polygon.setMap(null);
    });
    setPolygons([]);
    polygonDrawnRef.current = false;
    // setError(null);
    setDrawingEnabled(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="flex items-center justify-between">
        <h4 className="text-dark pt-3 text-xl font-bold dark:text-white">
          Add Geofence
        </h4>
        <button
          onClick={handleClearPolygon}
          className="rounded-md bg-gray-300 px-2 hover:bg-gray-400"
        >
          <span className="flex items-center">
            Clear Polygon&nbsp;
            <MdClear />
          </span>
        </button>
      </div>
      <div className="relative mt-2">
        <input
          id="pac-input"
          ref={searchBoxRef}
          className="absolute !left-1/4 mt-4 p-2"
          type="text"
          placeholder="Search Location"
        />

        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
          libraries={["drawing", "places"]}
          onError={(error) =>
            console.error("Error loading Google Maps API:", error)
          }
        >
          <GoogleMap
            mapContainerStyle={CONTAINER_STYLE}
            center={center}
            zoom={zoom}
            onLoad={handleMapLoad}
          >
            <Polygon
              paths={polygonCoords}
              options={{
                fillColor: "#FF0000",
                fillOpacity: 0.4,
                strokeColor: "#FF0000",
                strokeOpacity: 1,
                strokeWeight: 2,
                clickable: false,
                editable: false,
                draggable: false,
              }}
            />
          </GoogleMap>
          <div className="mt-6 flex items-center justify-start gap-5">
            <span className="p-float-label">
              <InputText
                id="name"
                name="name"
                onChange={handleChange}
                className={`border py-1.5 pl-2 text-sm `}
                autoComplete="off"
              />
              <label htmlFor="LastName" className="dark:text-gray-800">
                Name
              </label>
            </span>
            <span className="p-float-label">
              <Dropdown
                id="status"
                name="geofence_status"
                options={StatusOptions}
                value={data.geofence_status}
                onChange={handleChange}
                className={`h-8 border pl-0 text-sm`}
              />
              <label htmlFor="status" className="dark:text-gray-800">
                Status
              </label>
            </span>
            <button
              onClick={handleSubmit}
              className="rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
            >
              Create
            </button>
          </div>
        </LoadScript>
      </div>
    </>
  );
};

export default AddGeofence;
