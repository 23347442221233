import React, { useRef, useState, useCallback } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";

const GeofenceList = ({ data, onAddGeofence }) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [geofenceId, setGeofenceId] = useState("");
  const [viewDialog, setViewDialog] = useState(false);
  const [viewData, setViewData] = useState(null);
  // console.log("viewData::", viewData);
  const token = Cookies.get("token");
  const toastRef = useRef(null);
  const mapRef = useRef(null);

  // useEffect(() => {
  //   if (viewData) {
  //     const parsedData = JSON.parse(viewData);

  //     // Update the state with the parsed data
  //     // setViewData(parsedData);
  //     console.log("parsedData::::", parsedData);
  //   }
  // }, [viewData]);
  const CONTAINER_STYLE = {
    width: "100%",
    height: "400px",
    border: "2px #8994BC solid",
    borderRadius: "10px 10px 10px 10px",
    zIndex: "0",
  };

  const ZOOM = 13;

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  //global search
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const openEditDialog = (rowData) => {
    setOpenDialog(true);
    setEditData(rowData);
  };

  const openViewDialog = (rowData) => {
    setViewData(rowData);
    setViewDialog(true);
  };

  const deleteDialog = (rowData) => {
    setOpenDeleteDialog(true);
    setGeofenceId(rowData.geofence_id);
  };

  const StatusOptions = [
    { label: "Active", value: "1" },
    { label: "Deactive", value: "2" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEye
          title="View"
          className="cursor-pointer text-gray-700"
          onClick={() => {
            openViewDialog(rowData);
          }}
        />
        <FaRegEdit
          title="Edit"
          onClick={() => {
            openEditDialog(rowData);
          }}
          className="mx-4 cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.geofence_status === "2"
              ? deleteDialog(rowData)
              : console.log();
          }}
          className={` ${
            rowData.geofence_status === "2"
              ? "cursor-pointer text-red-600"
              : "text-gray-500"
          }`}
        />
      </div>
    );
  };

  const header = (
    <div className="my-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/editGeofenceData/${editData.geofence_id}`,
        { ...editData },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: res.data.message,
          life: 3000,
        });
        setOpenDialog(false);
        onAddGeofence();
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

  const handleDelete = () => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/deleteGeofenceData/${geofenceId}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: res.data.message,
          life: 3000,
        });
        setOpenDeleteDialog(false);
        onAddGeofence();
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <DataTable
        removableSort
        value={data}
        // selection={selectedVehicles}
        // onSelectionChange={(e) => setSelectedVehicles(e.value)}
        dataKey="geofence_id"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} vehicles"
        filterDisplay="menu"
        filters={filters}
        globalFilterFields={["name"]}
        emptyMessage="No geofences found."
        header={header}
        className="mt-8"
      >
        <Column
          key="srNo"
          field="serialNo"
          header="Sr No"
          className="border-b py-8 pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
          }}
        ></Column>
        <Column
          field="name"
          header="Geofence Name"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
        <Column
          key="status"
          field="geofence_status"
          header="Status"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          body={(rowData) => (
            <Tag
              className="h-5 rounded-sm text-xs font-normal"
              value={`${
                rowData.geofence_status === "1" ? "Active" : "Deactive"
              }`}
              severity={`${
                rowData.geofence_status === "1" ? "success" : "danger"
              }`}
            ></Tag>
          )}
        ></Column>
        <Column
          key="action"
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "8rem",
            padding: "5px",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        ></Column>
      </DataTable>
      <Dialog
        visible={openDialog}
        onHide={() => {
          setOpenDialog(false);
        }}
        style={{ width: "34%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <div className="flex items-center justify-between gap-5">
          <span className="p-float-label w-full">
            <InputText
              id="name"
              name="name"
              onChange={handleChange}
              className={`border py-1.5 pl-2 text-sm `}
              autoComplete="off"
              value={editData?.name}
            />
            <label htmlFor="LastName" className="dark:text-gray-800">
              Name
            </label>
          </span>
          <span className="p-float-label w-full">
            <Dropdown
              id="status"
              name="geofence_status"
              options={StatusOptions}
              value={editData?.geofence_status}
              onChange={handleChange}
              className={`h-8 border pl-0 text-sm`}
            />
            <label htmlFor="status" className="dark:text-gray-800">
              Status
            </label>
          </span>
        </div>
        <div className="mt-4 text-right">
          <button
            onClick={handleUpdate}
            className="rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
          >
            Update
          </button>
        </div>
      </Dialog>
      <Dialog
        visible={openDeleteDialog}
        onHide={() => {
          setOpenDeleteDialog(false);
        }}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={() => setOpenDeleteDialog(false)}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete?
          </span>
        </div>
      </Dialog>
      <Dialog
        visible={viewDialog}
        onHide={() => {
          setViewDialog(false);
        }}
        style={{ width: "74%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={`${viewData?.name}`}
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <div className="relative mt-2">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}
            onError={(error) =>
              console.error("Error loading Google Maps API:", error)
            }
          >
            <GoogleMap
              mapContainerStyle={CONTAINER_STYLE}
              center={viewData?.geofenceData[0]}
              zoom={ZOOM}
              onLoad={handleMapLoad}
            >
              <Polygon
                paths={viewData?.geofenceData}
                options={{
                  fillColor: "#FF0000",
                  fillOpacity: 0.4,
                  strokeColor: "#FF0000",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  clickable: false,
                  editable: false,
                  draggable: false,
                }}
              />
            </GoogleMap>
          </LoadScript>
        </div>
      </Dialog>
    </>
  );
};

export default GeofenceList;
