import React, { useState, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import { Tag } from "primereact/tag";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { AppContext } from "context/AppContext";

const OrgList = ({ data, editOrg }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isViewDialogVisible, setIsViewDialogVisible] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [editData, setEditData] = useState({
    org_name: "",
    org_address: "",
    org_city: "",
    org_state: "",
    org_pincode: "",
  });
  const [rowId, setRowId] = useState();
  const toastRef = useRef();
  const { updateOrg } = useContext(AppContext);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  //Opens edit dialog
  const openDialog = (rowData) => {
    setIsDialogVisible(true);
    setEditData({
      org_name: rowData.orgName,
      org_address: rowData.org_address.address,
      org_city: rowData.org_address.city,
      org_state: rowData.org_address.state,
      org_pincode: rowData.org_address.pincode,
    });
    setRowId(rowData);
  };

  //Opens view dialog
  const openViewDialog = (rowData) => {
    setIsViewDialogVisible(true);
    setViewData(rowData);
  };

  //Closes edit dialog
  const closeDialog = () => {
    setIsDialogVisible(false);
  };

  const handleChange = (e, name) => {
    const value = e.target ? e.target.value : e;
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = [
      "org_address",
      "org_city",
      "org_state",
      "org_pincode",
    ];
    const isAnyFieldEmpty = requiredFields.some((field) => !editData[field]);
    if (isAnyFieldEmpty) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all required fields.",
      });
    } else {
      editOrg(rowId?.orgId, editData);
      closeDialog();
      updateOrg();
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex items-center text-lg">
        <FaRegEdit
          title="Edit"
          onClick={() => openDialog(rowData)}
          className="h-4 w-4 cursor-pointer text-gray-700"
        />
        <FaRegEye
          title="View"
          onClick={() => openViewDialog(rowData)}
          className="mx-4 h-4 w-4 cursor-pointer text-navy-300"
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="mb-2 flex justify-end  dark:bg-gray-950">
        <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
          <i className="pi pi-search pl-2 dark:text-gray-300" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
          />
          {globalFilterValue && (
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
              onClick={clearSearch}
            />
          )}
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const enhancedData = data.map((row) => {
    return {
      ...row,
      searchableContent: `${row.orgName} ${row.orgType} ${row.org_address.address} ${row.org_address.city} ${row.user_information.name} ${row.contact_information.name}`,
    };
  });

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <DataTable
        value={enhancedData}
        header={header}
        removableSort
        paginator
        dataKey="serialNo"
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={["searchableContent"]}
        emptyMessage="No organizations found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            paddingTop: "0px",
            paddingLeft: "2rem",
            paddingBottom: "0px",
          }}
        ></Column>
        <Column
          field="orgName"
          header="Name"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="orgType"
          header="Type"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          // body={(rowData) => {
          //   return (
          //     <Tag
          //       className="mr-2 bg-gray-200 text-xs text-gray-800"
          //       icon="pi pi-tag"
          //       style={{
          //         width: "fit-content",
          //         height: "20px",
          //       }}
          //       value={rowData.orgType}
          //     />
          //   );
          // }}
        ></Column>
        <Column
          header="Admin User"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          body={(rowData) => {
            return <>{rowData.user_information.name}</>;
          }}
        ></Column>
        <Column
          header="Contact Person"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          body={(rowData) => {
            return <>{rowData.contact_information.name}</>;
          }}
        ></Column>
        <Column
          header="City"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          body={(rowData) => {
            return <>{rowData.org_address.city}</>;
          }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
        ></Column>
      </DataTable>
      <Dialog
        visible={isDialogVisible}
        onHide={closeDialog}
        style={{ width: "35rem", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit the organization"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form className="mx-auto" onSubmit={handleSubmit}>
          <div className="mt-8 w-auto">
            <span className="p-float-label">
              <InputText
                id="org_name"
                name="org_name"
                value={editData.org_name}
                className={`text-normal border bg-[#ccc] py-2 pl-2 font-bold text-navy-900`}
                disabled
              />
              <label htmlFor="device_id" className="dark:text-gray-300">
                Organization Name
              </label>
            </span>
          </div>
          <div className="flex justify-between gap-3">
            <div className="mt-8 flex-1">
              <span className="p-float-label">
                <InputText
                  id="org_address"
                  name="org_address"
                  value={editData.org_address}
                  className={`border py-1.5 pl-2 text-sm`}
                  onChange={(e) => handleChange(e, "org_address")}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  Address
                </label>
              </span>
            </div>
            <div className="mt-8 flex-1">
              <span className="p-float-label">
                <InputText
                  id="org_city"
                  name="org_city"
                  value={editData.org_city}
                  className={`border py-1.5 pl-2 text-sm`}
                  onChange={(e) => handleChange(e, "org_city")}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  City
                </label>
              </span>
            </div>
          </div>
          <div className="flex justify-between gap-3">
            <div className="mt-8 flex-1">
              <span className="p-float-label">
                <InputText
                  id="org_state"
                  name="org_state"
                  value={editData.org_state}
                  className={`border py-1.5 pl-2 text-sm`}
                  onChange={(e) => handleChange(e, "org_state")}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  State
                </label>
              </span>
            </div>
            <div className="mt-8 flex-1">
              <span className="p-float-label">
                <InputText
                  id="org_pincode"
                  name="org_pincode"
                  value={editData.org_pincode}
                  className={`border py-1.5 pl-2 text-sm`}
                  onChange={(e) => handleChange(e, "org_pincode")}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  Pincode
                </label>
              </span>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={isViewDialogVisible}
        onHide={() => {
          setIsViewDialogVisible(false);
        }}
        style={{ width: "35rem", height: "fit-content" }}
        header="Organization Details"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <div className="grid grid-cols-2 2xl:grid-cols-2">
          <div>
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Name
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.orgName}
            </p>
          </div>
          <div>
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Type
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.orgType}
            </p>
          </div>
        </div>
        <div className="mt-2">
          <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
            Address
          </p>
          <p className="overflow-hidden break-words rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
            {viewData.org_address?.address},&nbsp;{viewData.org_address?.city}
            ,&nbsp;{viewData.org_address?.state},&nbsp;
            {viewData.org_address?.pincode}
          </p>
        </div>
        <div className="grid grid-cols-2 2xl:grid-cols-2">
          <div className="mt-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Contact person
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.contact_information?.name}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Type
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.contact_information?.contactType}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              {viewData.contact_information?.contactType === "Phone"
                ? "Contact number"
                : "Email id"}
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.contact_information?.phoneORemail}
            </p>
          </div>
          <div className="mt-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Primary
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.contact_information?.contactIsPrimary === 1
                ? "Yes"
                : "No"}
            </p>
          </div>
          <div className="my-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Admin
            </p>
            <p className="rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.user_information?.name}
            </p>
          </div>
          <div className="my-2">
            <p className="py-0.5 pl-2 text-xs font-semibold dark:text-gray-400">
              Email id
            </p>
            <p className="overflow-hidden break-words rounded border-b-[1px] border-gray-150 bg-gray-200 py-0.5 pl-2 text-[#333] dark:border-navy-400 dark:bg-navy-600 dark:text-gray-200">
              {viewData.user_information?.email}
            </p>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OrgList;
