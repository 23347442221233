import axios from "axios";
import Cookies from "js-cookie";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  // State to manage form input values
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const RedirectToken = Cookies.get("RedirectToken");
  const toastRef = useRef(null);
  const navigate = useNavigate();

  function isValidPassword(password) {
    // Minimum length check
    if (password.length < 8) {
      return false;
    }

    // Regular expression for character types
    const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;

    // Check for at least one digit, lowercase letter, uppercase letter, and special character
    return regex.test(password);
  }
  // Function to handle form submission
  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Validate input (you may want to add more validation)
    if (!password || !confirmPassword || password !== confirmPassword) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Credentials are wrong!",
        life: 3000,
      });
      return;
    }

    // Assuming you have an API endpoint for password reset, you can make a call here
    // Example API call:
    // try {
    //   const response = await resetPasswordAPI(email, password);
    //   setResetStatus("Password reset successful!");
    // } catch (error) {
    //   setResetStatus("Password reset failed. Please try again.");
    // }

    // For the sake of this example, let's assume the reset is successful
    else if (!isValidPassword(password)) {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please make sure that passwords follows all the rules",
        life: 3000,
      });
    }
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/resetPassword`,
        {
          password: password,
          confirmPassword: confirmPassword,
        },
        {
          headers: { Authorization: RedirectToken },
        }
      )
      .then(() => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password changed successfully!",
          life: 3000,
        });
        navigate("/signin");
      })
      .catch(() => {
        toastRef.current.show({
          severity: "danger",
          summary: "Danger",
          detail: "Password changed failed!",
          life: 3000,
        });
      });
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <Toast ref={toastRef} className="toast-custom" position="top-right" />

      <div className="mx-auto h-fit  rounded-md bg-gray-300 p-12">
        <Message
          text="password must have this: minimum length 8 , minimum one capital letter, min one number, min one special character "
          className="mx-auto mb-10"
        />
        <h2 className="text-center text-3xl font-semibold">Reset Password</h2>

        <form className="mx-auto max-w-sm" onSubmit={handleResetPassword}>
          {/* <div className="mb-5">nl
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="pointer-events-none block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            />
  </div>*/}
          <div className="mb-5">
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
              New password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
              Confirm password
            </label>
            <input
              type="password"
              id="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 sm:w-auto"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
