import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import OrgList from "./components/OrgList";
import { Toast } from "primereact/toast";
import axios from "axios";
import Cookies from "js-cookie";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { AppContext } from "context/AppContext";

const Organization = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [orgTypes, setOrgTypes] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState();
  const [contactPerson, setContactPerson] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [org, setOrg] = useState(false);
  const { updateOrg } = useContext(AppContext);
  const [inputType, setInputType] = useState("pint");
  const [emptyFields, setEmptyFields] = useState([]);
  const [formData, setFormData] = useState({
    OrgName: "",
    OrgType: "",
    OrgAdd: "",
    OrgCity: "",
    OrgState: "",
    OrgPin: "",
    FirstName: "",
    LastName: "",
    Email: "",
    ContactType: "Phone",
    ContactInfo: "",
  });
  const [isError, setIsError] = useState(false);
  const pattern = new RegExp(/^\d{1,10}$/);
  const toastRef = useRef();
  const token = Cookies.get("token");
  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to check for exactly 10 digits
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const openDialog = () => {
    setIsDialogVisible(true);
    setSelectedOrg();
    setEmptyFields([]);
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_AWS_URL1}/organizationDetailsGet`, null, {
        headers: { Authorization: token },
      })
      .then((response) => {
        const formattedData = response.data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
          key: index + 1,
          address:
            item.org_address +
            ", " +
            item.org_city +
            ", " +
            item.org_state +
            ", " +
            item.org_pincode,
        }));
        setOrgData(formattedData);
        setLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "OrgType" },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setOrgTypes(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getUsers`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setContactPerson(response.data.data[0].userData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token, org, updateOrg]);

  //Edit organization
  const editOrg = (orgId, editData) => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/updateUserOrgs/${orgId}`,
        { ...editData },
        { headers: { Authorization: token } }
      )
      .then(() => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Device ${editData.org_name} updated successfully`,
          life: 3000,
        });
        setOrg(true);
        updateOrg();
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: 3000,
        });
        setOrg(true);
      });
  };

  const orgOptions = () => {
    return orgTypes?.map((el) => ({
      key: el.orgTypeId,
      label: el.orgTypeName,
      value: el.orgTypeId,
    }));
  };

  const contactPersons = () => {
    return contactPerson?.map((el) => ({
      key: el.orgTypeId,
      label: el.first_name + " " + el.last_name,
      value: el.user_id,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setEmptyFields((prevEmptyFields) =>
      prevEmptyFields.filter((field) => field !== name)
    );
  };

  const handleDropdownChange = (fieldName, selectedValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
    setEmptyFields((prevEmptyFields) =>
      prevEmptyFields.filter((field) => field !== fieldName)
    );
    if (fieldName === "ContactType") {
      const inputType = selectedValue === "Phone" ? "pint" : "email";
      setFormData((prevFormData) => ({
        ...prevFormData,
        ContactInfo: "",
      }));
      setInputType(inputType);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      "OrgName",
      "OrgType",
      "OrgAdd",
      "OrgCity",
      "OrgState",
      "OrgPin",
      "FirstName",
      "LastName",
      "Email",
      "ContactInfo",
    ];
    const newEmptyFields = requiredFields.filter((field) => !formData[field]);
    setEmptyFields(newEmptyFields);
    if (!isValidPhoneNumber(formData.ContactInfo)) {
      toastRef.current.show({
        severity: "warn",
        summary: "Invalid Phone Number",
        detail: "Please enter a 10-digit valid phone number.",
        life: 3000,
      });
      return;
    }
    // Display an error message or handle the validation appropriately
    if (newEmptyFields.length > 0) {
      toastRef.current.show({
        severity: "warn",
        summary: "Fill Required Fields",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/createOrganization`,
        {
          ...formData,
          UserType: "e1bf1f49-9e34-11ee-9fc8-0a33c87d103e",
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Organization ${formData.OrgName} added successfully`,
          life: 3000,
        });
        setIsDialogVisible(false);
        setOrg(true);
      })
      .catch((err) => {
        console.log(err);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };
  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
        Organizations
      </h4>
      <button
        className="flex h-7 items-center rounded-lg bg-blue-500 px-2 text-left text-sm font-normal text-white hover:bg-blue-600"
        onClick={openDialog}
      >
        <FiPlus />
        &nbsp;New Organization
      </button>
      {loaded ? (
        <OrgList
          data={orgData}
          orgTypes={orgOptions()}
          editOrg={editOrg}
          contactPersons={contactPersons()}
        />
      ) : (
        <PreloaderList />
      )}

      <Dialog
        visible={isDialogVisible}
        onHide={() => {
          setIsDialogVisible(false);
        }}
        style={{ width: "38rem", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form className="mx-auto" onSubmit={handleSubmit}>
          <div className="mt-5 flex justify-evenly gap-4">
            <div className="card justify-content-center flex-1">
              <span className="p-float-label">
                <InputText
                  id="org_name"
                  name="OrgName"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("OrgName") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="org_name" className="dark:text-gray-300">
                  Organization Name
                </label>
              </span>
              {emptyFields.includes("org_name") && (
                <small className="text-red-500">
                  Organization name is required.
                </small>
              )}
            </div>
            <div className="card justify-content-center flex-1">
              <span className="p-float-label">
                <Dropdown
                  id="org_type"
                  name="OrgType"
                  options={orgOptions()}
                  value={selectedOrg}
                  onChange={(e) => {
                    setSelectedOrg(e.value);
                    handleDropdownChange("OrgType", e.value);
                  }}
                  className={`h-8 border ${
                    emptyFields.includes("OrgType") ? "border-red-500" : ""
                  }`}
                  optionLabel="label"
                />

                <label htmlFor="org_type" className="dark:text-gray-300">
                  Organization Type
                </label>
              </span>
              {emptyFields.includes("OrgType") && (
                <small className="text-red-500">
                  Organization type is required.
                </small>
              )}
            </div>
          </div>
          {/* <div className="mb-6 ml-2 mt-4">
            <p>Address:</p>
          </div> */}
          <div className="mt-8 flex justify-evenly gap-4">
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="address"
                  type="text"
                  name="OrgAdd"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("OrgAdd") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="address" className="dark:text-gray-300">
                  Flat No./ Plot No., Area/Society
                </label>
              </span>
              {emptyFields.includes("address") && (
                <small className="text-red-500">Address is required.</small>
              )}
            </div>
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="city"
                  type="text"
                  name="OrgCity"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("OrgCity") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="city" className="dark:text-gray-300">
                  City
                </label>
              </span>
              {emptyFields.includes("OrgCity") && (
                <small className="text-red-500">City is required.</small>
              )}
            </div>
          </div>
          <div className="mt-8 flex justify-evenly gap-4">
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="state"
                  type="text"
                  name="OrgState"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("OrgState") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="state" className="dark:text-gray-300">
                  State
                </label>
              </span>
              {emptyFields.includes("OrgState") && (
                <small className="text-red-500">State is required.</small>
              )}
            </div>
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="pincode"
                  type="text"
                  name="OrgPin"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("OrgPin") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  pattern="^[1-9][0-9]{5}$"
                  onChange={handleInputChange}
                />
                <label htmlFor="pincode" className="dark:text-gray-300">
                  Pincode
                </label>
              </span>
              {emptyFields.includes("OrgPin") && (
                <small className="text-red-500">Pincode is required.</small>
              )}
            </div>
          </div>
          <div className="mb-8 mt-4 rounded-sm bg-gray-100 py-0.5 text-center text-sm shadow-white dark:bg-gray-800">
            <span className="font-normal">Add Default Admin</span>
          </div>
          <div className="flex justify-evenly gap-4">
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="first_name"
                  type="text"
                  name="FirstName"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("FirstName") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="first_name" className="dark:text-gray-300">
                  First Name
                </label>
              </span>
              {emptyFields.includes("FirstName") && (
                <small className="text-red-500">First name is required.</small>
              )}
            </div>
            <div className="card justify-content-center flex-auto">
              <span className="p-float-label">
                <InputText
                  id="last_name"
                  type="text"
                  name="LastName"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("LastName") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="last_name" className="dark:text-gray-300">
                  Last Name
                </label>
              </span>
              {emptyFields.includes("LastName") && (
                <small className="text-red-500">Last name is required.</small>
              )}
            </div>
          </div>
          <div className="mt-8 flex justify-evenly gap-4">
            <div className="card flex-1">
              <span className="p-float-label">
                <InputText
                  id="email_id"
                  type="email"
                  name="Email"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("Email") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <label htmlFor="email_id" className="dark:text-gray-300">
                  Email
                </label>
              </span>
              {emptyFields.includes("Email") && (
                <small className="text-red-500">Email ID is required.</small>
              )}
            </div>
            <div className="card justify-content-center flex-1">
              <span className="p-float-label">
                <InputText
                  id="phone"
                  keyfilter={inputType}
                  name="ContactInfo"
                  className={`border py-1 pl-2 text-sm ${
                    emptyFields.includes("ContactInfo") ? "border-red-500" : ""
                  }`}
                  autoComplete="off"
                  onChange={(e) => {
                    handleInputChange(e);
                    if (!pattern.test(e.target.value)) {
                      setIsError(true);
                    } else {
                      setIsError(false);
                    }
                  }}
                />
                <label htmlFor="phone" className="dark:text-gray-300">
                  Phone number
                </label>
              </span>
              {emptyFields.includes("ContactInfo") && (
                <small className="text-red-500">
                  {isError
                    ? "Please enter a valid phone number."
                    : "Phone number is required."}
                </small>
              )}
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Add Organization
            </button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default Organization;
