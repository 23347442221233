import React, { useState, useEffect, useRef } from "react";
import VehiclesList from "./components/VehiclesList";
import axios from "axios";
import Cookies from "js-cookie";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { FiPlus } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { FaDownload, FaFileExcel } from "react-icons/fa";
import * as XLSX from "xlsx";
import { Calendar } from "primereact/calendar";

const VehiclesAdmin = () => {
  const [reload, setReload] = useState(false);
  const token = Cookies.get("token");
  const org_id = Cookies.get("org_id");
  const user_type = Cookies.get("user_type");
  // const [isListView, setIsListView] = useState(
  //   localStorage.getItem("viewPreference") === "grid" ? false : true
  // );
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [data, setData] = useState([]);
  const [addData, setAddData] = useState({
    vehicle_name: "",
    vehicle_reg_number: "",
    vehicle_type: "",
    tag: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    vehicle_name: false,
    tag: false,
    device_type_id: false,
    vehicle_type: false,
  });
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const toastRef = useRef();
  const [dataEdittedOrDeleted, setDataEdittedOrDeleted] = useState(null);
  const [sumDialogVisible, setSumDialogVisible] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  // const [selectedEndDate, setSelectedEndDate] = useState(epochTodayInSeconds);
  //   const [selectedStartDate, setSelectedStartDate] =
  //     useState(epochStartInSeconds);
  // const handleToggleView = () => {
  //   const newView = !isListView;
  //   setIsListView(newView);
  //   // Store the view preference in localStorage
  //   localStorage.setItem("viewPreference", newView ? "list" : "grid");
  // };
  // Fetching all data
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
          headers: { Authorization: token },
        })
        .then((vehiclesResponse) => {
          // Processing vehicles data
          setTags(vehiclesResponse.data.tags);
          let formattedData = [];
          if (Array.isArray(vehiclesResponse.data.data)) {
            formattedData = vehiclesResponse.data.data.map((item, index) => ({
              ...item,
              serialNo: index + 1,
              key: index + 1,
              vehicle_status:
                parseInt(item.vehicle_active) === 1
                  ? "Active"
                  : parseInt(item.vehicle_active) === 2
                  ? "Deactive"
                  : "Deleted",
            }));
          }

          // Update state based on previous state
          setData(() => {
            if (formattedData.length === 0) {
              setLoaded(true);
            } else {
              setLoaded(true);
            }
            return formattedData;
          });

          // Fetch vehicle type lookup data
          return axios.post(
            `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
            { FetchType: "VehicleTypeLookup" },
            { headers: { Authorization: token } }
          );
        })
        .then((lookupResponse) => {
          setVehicleTypes(lookupResponse.data.data);
          // Processing lookup response to map vehicle types
          const vehicleTypeMap = {};
          lookupResponse.data.data.forEach((item) => {
            vehicleTypeMap[item.vehicleTypeId] = item.vehicleTypeName;
          });

          // Update data based on previous state
          setData((prevData) =>
            prevData.map((item) => ({
              ...item,
              vehicleTypeName: vehicleTypeMap[item.vehicle_type],
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoaded(true);
        });
    };

    fetchData();
  }, [token, dataEdittedOrDeleted, reload]);

  const vehiclesOptions = () => {
    return vehicleTypes?.map((el) => ({
      label: el.vehicleTypeName,
      value: el.vehicleTypeId,
    }));
  };

  const tagOptions = () => {
    return tags?.map((el) => ({
      label: el,
      value: el,
    }));
  };

  const onEditOrDelete = (data) => {
    setDataEdittedOrDeleted(data);
  };

  const openDialog = () => {
    setDialogVisible(true);
    setAddData({});
  };
  const openSummaryDialog = () => {
    setSumDialogVisible(true);
    setSelectedEndDate();
    setSelectedStartDate();
  };

  // Add customer api call
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !addData.vehicle_name ||
      !addData.vehicle_reg_number ||
      !addData.vehicle_type
    ) {
      setValidationErrors({
        vehicle_name: !addData.vehicle_name,
        tag: !addData.tag,
        vehicle_reg_number: !addData.vehicle_reg_number,
        vehicle_type: !addData.vehicle_type,
      });

      // Display toast for validation error
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "All fields are required",
        life: 3000,
      });

      return; // Stop further processing
    }
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/createVehicle`,
        { ...addData, org_id },
        {
          headers: { Authorization: token },
        }
      )
      .then(() => {
        // setDevices(addData);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Device ${addData.vehicle_name} added successfully`,
          life: 3000,
        });
        setReload(true);
        setDialogVisible(false);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/vehicleListReport`,
        { tag: tag },
        { headers: { Authorization: token } }
      );

      const data = response?.data?.data;

      if (!Array.isArray(data)) {
        console.log("Data is not an array");
        return;
      }

      const formattedData = data?.map((item) => ({
        Vehicle_Name: item.vehicle_name,
        Tag: item.tag,
        Registration_Number: item.vehicle_reg_number,
      }));

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      if (formattedData) {
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataExcel = new Blob([excelBuffer], { type: fileType });
        const fileName = tag + fileExtension;

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(dataExcel);
        downloadLink.download = fileName;
        downloadLink.click();
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Vehicles list has been downloaded successfully",
          life: 3000,
        });
        setTag("");
      }
    } catch (err) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.message,
        life: 3000,
      });
    }
  };

  const handleDownloadSummary = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/tagBasedReport`,
        { tag: tag, from_epoch: selectedStartDate, to_epoch: selectedEndDate },
        { headers: { Authorization: token } }
      );

      const summaryData = res.data;
      if (!Array.isArray(summaryData.data)) {
        console.error("Data is not an array");
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Received data is not an array",
          life: 3000,
        });
        return;
      }

      const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600)
          .toString()
          .padStart(2, "0");
        const m = Math.floor((seconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const s = (seconds % 60).toString().padStart(2, "0");
        return `${h} hours : ${m} minutes : ${s} seconds`;
      };

      const formattedData = summaryData.data.map((item) => ({
        Vehicle_Name: item.vehicle_name,
        Tag: tag,
        Total_distance: item.total_distance.toFixed(2),
        Total_alerts: item.total_alerts,
        Total_trips: item.total_trips,
        Total_duration: formatDuration(item.total_duration),
        Registration_Number: item.vehicle_reg_number,
      }));

      formattedData.push({
        Vehicle_Name: "Summary",
        Tag: "",
        Total_distance: summaryData.allSumm.total_distance.toFixed(2),
        Total_alerts: summaryData.allSumm.total_alerts,
        Total_trips: summaryData.allSumm.total_trips,
        Total_duration: formatDuration(summaryData.allSumm.total_duration),
        Registration_Number: "NA",
      });

      if (formattedData && formattedData.length > 0) {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const dataExcel = new Blob([excelBuffer], { type: fileType });
        const fileName = tag + fileExtension;

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(dataExcel);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink); // Append the link to the document body
        downloadLink.click();
        document.body.removeChild(downloadLink); // Remove the link from the document body
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "Report has been downloaded successfully",
          life: 3000,
        });
        setTimeout(() => {
          setSumDialogVisible(false);
          setTag("");
        }, 1000);
      }
    } catch (err) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.message || "An error occurred",
        life: 3000,
      });
    }
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />

      <>
        <div className="flex justify-between">
          <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
            Vehicles
          </h4>

          {/* <div className="pt-2">
              <button
                className={`${
                  isListView === true
                    ? "list-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "list-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsListUl />
              </button>
              <button
                className={`${
                  isListView === false
                    ? "grid-btn bg-gray-150 px-2 py-1  dark:bg-gray-700  "
                    : "grid-btn bg-white px-2 py-1  dark:bg-gray-150 "
                }`}
                onClick={handleToggleView}
              >
                <BsGrid />
              </button>
            </div> */}
        </div>
        {user_type !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <div className="flex items-center justify-between">
            <button
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
              onClick={openDialog}
            >
              <FiPlus />
              &nbsp;New Vehicle
            </button>
            <div className="flex items-center justify-between gap-3">
              <p className="flex items-center gap-1 text-gray-700">
                Select Tag
              </p>
              <span className="p-float-label">
                <Dropdown
                  id="vehicle_type"
                  name="vehicle_type"
                  options={tagOptions()}
                  optionLabel="label"
                  optionValue="value"
                  className={`p-dropdown } h-8 border text-sm 
                  dark:bg-navy-700`}
                  onChange={(e) => setTag(e.target.value)}
                  value={tag}
                  showClear={tag ? true : false}
                />
                <label htmlFor="vehicle_type" className="dark:text-gray-300">
                  Select
                </label>
              </span>
              <button
                className="flex items-center rounded-md border-[1px] border-gray-700 bg-white px-2 py-0.5 dark:bg-gray-800 dark:text-gray-100"
                onClick={handleDownload}
              >
                Download list&nbsp;
                <FaFileExcel className="text-gray-600" />
              </button>
              <button
                className="flex items-center rounded-md border-[1px] border-gray-700 bg-white px-2 py-0.5 dark:bg-gray-800 dark:text-gray-100"
                onClick={openSummaryDialog}
              >
                Download summary&nbsp;
                <FaDownload className="text-gray-600" />
              </button>
            </div>
          </div>
        )}
        {/* {!isListView && (
            <div className="opacity-100 transition-opacity duration-500">
              {loaded ? (
                <VehiclesGrid
                  data={data}
                  setVehicleDetails={setVehicleDetails}
                  onEditOrDelete={onEditOrDelete}
                  setSelectedOrganization={setSelectedOrganization}
                  setBasicVehicle={setBasicVehicle}
                />
              ) : (
                <div className="mt-6">
                  <PreloaderGrid />
                </div>
              )}
            </div>
          )} */}
        {/* ( */}
        <div className="opacity-100 transition-opacity duration-500">
          {loaded ? (
            <VehiclesList
              data={data}
              tag={tag}
              onEditOrDelete={onEditOrDelete}
            />
          ) : (
            <div className="mt-6">
              <PreloaderList />
            </div>
          )}
        </div>
      </>
      <Dialog
        visible={sumDialogVisible}
        onHide={() => {
          setSumDialogVisible(false);
        }}
        style={{ width: "35%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        className="p-fluid dark:bg-gray-900"
        header="Select date range"
      >
        <div className="mt-6 flex items-center justify-between gap-4">
          <span className="p-float-label flex items-center">
            <Calendar
              inputId="start_date"
              value={
                selectedStartDate ? new Date(selectedStartDate * 1000) : null
              }
              className="p-dropdown h-8 rounded-lg border border-gray-300"
              onChange={(e) => {
                const selectedDate = e.value;
                const epochTimeInSeconds = selectedDate
                  ? Math.floor(selectedDate.getTime() / 1000)
                  : null;
                setSelectedStartDate(epochTimeInSeconds);
              }}
              dateFormat="dd/mm/yy"
            />
            <label
              htmlFor="start_date"
              className=" text-gray-600 dark:text-gray-150"
            >
              From
            </label>
          </span>
          <span className="p-float-label flex items-center">
            <Calendar
              inputId="end_date"
              value={selectedEndDate ? new Date(selectedEndDate * 1000) : null}
              className={`p-dropdown h-8 rounded-lg border border-gray-300 `}
              onChange={(e) => {
                const selectedDate = e.value;
                if (selectedDate) {
                  // Set the time to 23:59:59 (last second of the day)
                  selectedDate.setHours(23, 59, 59, 999);
                  const epochTimeInSeconds = Math.floor(
                    selectedDate.getTime() / 1000
                  );
                  setSelectedEndDate(epochTimeInSeconds);
                } else {
                  setSelectedEndDate(null);
                }
              }}
              dateFormat="dd/mm/yy"
            />

            <label
              htmlFor="end_date"
              className="text-gray-600 dark:text-gray-150"
            >
              To
            </label>
          </span>
        </div>
        <div className="text-end">
          <button
            className="ml-auto mt-2 rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600 "
            onClick={handleDownloadSummary}
          >
            Download
          </button>
        </div>
      </Dialog>
      <Dialog
        visible={dialogVisible}
        onHide={() => {
          setDialogVisible(false);
        }}
        style={{ width: "40%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form className="mx-auto" onSubmit={handleSubmit}>
          <div className="mt-8 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
            <span className="p-float-label">
              <InputText
                id="vehicle_name"
                name="vehicle_name"
                onChange={(e) => handleChange(e, "vehicle_name")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.vehicle_name ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="vehicle_name" className="dark:text-gray-300">
                Vehicle Name
              </label>
            </span>
            <span className="p-float-label">
              <InputText
                id="vehicle_reg_number"
                name="vehicle_reg_number"
                onChange={(e) => handleChange(e, "vehicle_reg_number")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.vehicle_reg_number ? "border-red-600" : ""
                }`}
              />
              <label
                htmlFor="vehicle_reg_number"
                className="dark:text-gray-300"
              >
                Vehicle Registration Number
              </label>
            </span>
            <span className="p-float-label mt-4">
              <Dropdown
                id="vehicle_type"
                name="vehicle_type"
                options={vehiclesOptions()}
                optionLabel="label"
                optionValue="value"
                className={`p-dropdown h-9 border text-sm ${
                  validationErrors.vehicle_type ? "border-red-600" : ""
                }`}
                onChange={handleChange}
                value={addData.vehicle_type}
              />
              <label htmlFor="device_type" className="dark:text-gray-300">
                Type
              </label>
            </span>

            <span className="p-float-label mt-4">
              <InputText
                id="tag"
                name="tag"
                onChange={(e) => handleChange(e, "tag")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.tag ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="tag" className="dark:text-gray-300">
                Tag
              </label>
            </span>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Add Vehicle
            </button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default VehiclesAdmin;
