import React, { useState, useEffect, useRef } from "react";
import CustomersList from "./components/CustomersList";
import { Toast } from "primereact/toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import { FiPlus } from "react-icons/fi";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { IoIosInformationCircleOutline } from "react-icons/io";

const Customers = () => {
  const token = Cookies.get("token");
  const user_type = Cookies.get("user_type");
  const org_id = Cookies.get("org_id");
  // const [isListView, setIsListView] = useState(
  //   localStorage.getItem("viewPreference") === "grid" ? false : true
  // );
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [cust, setCust] = useState(true);
  const [userType, setUserType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [ContactData, setContactData] = useState("");
  const toastRef = useRef(null);
  const [formErrors, setFormErrors] = useState({
    FirstName: false,
    LastName: false,
    KeyPass: false,
    confirmPassword: false,
    UserType: false,
    ContactInfo: false,
    OrgID: false,
    ContactType: false,
    Email: false,
  });

  const handleContactData = (value) => {
    setContactData(value);
  };

  const handleChange = (event) => {
    if (event.target.name === "UserType") {
      setUserType(event.target.value);
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      user_type: null,
    }));
  };

  //User Type options

  // Delete api call
  const handleDeleteCustomer = async (customerId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_AWS_URL1}/deleteUser/${customerId}`,
        {},
        { headers: { Authorization: `${token}` } }
      );

      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: `User  ${customerId} deleted successfully`,
        life: 3000,
      });
      if (refetch) {
        setRefetch(false);
      } else {
        setRefetch(true);
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  // Edit api call
  const handleUpdateCustomer = async (customerId, updatedData) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_AWS_URL1}/editUser/${customerId}`,
        updatedData,
        { headers: { Authorization: `${token}` } }
      );
      // Update the customer data in the state
      if (response.status === 200) {
        setData((prevData) =>
          prevData.map((customer) =>
            customer.user_uuid === customerId
              ? { ...customer, ...updatedData }
              : customer
          )
        );

        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `User  ${updatedData?.first_name} updated successfully`,
          life: 3000,
        });
        if (refetch) {
          setRefetch(false);
        } else {
          setRefetch(true);
        }
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          toastRef.current.show({
            severity: "warn",
            summary: "Unauthorized",
            detail: "Please authenticate.",
            life: 3000,
          });
        } else {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: `${error.response.data.message}`,
            life: 3000,
          });
        }
      }
    }
  };
  // Fetching all data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getUsers`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const formattedData = res.data.data[0].userData.map((item, index) => ({
          ...item,
          serialNo: index + 1,
          fullLastName: item.first_name + " " + item.last_name,
          full_address: `${item.address || ""} ${item.city || ""} ${
            item.state || ""
          } ${item.pincode || ""}`,
          org: item.org_id,
          Email: item.Email,
        }));
        setData(formattedData);
        setLoaded(true);
      })
      .catch((err) => {
        // console.log(err);
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "User's list cannot be fetched ! kindly try again later" ||
            err.response?.data.message,
          life: 3000,
        });
      });
  }, [token, cust, refetch]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "UserTypeLookup" },
        {
          headers: { Authorization: `${token}` },
        }
      )
      .then((res) => {
        let datatobesaved = res.data.data;
        setUserData(datatobesaved);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, refetch]);

  // const handleToggleView = () => {
  //   const newView = !isListView;
  //   setIsListView(newView);
  //   // Store the view preference in localStorage
  //   localStorage.setItem("viewPreference", newView ? "list" : "grid");
  // };

  //open add customer dialog
  const openDialog = () => {
    setIsDialogVisible(true);
    setUserType(null);
  };

  //close add customer dialog
  const closeDialog = () => {
    setIsDialogVisible(false);
    setFormErrors(false);
    setIsSubmitting(false);
    setUserType(null);
  };

  // Add Customer api call
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = event.target;
    const formData = new FormData(form);

    const data = {
      first_name: formData.get("FirstName"),
      last_name: formData.get("LastName"),
      email: formData.get("Email"),
      contact_info: formData.get("ContactInfo"),
      org_id: org_id,
      keyPass: formData.get("KeyPass"),
      // confirmPassword: formData.get("confirmPassword"),
      user_type: formData.get("UserType"),
      contact_type: formData.get("ContactType"),
    };

    setFormErrors({
      FirstName: data.first_name === "",
      LastName: data.last_name === "",
      Email: data.email === "",
      OrgID: data.org_id === "",
      UserType: data.user_type === "",
      KeyPass: data.keyPass === "",
      confirmPassword:
        data.keyPass !== formData.get("confirmPassword") || data.keyPass === "",
    });

    const requiredFields = [
      "FirstName",
      "LastName",
      "Email",
      "KeyPass",
      "confirmPassword",
      "UserType",
      "ContactInfo",
      "OrgID",
      "ContactType",
    ];

    const isAnyFieldEmpty = requiredFields.some(
      (fieldName) => data[fieldName] === ""
    );

    if (isAnyFieldEmpty) {
      toastRef.current.show({
        severity: "warn",
        summary: "Fill Required Fields",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
      setIsSubmitting(false);
      return;
    }

    if (data.keyPass !== formData.get("confirmPassword")) {
      toastRef.current.show({
        severity: "warn",
        summary: "Password Mismatch",
        detail: "Password and Confirm Password do not match.",
        life: 3000,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AWS_URL1}/createUser`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        setIsDialogVisible(false);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `User ${
            data.FirstName + " " + data.FirstName
          } Added successfully`,
          life: 3000,
        });
        setCust(data);
      } else {
        toastRef.current.show({
          severity: "success",
          summary: "success",
          detail: `${response.data.message}`,
          life: 3000,
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 401) {
          toastRef.current.show({
            severity: "warn",
            summary: "Unauthorized",
            detail: "Please authenticate.",
            life: 3000,
          });
        } else if (status === 400) {
          const { message } = data;
          toastRef.current.show({
            severity: "error",
            summary: "Validation Error",
            detail: message,
            life: 3000,
          });
          setIsSubmitting(false);
        } else if (status === 402) {
          toastRef.current.show({
            severity: "error",
            summary: "Password Mismatch",
            detail: "Passwords do not match.",
            life: 3000,
          });
          setIsSubmitting(false);
        } else {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: `${error.response.data.message}`,
            life: 3000,
          });
          setIsDialogVisible(true);
          setIsSubmitting(false);
        }
      }
    } finally {
      setIsSubmitting(false); // Reset submission status
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="flex justify-between">
        <div>
          <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
            Users
          </h4>
          {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
            <button
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
              onClick={openDialog}
            >
              <FiPlus className="mr-2" />
              New User
            </button>
          )}
        </div>
      </div>

      {/* {!isListView &&
        (loaded ? (
          <CustomersGrid
            data={data}
            onDelete={handleDeleteCustomer}
            onUpdate={handleUpdateCustomer}
          />
        ) : (
          <div className="mt-4">
            <PreloaderGrid />
          </div>
        ))}
      {isListView && ( */}
      <div className="opacity-100 transition-opacity duration-500">
        {loaded ? (
          <CustomersList
            data={data}
            onDelete={handleDeleteCustomer}
            onUpdate={handleUpdateCustomer}
          />
        ) : (
          <div className="mt-4">
            <PreloaderList />
          </div>
        )}
      </div>
      {/* )} */}

      {/* Add customer form */}
      <Dialog
        visible={isDialogVisible}
        onHide={closeDialog}
        style={{ width: "40%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit} className="mx-auto">
          <div
            className={`mt-6 grid grid-cols-2 md:grid-cols-2 2xl:grid-cols-2`}
          >
            <span className="p-float-label">
              <InputText
                id="FirstName"
                name="FirstName"
                keyfilter="alpha"
                className={`border py-1.5 pl-2 text-sm
                    ${
                      formErrors.FirstName
                        ? "border-red-600"
                        : (data.FirstName = "" ? "p-filled" : "")
                    }
                  `}
                onChange={(e) =>
                  setFormErrors({ ...formErrors, [e.target.name]: false })
                }
                autoComplete="off"
              />

              <label htmlFor="FirstName" className="dark:text-gray-300">
                First Name
              </label>
            </span>
            <span className="p-float-label">
              <InputText
                id="LastName"
                name="LastName"
                keyfilter="alpha"
                className={`border py-1.5 pl-2 text-sm ${
                  formErrors.LastName
                    ? "border-red-600"
                    : (data.LastName = "" ? "p-filled" : "")
                }`}
                onChange={(e) =>
                  setFormErrors({ ...formErrors, [e.target.name]: false })
                }
                autoComplete="off"
              />

              <label htmlFor="LastName" className="dark:text-gray-300">
                Last Name
              </label>
            </span>
          </div>
          <div
            className={`mt-6 grid grid-cols-2 md:grid-cols-2 2xl:grid-cols-2`}
          >
            <div className="flex items-center">
              <input
                type="radio"
                className="mx-2"
                name="ContactType"
                id="phone"
                value={"Phone"}
                onChange={() => handleContactData("Phone")}
              />
              <label htmlFor="phone" className="text-sm">
                Phone
              </label>
              <input
                type="radio"
                className="mx-2"
                name="ContactType"
                id="email"
                value={"Email"}
                onChange={() => handleContactData("Email")}
              />

              <label htmlFor="email" className="text-sm">
                Email
              </label>
            </div>
            {ContactData ? (
              <div>
                {ContactData === "Phone" ? (
                  <>
                    <span className="p-float-label">
                      <InputText
                        id="ContactInfo"
                        name="ContactInfo"
                        className={`border py-1.5 pl-2 text-sm `}
                        onChange={handleChange}
                        autoComplete="off"
                      />

                      <label
                        htmlFor="ContactInfo"
                        className="dark:text-gray-300"
                      >
                        Phone number
                      </label>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="p-float-label">
                      {" "}
                      <InputText
                        id="ContactInfo"
                        name="ContactInfo"
                        className={`border py-1.5 pl-2 text-sm  ${
                          formErrors.ContactInfo
                            ? "border-red-600"
                            : (data.ContactInfo = "" ? "p-filled" : "")
                        }`}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <label
                        htmlFor="ContactInfo"
                        className="dark:text-gray-300"
                      >
                        Email
                      </label>
                    </span>
                    <small className="flex items-center text-xs text-blue-400">
                      <IoIosInformationCircleOutline />
                      It should be an valid email address
                    </small>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>

          <span className="p-float-label mt-6">
            <InputText
              id="Email"
              name="Email"
              className={`border py-1.5 pl-2 text-sm ${
                formErrors.Email
                  ? "border-red-600"
                  : (data.Email = "" ? "p-filled" : "")
              }`}
              onChange={(e) =>
                setFormErrors({ ...formErrors, [e.target.name]: false })
              }
              autoComplete="off"
            />

            <label htmlFor="Email" className="dark:text-gray-300">
              Username
            </label>
          </span>
          <div
            className={`mt-6 grid grid-cols-2 md:grid-cols-2 2xl:grid-cols-2`}
          >
            <span className="p-float-label">
              <InputText
                id="KeyPass"
                type={showPassword ? "text" : "password"}
                name="KeyPass"
                className={`border py-1.5 pl-2 text-sm ${
                  formErrors.KeyPass
                    ? "border-red-600"
                    : data.KeyPass !== ""
                    ? ""
                    : "p-filled"
                }`}
                onChange={(e) =>
                  setFormErrors({ ...formErrors, [e.target.name]: false })
                }
                minLength={6}
                autoComplete="off"
              />

              <label htmlFor="KeyPass" className="dark:text-gray-300">
                Password
              </label>
              <div className="absolute right-2.5 top-[0.7rem]">
                {showPassword ? (
                  <FaEyeSlash
                    className="h-4 w-4 cursor-pointer text-gray-500"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <FaEye
                    className="h-4 w-4 cursor-pointer text-gray-600"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </span>
            <span className="p-float-label">
              <InputText
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className={`border py-1.5 pl-2 text-sm ${
                  formErrors.confirmPassword
                    ? "border-red-600"
                    : (data.confirmPassword = ""
                        ? "p-filled border-red-600"
                        : "")
                }`}
                onChange={(e) =>
                  setFormErrors({ ...formErrors, [e.target.name]: false })
                }
                autoComplete="off"
              />

              <label htmlFor="confirmPassword" className="dark:text-gray-300">
                Confirm Password
              </label>
              <div className="absolute right-2.5 top-[0.7rem]">
                {showConfirmPassword ? (
                  <FaEyeSlash
                    className="h-4 w-4 cursor-pointer text-gray-500"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <FaEye
                    className="h-4 w-4 cursor-pointer text-gray-600"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
            </span>
          </div>
          <div
            className={`mx-auto mt-4 grid w-full grid-cols-2 md:grid-cols-2`}
          >
            <div className="mx-auto mb-2 mt-4 w-full flex-1">
              <span className="p-float-label">
                <Dropdown
                  id="UserType"
                  name="UserType"
                  options={userData}
                  value={userType}
                  optionLabel="userTypeName"
                  optionValue="userTypeId"
                  onChange={handleChange}
                  className={`h-8 border pl-0 text-sm ${
                    formErrors.UserType ? "border-red-600" : ""
                  }`}
                />
                <label htmlFor="UserType" className="dark:text-gray-300">
                  User Type
                </label>
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            {isSubmitting ? (
              <div className="flex items-center">
                <span className="mr-2 animate-spin">
                  {/* You can use a suitable loading spinner here */}
                  <svg
                    className="h-5 w-5 animate-spin text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.84 3 7.95l3-2.658z"
                    ></path>
                  </svg>
                </span>
                Submitting...
              </div>
            ) : (
              <button
                type="submit"
                className="ml-auto rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
              >
                Add user
              </button>
            )}
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default Customers;
