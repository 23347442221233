import axios from "axios";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineDisconnect } from "react-icons/ai";
import { Dialog } from "primereact/dialog";
import { IoIosInformationCircle } from "react-icons/io";
import { AppContext } from "context/AppContext";

const CorelationListRoot = ({ data, toast }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [devicetypes, setDeviceTypes] = useState([]);
  const [disconnectDialogVisible, setDisconnectDialogVisible] = useState(false);
  const [corelId, setCorelId] = useState([]);
  const token = Cookies.get("token");
  const { updateCorel } = useContext(AppContext);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "DeviceTypeLookup" },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setDeviceTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  //Global search logic
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue(""); // Clear the search input value
    const _filters = { ...filters };
    _filters["global"].value = null; // Clear the global filter value
    setFilters(_filters);
  };
  // Status body
  const getStatusSeverity = (option) => {
    switch (option) {
      case 1:
        return "success";

      case 2:
        return "danger";

      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData.device_status === 1
            ? "Active"
            : rowData.device_status === 2
            ? "Deactive"
            : "Deleted"
        }
        severity={getStatusSeverity(rowData.device_status)}
        className="h-5 rounded-sm text-xs font-normal"
      />
    );
  };
  const openDiscoonectDialog = (rowData) => {
    setDisconnectDialogVisible(true);
    setCorelId(rowData.corel_id);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <AiOutlineDisconnect
          title="Delete connection"
          onClick={() => openDiscoonectDialog(rowData)}
          className="mx-2 cursor-pointer text-red-600"
        />
      </>
    );
  };

  const DisconnectDialog = ({ visible, onHide }) => {
    const handleDisconnection = () => {
      axios
        .put(`${process.env.REACT_APP_AWS_URL2}/deleteCoRel/${corelId}`, null, {
          headers: { Authorization: token },
        })
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `Selected connection is deleted successfully`,
            life: 3000,
          });
          updateCorel();
          onHide();
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
          onHide();
        });
    };

    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Unassign"
        footer={
          <div>
            <Button
              label="Confirm"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDisconnection}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <IoIosInformationCircle className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete the connection ?
          </span>
        </div>
      </Dialog>
    );
  };

  const renderHeader = () => {
    return (
      <div className="mb-2 flex justify-end  dark:bg-gray-950">
        <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
          <i className="pi pi-search pl-2 dark:text-gray-300" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
          />
          {globalFilterValue && (
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
              onClick={clearSearch}
            />
          )}
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <DataTable
        value={data}
        removableSort
        paginator
        header={header}
        dataKey="serialNo"
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "device_id",
          "sim_number",
          "org_id",
          "device_type_id",
        ]}
        emptyMessage="No connections found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        ></Column>
        <Column
          field="device_id"
          header="Device ID"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          field="sim_number"
          header="Sim Number"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          header="Sim Validity"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", textAlign: "left", padding: "5px" }}
          body={(rowData) => {
            const date = rowData.sim_validity.split(" ")[0];
            return <span>{date}</span>;
          }}
        ></Column>
        <Column
          field="org_name"
          header="Organization"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          header="Device status"
          body={statusBodyTemplate}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          field="device_type_id"
          header="Device Type"
          sortField="device_type_id"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "6rem", padding: "5px" }}
          body={(rowData) => {
            const deviceTypeId = rowData.device_type_id;
            const matchedDevice = devicetypes.find(
              (type) => type.deviceTypeId === deviceTypeId
            );

            return (
              <Tag
                className="mr-2 bg-gray-200 text-gray-800"
                icon="pi pi-tag"
                style={{
                  width: "fit-content",
                  height: "20px",
                }}
                value={matchedDevice ? matchedDevice.deviceName : "Unknown"}
              />
            );
          }}
        ></Column>
        <Column
          header="Delete"
          body={actionBodyTemplate}
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
        ></Column>
      </DataTable>
      <DisconnectDialog
        visible={disconnectDialogVisible}
        onHide={() => setDisconnectDialogVisible(false)}
      />
    </>
  );
};

export default CorelationListRoot;
