import React from "react";

// Admin Imports
import Dashboard from "views/admin/default";
import VehiclesAdmin from "views/admin/vehicles";
import DevicesAdmin from "views/admin/devices";
import Corelations from "views/admin/analytics_threshold";
import FeatureSet from "views/admin/feature_set";
import Customers from "views/admin/customers";
import Contacts from "views/admin/contacts";

// Icon Imports
import {
  MdOutlineDashboard,
  MdOutlineFeaturedPlayList,
  MdContactPhone,
  MdOutlineSimCard,
} from "react-icons/md";
import { CgOptions, CgOrganisation } from "react-icons/cg";
import { BsTruck, BsFillCpuFill } from "react-icons/bs";
import { TbDeviceAnalytics, TbReport } from "react-icons/tb";
import { FaLocationPinLock } from "react-icons/fa6";
import { HiOutlineUsers } from "react-icons/hi";
import Organization from "views/admin/organization";
import SimCards from "views/admin/sim_cards";
import Cookies from "js-cookie";
import CorelationsRoot from "views/admin/corelations_root";
import { BiBeenHere } from "react-icons/bi";
import CompletedTrips from "views/admin/trips";
import CompletedTripView from "views/admin/trips/components/CompletedTripView";
import OngoingTripView from "views/admin/trips/components/OngoiongTripView";
import FeaturesetOption from "views/admin/featureset_options";
import { RiAlertLine } from "react-icons/ri";
import Triggers from "views/admin/alert-triggers";
import Reports from "views/admin/reports";
import VehicleView from "views/admin/vehicles/components/VehicleView";
import Driver from "views/admin/driver";
import Geofence from "views/admin/geofence";
import AddGeofence from "views/admin/geofence/components/AddGeofence";
import { IoMdPerson } from "react-icons/io";
import DriverView from "views/admin/driver/components/DriverView";

const user_type = Cookies.get("user_type");

// Define routes for Admin panel
export const routes_admin = [
  //root
  ...(user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e"
    ? [
        {
          name: "Dashboard",
          title: "Dashboard",
          path: "dashboard",
          icon: <MdOutlineDashboard className="h-6 w-6" />,
          component: <Dashboard />,
        },
        {
          name: "Organization",
          title: "Organization",
          path: "organization",
          icon: <CgOrganisation className="h-6 w-6" />,
          component: <Organization />,
        },
        {
          name: "Completed-Trips",
          title: "Trips",
          path: "trips",
          icon: <BiBeenHere className="h-6 w-6" />,
          component: <CompletedTrips />,
        },
        {
          name: "Completed-trip",
          path: "trips/completed-trip/:trip_id",
          component: <CompletedTripView />,
        },
        {
          name: "Ongoing-trip",
          path: "trips/ongoing-trip/:trip_id",
          component: <OngoingTripView />,
        },
        {
          name: "Users",
          title: "Users",
          path: "users",
          icon: <HiOutlineUsers className="h-6 w-6" />,
          component: <Customers />,
        },
        {
          name: "Sim Cards",
          title: "Sim Cards",
          path: "sim-cards",
          icon: <MdOutlineSimCard className="h-6 w-6" />,
          component: <SimCards />,
        },
        {
          name: "Vehicles",
          title: "Vehicles",
          path: "vehicles",
          icon: <BsTruck className="h-6 w-6" />,
          component: <VehiclesAdmin />,
          secondary: true,
        },
        {
          name: "Vehicles",
          path: "vehicles/:vehicle_id",
          component: <VehicleView />,
        },
        {
          name: "Devices",
          title: "Devices",
          icon: <BsFillCpuFill className="h-6 w-6" />,
          path: "devices",
          component: <DevicesAdmin />,
        },
        {
          name: "Featureset Options",
          title: "Featureset Options",
          path: "featureset-options",
          icon: <CgOptions className="h-6 w-6" />,
          component: <FeaturesetOption />,
        },
        {
          name: "Feature Set",
          title: "Feature Set",
          path: "feature-set",
          icon: <MdOutlineFeaturedPlayList className="h-6 w-6" />,
          component: <FeatureSet />,
        },
        {
          name: "Corelations",
          title: "Connections",
          path: "co-relations",
          icon: <TbDeviceAnalytics className="h-6 w-6" />,
          component: <CorelationsRoot />,
        },
      ]
    : //driver
    user_type === "f0003b02-9e34-11ee-9fc8-0a33c87d103e"
    ? [
        {
          name: "Dashboard",
          title: "Dashboard",
          path: "dashboard",
          icon: <MdOutlineDashboard className="h-6 w-6" />,
          component: <Dashboard />,
        },

        {
          name: "Completed-Trips",
          title: "Trips",
          path: "trips",
          icon: <BiBeenHere className="h-6 w-6" />,
          component: <CompletedTrips />,
        },
        {
          name: "Completed-trip",
          path: "trips/completed-trip/:trip_id",
          component: <CompletedTripView />,
        },
        {
          name: "Ongoing-trip",
          path: "trips/ongoing-trip/:trip_id",
          component: <OngoingTripView />,
        },
        // {
        //   name: "Users",
        //   title: "Users",
        //   path: "users",
        //   icon: <HiOutlineUsers className="h-6 w-6" />,
        //   component: <Customers />,
        // },

        {
          name: "Vehicles",
          title: "Vehicles",
          path: "vehicles",
          icon: <BsTruck className="h-6 w-6" />,
          component: <VehiclesAdmin />,
          secondary: true,
        },
        {
          name: "Vehicles",
          path: "vehicles/:vehicle_id",
          component: <VehicleView />,
        },
      ] //customer
    : [
        {
          name: "Dashboard",
          title: "Dashboard",
          path: "dashboard",
          icon: <MdOutlineDashboard className="h-6 w-6" />,
          component: <Dashboard />,
        },
        {
          name: "Organization",
          title: "Organization",
          path: "organization",
          icon: <CgOrganisation className="h-6 w-6" />,
          component: <Organization />,
        },
        {
          name: "Completed-Trips",
          title: "Trips",
          path: "trips",
          icon: <BiBeenHere className="h-6 w-6" />,
          component: <CompletedTrips />,
        },
        {
          name: "Completed-trip",
          path: "trips/completed-trip/:trip_id",
          component: <CompletedTripView />,
        },
        {
          name: "Ongoing-trip",
          path: "trips/ongoing-trip/:trip_id",
          component: <OngoingTripView />,
        },
        // {
        //   name: "Users",
        //   title: "Users",
        //   path: "users",
        //   icon: <HiOutlineUsers className="h-6 w-6" />,
        //   component: <Customers />,
        // },
        {
          name: "Sim Cards",
          title: "Sim Cards",
          path: "sim-cards",
          icon: <MdOutlineSimCard className="h-6 w-6" />,
          component: <SimCards />,
        },
        {
          name: "Vehicles",
          title: "Vehicles",
          path: "vehicles",
          icon: <BsTruck className="h-6 w-6" />,
          component: <VehiclesAdmin />,
          secondary: true,
        },
        {
          name: "Vehicles",
          path: "vehicles/:vehicle_id",
          component: <VehicleView />,
        },
        {
          name: "Devices",
          title: "Devices",
          icon: <BsFillCpuFill className="h-6 w-6" />,
          path: "devices",
          component: <DevicesAdmin />,
        },
        // {
        //   name: "Drivers",
        //   title: "Drivers",
        //   icon: <IoMdPerson className="h-6 w-6" />,
        //   path: "drivers",
        //   component: <Driver />,
        //   secondary: true,
        // },
        {
          name: "Drivers",
          // title: "Drivers",
          // icon: <BsFillCpuFill className="h-6 w-6" />,
          path: "drivers/:id",
          component: <DriverView />,
        },
        {
          name: "Feature Set",
          title: "Feature Set",
          path: "feature-set",
          icon: <MdOutlineFeaturedPlayList className="h-6 w-6" />,
          component: <FeatureSet />,
        },
        {
          name: "Corelations",
          title: "Connections",
          path: "co-relations",
          icon: <TbDeviceAnalytics className="h-6 w-6" />,
          component: <Corelations />,
        },
        {
          name: "Contacts",
          title: "Contacts",
          path: "contacts",
          icon: <MdContactPhone className="h-6 w-6" />,
          component: <Contacts />,
        },
        {
          name: "Alert Triggers",
          title: "Alert Triggers",
          path: "alert-triggers",
          icon: <RiAlertLine className="h-6 w-6" />,
          component: <Triggers />,
        },
        {
          name: "Reports",
          title: "Reports",
          path: "reports",
          icon: <TbReport className="h-6 w-6" />,
          component: <Reports />,
        },
        {
          name: "Geofence",
          title: "Geofence",
          path: "geofence",
          icon: <FaLocationPinLock className="h-6 w-6" />,
          component: <Geofence />,
          secondary: true,
        },
        {
          name: "Geofence",
          path: "geofence/add-geofence",
          component: <AddGeofence />,
        },
      ]),
];
