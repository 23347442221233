import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import PreloaderList from "components/skeleton-preloader/PreloaderList";

const VehicleTrips = ({ startDate, endDate }) => {
  const { vehicle_id } = useParams();
  const token = Cookies.get("token");
  const [totalRecord, setTotalRecords] = useState(0);
  const [tripData, setTripData] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="my-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  //vehicle trip data

  function convertEpochToIST(epoch) {
    const date = new Date(epoch * 1000); // Convert seconds to milliseconds
    return date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
  }

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = (event) => {
    if (event.rows !== rows) {
      setRows(event.rows);
    } else {
      setCurrentPage(event.page);
    }
  };
  useEffect(() => {
    const x = localStorage.getItem("setTripPage");
    if (x) {
      setCurrentPage(x);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("setTripPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AWS_URL2}/vehicleTripsByid/${vehicle_id}`,
          {
            from_epoch: startDate,
            to_epoch: endDate,
            limit: rows.toString(),
            offset: (currentPage * rows).toString(),
          },
          {
            headers: { Authorization: token },
          }
        );

        const formattedData = response.data.data?.map((item, index) => ({
          ...item,
          key: index + 1,
          serialNo: index + 1,
          distance: Math.round(item.total_distance * 100) / 100,
        }));

        setTripData(formattedData);
        setTotalRecords(response.data.data[0]?.total_count || 0);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [
    rows,
    currentPage,
    endDate,
    vehicle_id,
    startDate,
    token,
    setTripData,
    setTotalRecords,
  ]);

  const redirect = (selectedProduct) => {
    const url1 = `/trips/completed-trip/${selectedProduct?.trip_id}`;
    window.open(url1, "_blank");
  };

  useEffect(() => {
    if (selectedProduct !== null) {
      redirect(selectedProduct);
    }
  }, [selectedProduct]);

  const handleSelectionChange = (e) => {
    setSelectedProduct(e.value);
  };

  return (
    <>
      {tripData ? (
        <>
          <p className="bg-[#ddd] text-center text-lg">Total Trips</p>
          <DataTable
            removableSort
            value={tripData}
            dataKey="serialNo"
            filters={filters}
            globalFilterFields={[
              "trip_id",
              "trip_start_time",
              "trip_end_time",
              "duration",
              "device_id",
              "distance",
            ]}
            emptyMessage="No trips found."
            header={header}
            className="w-full"
            onPage={onPageChange}
            first={currentPage * rows}
            selectionMode="single"
            selection={selectedProduct}
            onSelectionChange={handleSelectionChange}
          >
            <Column
              field="serialNo"
              header="#"
              className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{
                minWidth: "4rem",
                paddingTop: "5px",
                paddingLeft: "2rem",
                paddingBottom: "5px",
              }}
              bodyStyle={{
                textAlign: "left",
                overflow: "visible",
                paddingTop: "0px",
                paddingLeft: "2rem",
                paddingBottom: "0px",
              }}
            ></Column>
            <Column
              field="trip_id"
              header="Trip ID"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
            ></Column>
            <Column
              field="trip_start_time"
              header="Start Time"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
              bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
              // body={(rowData) => convertEpochToIST(rowData.trip_start_time)}
              body={(rowData) => {
                return (
                  <Tag
                    className="mr-2 bg-green-100 text-gray-800"
                    style={{
                      width: "fit-content",
                      height: "25px",
                    }}
                    value={convertEpochToIST(rowData?.trip_start_time)}
                    icon="pi pi-caret-right"
                  />
                );
              }}
            ></Column>
            <Column
              field="trip_end_time"
              header="End Time"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
              // body={(rowData) => convertEpochToIST(rowData.trip_end_time)}
              body={(rowData) => {
                return (
                  <Tag
                    className="mr-2 bg-red-200 text-gray-800"
                    style={{
                      width: "fit-content",
                      height: "25px",
                    }}
                    icon="pi pi-stop-circle"
                    value={convertEpochToIST(rowData?.trip_end_time)}
                  />
                );
              }}
            ></Column>
            <Column
              field="distance"
              header="Distance"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "7rem", padding: "5px" }}
              body={(rowData) => {
                const distance = parseFloat(rowData?.distance);
                const formattedDistance = distance.toFixed(2);
                return formattedDistance + " km";
              }}
            ></Column>
            <Column
              field="duration"
              header="Duration"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "7rem", padding: "5px" }}
              body={(rowData) => {
                return formatDuration(rowData.duration);
              }}
            ></Column>
          </DataTable>
          <div className="flex items-center justify-center">
            <Paginator
              first={currentPage * rows}
              rows={rows}
              totalRecords={totalRecord}
              onPageChange={onPageChange}
              paginatorTemplate={`FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown`}
              rowsPerPageOptions={[10, 25, 50]}
            />
            <p className="text-xs text-gray-700">
              Showing {rows} trips of {totalRecord}
            </p>
          </div>
        </>
      ) : (
        <PreloaderList />
      )}
    </>
  );
};

export default VehicleTrips;
