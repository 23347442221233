import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
const DriverView = () => {
  const { id } = useParams();
  const [useViewData, setUserViewData] = useState();
  const token = Cookies.get("token");
  const handleGetUserDetails = async () => {
    // if (user_id) {  3b7ccde4-4982-11ef-a4a5-0affb355c66d
    try {
      // getDriverDetailsByUserId/{user_id}
      const response = await axios.get(
        `${process.env.REACT_APP_AWS_URL1}/getDriverDetailsByUserId/${id}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (response.data.data) {
        setUserViewData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };
  useEffect(() => {
    handleGetUserDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <div className="grid sm:grid-cols-3">
        <div className="rounded-lg bg-white p-4 shadow-lg">
          <h2 className="mb-4 border-b-4 text-2xl font-bold">
            <span className="">User Details</span>
          </h2>
          <div className="mt-2 flex">
            <p className="w-4/12 font-bold">First Name:</p>
            <p className="w-4/12">
              {useViewData && useViewData[0]?.first_name}
            </p>
          </div>
          <div className="mt-2 flex">
            <p className="w-4/12 font-bold">Last Name:</p>
            <p className="w-4/12">{useViewData && useViewData[0]?.last_name}</p>
          </div>
          <div className="mt-2 flex">
            <p className="w-4/12 font-bold">Vehical Status:</p>
            <p className="flex w-4/12 items-center">
              {/* {useViewData && useViewData[0]?.vehicle_active} */}
              <GoDotFill
                className={`${
                  useViewData && useViewData[0]?.vehicle_active
                    ? "text-green-500"
                    : "text-rose-500"
                }`}
              />
            </p>
          </div>
          {/* <div className='flex mt-2'>
           <p className='w-4/12 font-bold'>Vehical Name:</p>
           <p className='w-4/12'>ujhvb</p>
        </div> */}
          <div className="mt-2 flex">
            <p className="w-4/12 font-bold">Registration No:</p>
            <p className="w-4/12">
              {useViewData && useViewData[0]?.vehicle_reg_number}
            </p>
          </div>
          <div className="mt-2 flex">
            <p className="w-4/12 font-bold">Email:</p>
            <p className="w-4/12">{useViewData && useViewData[0]?.email}</p>
          </div>
        </div>
        <div className="rounded-lg bg-white p-4 shadow-lg">
          <h2 className="mb-4 border-b-4 text-2xl font-bold">Vehicle Assign</h2>
          {/* Wrapper for the scrollable content */}
          <div className="h-64 overflow-y-auto">
            {useViewData &&
              useViewData.map((data, index) => (
                <div key={index} className="mt-2 flex flex-col border-b-2">
                  <div className="flex">
                    <p className="w-4/12 font-bold">Vehicle Name:</p>
                    <p className="w-4/12">{data?.vehicle_name}</p>
                  </div>
                  <div className="flex">
                    <p className="w-4/12 font-bold">Vehicle Reg No:</p>
                    <p className="w-4/12">{data?.vehicle_reg_number}</p>
                  </div>
                  {/* <div className="flex">
            <p className="w-4/12 font-bold">Vehicle Name:</p>
            <p className="w-4/12">{data?.vehicle_name}</p>
          </div>
          <div className="flex">
            <p className="w-4/12 font-bold">Vehicle Reg No:</p>
            <p className="w-4/12">{data?.vehicle_reg_number}</p>
          </div>
          <div className="flex">
            <p className="w-4/12 font-bold">Vehicle Name:</p>
            <p className="w-4/12">{data?.vehicle_name}</p>
          </div>
          <div className="flex">
            <p className="w-4/12 font-bold">Vehicle Reg No:</p>
            <p className="w-4/12">{data?.vehicle_reg_number}</p>
          </div> */}
                </div>
              ))}
          </div>
        </div>

        <div className="rounded-lg bg-white p-4 shadow-lg">
          <h2 className="mb-4 border-b-4 text-2xl font-bold">
            <span className="">Score</span>
          </h2>
          <div className="flex  items-center justify-center text-[120px] text-cyan-500">
            <p>96</p>
          </div>

          {/* <p className="mb-4 text-2xl text-center">Upcoming</p> */}
        </div>
      </div>
    </>
  );
};

export default DriverView;
