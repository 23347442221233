import axios from "axios";
import { AppContext } from "context/AppContext";
import Cookies from "js-cookie";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";

const DTV = ({ toastRef, closeDialog }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedFs, setSelectedFs] = useState(null);
  const [devices, setDevices] = useState([]);
  const [featurset, setFeatureset] = useState([]);
  const org_id = Cookies.get("org_id");
  const token = Cookies.get("token");
  const { updateCorel } = useContext(AppContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getUnLinkedDevicesRelations/${org_id}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setDevices(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //FS option data
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getFeatureset`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setFeatureset(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, org_id]);

  const vehicleOptions = () => {
    return vehicles?.map((el) => ({
      label: el.vehicle_name + " [ " + el.vehicle_reg_number + " ]",
      value: el.vehicle_id,
    }));
  };

  const deviceOptions = () => {
    return devices?.map((el) => ({
      label: el.device_id,
      value: el,
    }));
  };

  const fsOptions = () => {
    return featurset?.map((el) => ({
      label: el.featureset_name,
      value: el,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //publish the featureset
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/featuresetPublish`,
        {
          device_id: selectedDevice.device_id,
          featureset_data: selectedFs.featureset_data,
          device_type: selectedDevice.device_type_id,
          org_id: org_id,
        },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    //Add data to coRelations
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/addCoRelations`,
        {
          corel_type: "DTV",
          corel_with: selectedDevice.device_id,
          corel_to: selectedOption,
          device_type_id: selectedDevice.device_type_id,
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        const selectedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === selectedOption
        );
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `${selectedDevice.device_id} is successfully assigned to ${selectedVehicle.vehicle_name}`,
          life: 3000,
        });
        closeDialog();
        updateCorel();
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

  const selectedVehicleTemplate = (option, props) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const vehicleOptionTemplate = (option) => {
    return (
      <div className="align-items-center flex">
        <div>{option.label}</div>
      </div>
    );
  };
  const selectedDeviceTemplate = (option, props) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const deviceOptionTemplate = (option) => {
    return (
      <div className="align-items-center flex">
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
          <span className="p-float-label">
            <Dropdown
              id="vehicle"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.value)}
              options={vehicleOptions()}
              optionLabel="label"
              filter
              valueTemplate={selectedVehicleTemplate}
              itemTemplate={vehicleOptionTemplate}
              className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
            />
            <label htmlFor="vehicle" className="dark:text-gray-300">
              Select a Vehicle
            </label>
          </span>
          <span className="p-float-label">
            <Dropdown
              id="device"
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.value)}
              options={deviceOptions()}
              optionLabel="label"
              filter
              valueTemplate={selectedDeviceTemplate}
              itemTemplate={deviceOptionTemplate}
              className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
            />
            <label htmlFor="device" className="dark:text-gray-300">
              Select a Device
            </label>
          </span>

          <span className="p-float-label mt-4">
            <Dropdown
              id="fs"
              value={selectedFs}
              onChange={(e) => setSelectedFs(e.value)}
              options={fsOptions()}
              optionLabel="label"
              className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
            />
            <label htmlFor="fs" className="dark:text-gray-300">
              Select a Featureset
            </label>
          </span>
        </div>

        <div className="flex justify-end p-4">
          <button
            type="submit"
            className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          >
            Assign
          </button>
        </div>
      </form>
    </>
  );
};

export default DTV;
