import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import axios from "axios";
import Cookies from "js-cookie";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { FiLoader } from "react-icons/fi";

const Quick = ({ close }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const token = Cookies.get("token");
  const user_uuid = Cookies.get("user_uuid");
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [titleValid, setTitleValid] = useState(true);
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);
  const [vehicleValid, setVehicleValid] = useState(true);
  const [eventValid, setEventValid] = useState(true);
  const toastRef = useRef(null);
  const [selectedVehicleNames, setSelectedVehicleNames] = useState([]);
  const [selectedEventNames, setSelectedEventNames] = useState([]);
  const [loader, setLoader] = useState(false);

  const events = [
    { name: "Alarm", code: "ALM" },
    { name: "Break Data", code: "BRK" },
    { name: "Accelarator Cut Data", code: "ACC" },
    { name: "Limp Mode Data", code: "LMP" },
    { name: "Accident Data", code: "ACD" },
    { name: "DMS", code: "DMS" },
    { name: "Notification Data", code: "NTF" },
  ];

  //get contacts list

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid]);

  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const today = new Date();

    if (title.trim() === "") {
      setTitleValid(false);
    } else {
      setTitleValid(true);
    }

    const startDate = selectedStartDate ? new Date(selectedStartDate) : null;
    const endDate = selectedEndDate ? new Date(selectedEndDate) : null;

    if (!startDate) {
      setStartDateValid(false);
    } else if (startDate > endDate) {
      setStartDateValid(false);
      toastRef.current.show({
        severity: "error",
        summary: "Invalid Start Date",
        detail: "Start date can not be before end date.",
        life: 3000,
      });
      setLoader(false);
      return;
    } else {
      setStartDateValid(true);
    }

    // Check and set the end date validity
    if (!endDate) {
      setEndDateValid(false);
    } else if (endDate > today) {
      setEndDateValid(false);
      toastRef.current.show({
        severity: "warn",
        summary: "Invalid End Date",
        detail: "End date cannot be in the future.",
        life: 3000,
      });
      setLoader(false);
      return;
    } else {
      setEndDateValid(true);
    }

    // Check and set the end date validity
    if (selectedEndDate > today) {
      setEndDateValid(false);
      toastRef.current.show({
        severity: "error",
        summary: "Invalid End Date",
        detail: "End date cannot be in the future.",
        life: 3000,
      });
      setLoader(false);
    } else {
      setEndDateValid(true);
    }

    // Check and set the end date validity
    if (!selectedEndDate) {
      setEndDateValid(false);
    } else {
      setEndDateValid(true);
    }

    // Check and set the vehicle validity
    if (!selectedVehicles || selectedVehicles.length === 0) {
      setVehicleValid(false);
    } else {
      setVehicleValid(true);
    }

    // Check and set the event validity
    if (!selectedEvents || selectedEvents.length === 0) {
      setEventValid(false);
    } else {
      setEventValid(true);
    }

    // Check if any field is invalid
    if (
      !titleValid ||
      !startDateValid ||
      !endDateValid ||
      !vehicleValid ||
      !eventValid
    ) {
      toastRef.current.show({
        severity: "warn",
        summary: "Fill Required Fields",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
      setLoader(false);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/createMiniReport`,
        {
          title: title,
          fromDate: startDate,
          toDate: endDate,
          vehicle_id: selectedVehicles,
          events: selectedEvents,
        },
        { headers: { Authorization: token } }
      )
      .then((res) => {
        console.log(res.data);
        setLoader(false);
        close();
        localStorage.setItem("quickReportData", JSON.stringify(res.data.data));
        window.open("/report/quick-report", "_blank");
      })
      .catch((error) => {
        toastRef.current.show({
          severity: "warn",
          summary: "Fill Required Fields",
          detail: error.message,
          life: 3000,
        });
        setLoader(false);
      });
  };

  const vehicleOptions = () => {
    const optionsArray = [];
    vehicles?.forEach((el) => {
      optionsArray.push({
        key: el.vehicle_id,
        name: el.vehicle_name + " [" + el.vehicle_reg_number + "]",
        code: el.vehicle_id,
      });
    });
    return optionsArray;
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div>
        <p className="text-right text-sm text-red-500">
          All Fields Are Required<span> **</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <div className="mt-8">
              <span className="p-float-label">
                <InputText
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setTitleValid(e.target.value.trim() !== "");
                  }}
                  className={`h-8 border border-gray-300 py-1.5 pl-2 text-sm ${
                    !titleValid ? "border-red-600" : ""
                  }`}
                />
                <label
                  htmlFor="title"
                  className="text-gray-600 dark:text-gray-150"
                >
                  Title
                </label>
              </span>
              {!titleValid && (
                <small className="text-red-500">Title is required.</small>
              )}
            </div>
            <div className="card p-fluid mt-6 flex flex-wrap gap-3">
              <div className="flex-auto">
                <span className="p-float-label">
                  <Calendar
                    inputId="start_date"
                    value={
                      selectedStartDate ? new Date(selectedStartDate) : null
                    }
                    onChange={(e) => {
                      setSelectedStartDate(e.value);
                      setStartDateValid(!!e.value);
                    }}
                    className={`p-dropdown h-10 rounded-lg border border-gray-300 ${
                      !startDateValid ? "border-red-600" : ""
                    }`}
                    dateFormat="dd/mm/yy"
                  />
                  <label
                    htmlFor="start_date"
                    className="text-gray-600 dark:text-gray-150"
                  >
                    From
                  </label>
                </span>
                {!startDateValid ? (
                  <small className="text-xs text-red-500">
                    From date is required.
                  </small>
                ) : (
                  <small className="text-xs text-gray-400 dark:text-gray-150">
                    Click to Select
                  </small>
                )}
              </div>
              <div className="flex-auto">
                <span className="p-float-label">
                  <Calendar
                    inputId="end_date"
                    value={selectedEndDate ? new Date(selectedEndDate) : null}
                    onChange={(e) => {
                      setSelectedEndDate(e.value);
                      setEndDateValid(!!e.value);
                    }}
                    className={`p-dropdown h-10 rounded-lg border border-gray-300 ${
                      !endDateValid ? "border-red-600" : ""
                    }`}
                    dateFormat="dd/mm/yy"
                  />
                  <label
                    htmlFor="end_date"
                    className="text-gray-600  dark:text-gray-150"
                  >
                    To
                  </label>
                </span>

                {!endDateValid ? (
                  <small className="text-xs text-red-500">
                    To date is required.
                  </small>
                ) : (
                  <small className="text-xs text-gray-400 dark:text-gray-150">
                    Click to Select
                  </small>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
              <div className="mt-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={selectedVehicleNames}
                    options={vehicleOptions()}
                    onChange={(e) => {
                      setSelectedVehicleNames(e.value);
                      setSelectedVehicles(
                        e.target.value.map((vehicle) => vehicle.code)
                      );

                      setVehicleValid(e.target.value.length > 0);
                    }}
                    // selectAllLabel="Select All Vehicles"
                    placeholder="Select vehicles"
                    optionLabel="name"
                    filter
                    className={`h-10 rounded-lg border border-gray-300 shadow-sm dark:bg-gray-900 dark:placeholder-gray-50 ${
                      !vehicleValid ? "border-red-600" : ""
                    }`}
                  />

                  <label
                    htmlFor="vehicle"
                    className="text-gray-600 dark:text-gray-150"
                  >
                    Select Vehicles
                  </label>
                </span>
                {!vehicleValid && (
                  <small className="text-red-500">Vehicle is required.</small>
                )}
              </div>
              <div className="mt-6">
                <span className="p-float-label">
                  <MultiSelect
                    value={selectedEventNames}
                    options={events}
                    onChange={(e) => {
                      setSelectedEventNames(e.value);
                      setSelectedEvents(
                        e.target.value.map((event) => event.code)
                      );
                      setEventValid(e.target.value);
                    }}
                    selectAllLabel="Select All Events"
                    optionLabel="name"
                    className={`h-10 rounded-lg border border-gray-300 shadow-sm dark:bg-gray-900 dark:text-gray-300 dark:placeholder-gray-50 ${
                      !eventValid ? "border-red-600" : ""
                    }`}
                  />
                  <label
                    htmlFor="vehicle"
                    className="text-gray-600 dark:text-gray-150"
                  >
                    Select Events
                  </label>
                </span>
                {!eventValid && (
                  <small className="text-red-500">Event is required.</small>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className={`rounded-lg bg-blue-500 px-2 py-1 text-left text-sm text-white dark:bg-gray-150 dark:font-bold dark:text-blue-800 ${
                loader
                  ? "cursor-not-allowed bg-blue-200 text-blue-500"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              } `}
              disabled={loader}
              type="submit"
            >
              {loader ? (
                <>
                  Loading...{" "}
                  <FiLoader className="inline-block animate-spin text-gray-100" />
                </>
              ) : (
                <>Generate</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Quick;
