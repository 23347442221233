import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import axios from "axios";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import { AiOutlineDisconnect } from "react-icons/ai";
import { IoIosInformationCircle } from "react-icons/io";
// import { TabPanel, TabView } from "primereact/tabview";
import { AppContext } from "context/AppContext";

const CoRelationsList = ({ dataDTV, toast }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [devicetypes, setDeviceTypes] = useState([]);
  // const [vehicletypes, setVehicleTypes] = useState([]);
  const [disconnectDialogVisible, setDisconnectDialogVisible] = useState(false);
  const [corelId, setCorelId] = useState([]);
  const { updateCorel } = useContext(AppContext);
  const token = Cookies.get("token");
  //Global search logic
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };
  //Searchbox
  const renderHeader = () => {
    return (
      <div className="mb-2 flex justify-end  dark:bg-navy-800">
        <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
          <i className="pi pi-search pl-2 dark:text-gray-300" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
          />
          {globalFilterValue && (
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
              onClick={clearSearch}
            />
          )}
        </span>
      </div>
    );
  };
  const header = renderHeader();

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "DeviceTypeLookup" },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setDeviceTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .post(
    //     `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
    //     { FetchType: "VehicleTypeLookup" },
    //     {
    //       headers: { Authorization: token },
    //     }
    //   )
    //   .then(() => {
    //     // setVehicleTypes(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [token]);

  const devicesOptions = () => {
    return devicetypes?.map((el) => ({
      label: el.deviceName,
      value: el.deviceTypeId,
    }));
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <AiOutlineDisconnect
          title="Delete connection"
          onClick={() => openDiscoonectDialog(rowData)}
          className="mx-2 cursor-pointer text-red-600"
        />
      </>
    );
  };

  const openDiscoonectDialog = (rowData) => {
    setDisconnectDialogVisible(true);
    setCorelId(rowData.corel_id);
  };

  const DisconnectDialog = ({ visible, onHide }) => {
    const handleDisconnection = () => {
      axios
        .put(`${process.env.REACT_APP_AWS_URL2}/deleteCoRel/${corelId}`, null, {
          headers: { Authorization: token },
        })
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `Selected connection is deleted successfully`,
            life: 3000,
          });
          updateCorel();
          onHide();
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
          onHide();
        });
    };
    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Unassign"
        footer={
          <div>
            <Button
              label="Confirm"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDisconnection}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <IoIosInformationCircle className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete the connection ?
          </span>
        </div>
      </Dialog>
    );
  };
  return (
    <>
      {/* <TabView className="mt-4 rounded dark:bg-navy-800">
        <TabPanel
          header="Vehicle & Device Connections"
          className="rounded text-sm"
        > */}
      <DataTable
        value={dataDTV}
        removableSort
        paginator
        id="dtv_table"
        header={header}
        dataKey="serialNo"
        rows={20}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "vehicle_name",
          "vehicle_reg_number",
          "device_id",
          "user",
        ]}
        emptyMessage="No connections found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        ></Column>
        <Column
          field="vehicle_name"
          header="Vehicle"
          className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          field="vehicle_reg_number"
          header="Registration No."
          className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          field="device_id"
          header="Device"
          className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
        ></Column>
        <Column
          header="Device Type"
          sortField="device_type_id"
          sortable
          className="border-b text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
          body={(rowData) => {
            const deviceTypeId = rowData.device_type_id;
            const matchedDevice = Array.isArray(devicesOptions())
              ? devicesOptions().find((type) => type.value === deviceTypeId)
              : null;
            return (
              <Tag
                className="mr-2 bg-gray-200 text-gray-800"
                icon="pi pi-tag"
                style={{
                  width: "fit-content",
                  height: "25px",
                }}
                value={matchedDevice ? matchedDevice.label : "Unknown"}
              />
            );
          }}
        ></Column>
        <Column
          field="user"
          header="User"
          sortable
          className="border-b text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "10rem", padding: "5px" }}
        ></Column>
        <Column
          key="action"
          header="Unlink"
          className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
          style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
          body={actionBodyTemplate}
        ></Column>
      </DataTable>
      {/* </TabPanel>
        <TabPanel
          header="Vehicle & User Connections"
          className="rounded text-sm"
        >
          <DataTable
            value={dataUTV}
            removableSort
            paginator
            id="dtv_table"
            header={header}
            dataKey="serialNo"
            rows={20}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[20, 35, 50]}
            filters={filters}
            filterDisplay="menu"
            globalFilterFields={[
              "vehicle_name",
              "vehicle_type",
              "vehicle_reg_number",
              "user",
            ]}
            emptyMessage="No connections found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            <Column
              field="serialNo"
              header="#"
              className="border-b pl-4 text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{
                minWidth: "4rem",
                padding: "5px 0px 5px 2rem",
              }}
              bodyStyle={{
                textAlign: "left",
                overflow: "visible",
                padding: "0px 0px 0px 2rem",
              }}
            ></Column>
            <Column
              field="vehicle_name"
              header="Vehicle"
              className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "8rem", textAlign: "left", padding: "5px" }}
            ></Column>
            <Column
              header="Type"
              sortField="vehicle_type"
              sortable
              className="border-b text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "7rem", padding: "5px" }}
              body={(rowData) => {
                const vehicleTypeId = rowData.vehicle_type;
                const matchedVehicle = Array.isArray(vehicletypes)
                  ? vehicletypes.find(
                      (type) => type.vehicleTypeId === vehicleTypeId
                    )
                  : null;
                return (
                  <p
                    className="mr-2 rounded-lg border-[1px] border-gray-200 bg-none px-2 text-gray-800"
                    style={{
                      width: "fit-content",
                      height: "25px",
                    }}
                  >
                    {matchedVehicle
                      ? matchedVehicle.vehicleTypeName
                      : "Unknown"}
                  </p>
                );
              }}
            ></Column>
            <Column
              field="vehicle_reg_number"
              header="Reg No."
              className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
            ></Column>
            <Column
              header="Devices"
              className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "10rem", textAlign: "left", padding: "5px" }}
              body={(rowData) => {
                if (
                  rowData.device_linked &&
                  Array.isArray(rowData.device_linked) &&
                  rowData.device_linked.length > 0
                ) {
                  const deviceIds = rowData.device_linked.map(
                    (device) => device.device_id
                  );
                  return (
                    <div>
                      {deviceIds.map((deviceId, index) => (
                        <Tag
                          key={index}
                          className="my-1 mr-2 bg-gray-200 text-gray-800"
                          icon="pi pi-tag"
                          style={{
                            width: "fit-content",
                            height: "25px",
                          }}
                          value={deviceId}
                        />
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Tag
                        className="mr-2 bg-gray-200 text-gray-800"
                        icon="pi pi-tag"
                        style={{
                          width: "fit-content",
                          height: "25px",
                        }}
                        value="No devices found"
                      />
                    </div>
                  );
                }
              }}
            />
            <Column
              field="user"
              header="User"
              sortable
              className="border-b text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "10rem", padding: "5px" }}
            ></Column>
            <Column
              key="action"
              header="Unlink"
              className="border-b py-1 text-sm dark:bg-navy-700 dark:text-gray-200"
              style={{ minWidth: "4rem", textAlign: "left", padding: "5px" }}
              body={actionBodyTemplate}
            ></Column>
          </DataTable>
        </TabPanel>
      </TabView> */}
      {/* List View  */}

      <DisconnectDialog
        visible={disconnectDialogVisible}
        onHide={() => setDisconnectDialogVisible(false)}
      />
    </>
  );
};

export default CoRelationsList;
