import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import GeofenceList from "./components/GeofenceList";
import Cookies from "js-cookie";
import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";

const Geofence = () => {
  const token = Cookies.get("token");
  const [geoData, setGeoData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getGeofenceData`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const formattedData = res?.data?.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
        }));
        setLoading(false);
        setGeoData(formattedData);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className="text-dark pt-3 text-xl font-bold dark:text-white">
        Geofence
      </h4>
      <div>
        <Link to="/geofence/add-geofence">
          <button className="mt-0.5 flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600">
            <FiPlus />
            &nbsp; New Geofence
          </button>
        </Link>
      </div>
      {loading ? (
        <PreloaderList />
      ) : (
        <GeofenceList data={geoData} onAddGeofence={fetchData} />
      )}
    </>
  );
};

export default Geofence;
