import axios from "axios";
import Card from "components/card";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const Cards = () => {
  const [data, setData] = useState([]);
  const token = Cookies.get("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/dashrootdata`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const organizationsCount = (data?.Organizations ?? 0) - 1;

  return (
    <>
      <div className="mt-4 grid grid-cols-4 gap-6 2xl:grid-cols-4">
        <Card orgName="Total trips" count={data?.totalTrips} path="/trips" />
        <Card
          orgName="Completed trips"
          count={data?.completedTrips}
          path="/trips"
        />
        <Card
          orgName="Ongoing trips"
          count={data?.onGoingTrips}
          path="/trips"
        />
        <Card
          orgName="Total devices"
          count={data?.totalDevices}
          path="/devices"
        />
        <Card orgName="DMS" count={data?.DMS} path="/devices" />
        <Card orgName="ECU" count={data?.ECU} path="/devices" />
        <Card orgName="IoT" count={data?.IoT} path="/devices" />
        <Card
          orgName="Alcohol Detection System"
          count={data?.ALCOHOL}
          path="/devices"
        />
        <Card
          orgName="Organizations"
          count={organizationsCount}
          path="/organization"
        />
        <Card orgName="Simcards" count={data?.Simcards} path="/sim-cards" />
        <Card orgName="Vehicles" count={data?.Vehicles} path="/vehicles" />
        <Card
          orgName="Featuresets"
          count={data?.Featuresets}
          path="/feature-set"
        />
      </div>
    </>
  );
};

export default Cards;
