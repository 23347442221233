import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { AppContext } from "context/AppContext";
import Cookies from "js-cookie";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";

const EditFeatureset = ({
  parameters,
  toastRef,
  closeDialog1,
  vehicleTypes,
}) => {
  const [data, setData] = useState({});
  const [fields, setFields] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [details, setDetails] = useState({});
  const token = Cookies.get("token");
  const { updateFS } = useContext(AppContext);

  const vehicleTypeOptions = () => {
    return vehicleTypes.map((item) => ({
      label: item.vehicleTypeName,
      value: item.vehicleTypeId,
    }));
  };

  const statusOptions = () => {
    const status = [
      { label: "Active", value: 1 },
      { label: "Deactive", value: 2 },
    ];
    return status;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (type === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  //function to restructure data
  function restructureData(data) {
    const restructuredData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const [mainKey, subKey] = key.split("--");
        const value = parseFloat(data[key]);

        if (subKey) {
          restructuredData[subKey] = restructuredData[subKey] || {};
          restructuredData[subKey][mainKey] = value;
        }
      }
    }

    return restructuredData;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let structuredData = await restructureData(data);

      let insertData = {
        featureset_name: details.featureset_name,
        featureset_version: details.featureset_version,
        featureset_type: details.featureset_type,
        featureset_status: details.featureset_status,
        featureset_data: JSON.stringify(structuredData),
      };

      axios
        .put(
          `${process.env.REACT_APP_AWS_URL2}/editFeatureset/${parameters.propValue.featureset_uuid}`,
          insertData,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          updateFS();

          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: `Featureset ${insertData.featureset_name} updated successfully`,
            life: 3000,
          });
          closeDialog1();
        })
        .catch((err) => {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.error,
            life: 3000,
          });
        });
    } catch (error) {
      console.error("Error submitting form data::::::", error);
    }
  };

  let convertData = (originalData) => {
    let convertedData = {};

    for (let category in originalData) {
      for (let attribute in originalData[category]) {
        let key = `${attribute}--${category}`;
        let value = originalData[category][attribute].toString();
        convertedData[key] = value;
      }
    }

    return convertedData;
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/${parameters.propValue.featureset_option_for}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setFields(res.data.data[0].optionsdata);
        setLoaded(true);
      })
      .catch((err) => console.log("err", err));

    //get the data of featureset from backend

    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getFeaturesetById/${parameters.propValue.featureset_uuid}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(async (res) => {
        let x = JSON.parse(res.data.data[0].featureset_data);
        let orderedData = await convertData(x);

        setData(orderedData);
        setDetails({
          featureset_name: res.data.data[0].featureset_name,
          featureset_version: res.data.data[0].featureset_version,
          featureset_type: res.data.data[0].featureset_type,
          featureset_status: res.data.data[0].featureset_status,
        });
      })
      .catch((err) => {
        console.log("err::", err);
      });
  }, [token, parameters]);

  if (!loaded) {
    return <PreloaderList />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="rounded-lg bg-gray-200 py-6 dark:bg-gray-900">
          <div className="flex justify-between gap-3 rounded px-5 py-2">
            <div className="flex-1">
              <span className="p-float-label">
                <InputText
                  name="featureset_name"
                  className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                  onChange={handleDetails}
                  value={details?.featureset_name}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  Featureset Name
                </label>
              </span>
            </div>
            <div className="flex-1">
              <span className="p-float-label">
                <InputText
                  name="featureset_version"
                  className="border py-1.5 pl-2 text-sm"
                  onChange={handleDetails}
                  value={details?.featureset_version}
                />
                <label htmlFor="device_id" className="dark:text-gray-300">
                  Featureset Version
                </label>
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-between gap-3 rounded px-5 py-2">
            <div className="flex-1">
              <span className="p-float-label">
                <Dropdown
                  id="featureset_type"
                  options={vehicleTypeOptions()}
                  optionLabel="label"
                  optionValue="value"
                  className={`p-dropdown h-9 border text-sm `}
                  name="featureset_type"
                  onChange={handleDetails}
                  value={details?.featureset_type}
                />
                <label htmlFor="featureset_type" className="dark:text-gray-300">
                  Featureset Type
                </label>
              </span>
            </div>
            <div className="flex-1">
              <span className="p-float-label">
                <Dropdown
                  id="featureset_status"
                  name="featureset_status"
                  options={statusOptions()}
                  optionLabel="label"
                  optionValue="value"
                  className={`p-dropdown h-9 border text-sm `}
                  onChange={handleDetails}
                  value={details.featureset_status}
                />
                <label
                  htmlFor="featureset_status"
                  className="dark:text-gray-300"
                >
                  Status
                </label>
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {Object.entries(
            fields.reduce((acc, options) => {
              let dropdownOptions = [];
              try {
                if (typeof options.featureset_option_dropmenu === "string") {
                  dropdownOptions = JSON.parse(
                    options.featureset_option_dropmenu
                  );
                  if (!Array.isArray(dropdownOptions)) {
                    throw new Error("Dropdown options is not an array");
                  }
                } else if (Array.isArray(options.featureset_option_dropmenu)) {
                  dropdownOptions = options.featureset_option_dropmenu;
                } else {
                  throw new Error("Dropdown options is not a string or array");
                }
              } catch (error) {
                dropdownOptions = [];
              }

              const groupIndex = options.featureset_option_index;
              if (!acc[groupIndex]) {
                acc[groupIndex] = [];
              }

              acc[groupIndex].push(
                <div key={options.featureset_option_id}>
                  {options.featureset_option_input_type === "dropdown" ? (
                    <div className="mt-8">
                      <span className="p-float-label">
                        <Dropdown
                          id={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          value={
                            data[
                              `${options.featureset_option_name}--${options.featureset_group_by}`
                            ]
                          }
                          options={dropdownOptions}
                          className="h-9 w-full border dark:bg-gray-900"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: `${options.featureset_option_name}--${options.featureset_group_by}`,
                                value: e.value,
                              },
                            })
                          }
                          optionLabel="label"
                        />
                        <label
                          htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          className="dark:text-gray-300"
                        >
                          {options.featureset_option_label}
                        </label>
                      </span>
                    </div>
                  ) : options.featureset_option_input_type === "radio" ? (
                    <div
                      key={options.featureset_option_id}
                      className="mt-2 w-full"
                    >
                      <label className="text-base text-gray-700  dark:text-gray-300">
                        {options.featureset_option_label}
                      </label>
                      <div className="mt-1">
                        <input
                          type="radio"
                          id={`${options.featureset_option_name}-enable`}
                          name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          value="1"
                          checked={
                            data[
                              `${options.featureset_option_name}--${options.featureset_group_by}`
                            ] === "1"
                          }
                          onChange={handleChange}
                          style={{ marginRight: "5px" }}
                        />
                        <label
                          htmlFor={`${options.featureset_option_name}-enable`}
                          className="text-sm dark:text-gray-300"
                        >
                          Enable
                        </label>
                        <input
                          type="radio"
                          id={`${options.featureset_option_name}-disable`}
                          name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          value="0"
                          checked={
                            data[
                              `${options.featureset_option_name}--${options.featureset_group_by}`
                            ] === "0"
                          }
                          onChange={handleChange}
                          style={{ marginLeft: "15px", marginRight: "5px" }}
                        />
                        <label
                          htmlFor={`${options.featureset_option_name}-disable`}
                          className="text-sm dark:text-gray-300"
                        >
                          Disable
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={options.featureset_option_id}
                      className=" w-[1/3]"
                    >
                      <div className="mt-8">
                        <span className="p-float-label">
                          <InputText
                            type={options.featureset_option_input_type}
                            name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                            value={
                              data[
                                `${options.featureset_option_name}--${options.featureset_group_by}`
                              ]
                            }
                            onChange={handleChange}
                            className="h-9 w-full border pl-2 text-sm dark:bg-gray-900 dark:text-gray-300"
                          />
                          <label
                            htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                            className="dark:text-gray-300"
                          >
                            {options.featureset_option_label}
                          </label>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );

              return acc;
            }, {})
          ).map(([groupIndex, elements]) => (
            <div key={groupIndex}>
              <div className="grid grid-cols-3 gap-4 rounded border-[1px] bg-[#E9ECEF] p-3 dark:border-none dark:bg-navy-900 2xl:grid-cols-3">
                {elements}
              </div>
              <br />
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end px-5">
          <button
            type="submit"
            className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditFeatureset;
