import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { Tag } from "primereact/tag";
import { MdDeleteOutline } from "react-icons/md";

const ReportList = ({ data, updateData }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const token = Cookies.get("token");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const toastRef = useRef(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="mb-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const openDeleteDialog = (rowData) => {
    setSelectedReport(rowData);
    setDeleteDialogVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => openDeleteDialog(rowData)}
          className="mx-2 cursor-pointer text-red-600"
        />
        {rowData.reports_type === "1" && (
          <FaRegEye
            title="View"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_BASE_URL}/report/${rowData.report_uuid}`
              )
            }
            className="cursor-pointer text-gray-700"
          />
        )}
      </div>
    );
  };

  const DeleteDeviceDialog = ({ visible, onHide, report_uuid }) => {
    const handleConfirmDelete = async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_AWS_URL2}/deleteReports/${report_uuid}`,
          {},
          {
            headers: { Authorization: token }, // Make sure the authorization header is correctly formatted
          }
        );

        if (res.status === 200) {
          // Report deleted successfully
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: `Report ${selectedReport.title} deleted successfully!`,
            life: 3000,
          });
          updateData(
            data.filter(
              (report) => report.report_uuid !== selectedReport.report_uuid
            )
          );
          onHide(); // Close the dialog or take any other required action
        } else {
          // Handle other response status codes if needed
          console.error("Delete failed with status:", res.status);
        }
      } catch (err) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete report. Please try again.",
          life: 3000,
        });
      }
    };

    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleConfirmDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={onHide}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {selectedReport?.title}?
          </span>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <Toast ref={toastRef} />
      <DataTable
        removableSort
        value={data}
        dataKey="report_uuid"
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        filterDisplay="menu"
        filters={filters}
        globalFilterFields={[
          "title",
          "reports_schedule_type",
          "reports_type",
          "report_created_at",
        ]}
        emptyMessage="No reports found."
        header={header}
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            padding: "5px 0px 5px 2rem",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            padding: "0px 0px 0px 2rem",
          }}
        ></Column>
        {/* <Column
          field="r_id"
          header="Report ID"
          sortable
          className="border-b dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "10rem", border: "none !important" }}
        ></Column> */}

        <Column
          field="title"
          header="Report Title"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="report_created_at"
          header="Generate Date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
          body={(rowData) => {
            if (rowData.report_created_at) {
              const date = new Date(rowData.report_created_at);
              const formattedDate = `${String(date.getDate()).padStart(
                2,
                "0"
              )}-${String(date.getMonth() + 1).padStart(
                2,
                "0"
              )}-${date.getFullYear()}`;
              const formattedTime = `${String(date.getHours()).padStart(
                2,
                "0"
              )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
                date.getSeconds()
              ).padStart(2, "0")}`;
              return (
                <Tag
                  className="mr-2 bg-gray-200 text-gray-800"
                  icon="pi pi-calendar"
                  style={{
                    width: "fit-content",
                    height: "25px",
                  }}
                  value={formattedDate + " " + formattedTime}
                />
              );
            } else {
              return "--";
            }
          }}
        ></Column>
        <Column
          field="from_date"
          header="From date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          body={(rowData) => {
            if (rowData.from_date) {
              const date = new Date(rowData.from_date);
              const formattedDate = `${String(date.getDate()).padStart(
                2,
                "0"
              )}-${String(date.getMonth() + 1).padStart(
                2,
                "0"
              )}-${date.getFullYear()}`;
              return formattedDate;
            } else {
              return "--";
            }
          }}
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="to_date"
          header="To date"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          body={(rowData) => {
            if (rowData.to_date) {
              const date = new Date(rowData.to_date);
              const formattedDate = `${String(date.getDate()).padStart(
                2,
                "0"
              )}-${String(date.getMonth() + 1).padStart(
                2,
                "0"
              )}-${date.getFullYear()}`;
              return formattedDate;
            } else {
              return "--";
            }
          }}
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          field="reports_type"
          header="Report Type"
          body={(rowData) =>
            rowData.reports_type === "1" ? "Generated" : "Scheduled"
          }
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "12rem", padding: "5px" }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "9rem", padding: "5px" }}
        ></Column>
      </DataTable>
      <DeleteDeviceDialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        report_uuid={selectedReport ? selectedReport.report_uuid : null}
      />
    </>
  );
};

export default ReportList;
