import React, { useEffect, useState } from "react";
import axios from "axios";
import gif from "../../assets/img/verified.gif";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  // Get the token from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      axios
        .post(`${process.env.REACT_APP_AWS_URL1}/verifyToken`, {
          token,
        })
        .then((response) => {
          alert(response.data.message);
          setRedirect(response.data.data.redirect);
          Cookies.set("RedirectToken", token);
          Cookies.set("email_verified", 1);
          if (redirect) {
            navigate("/reset-password");
          } else {
            navigate("/signin");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Redirect after 10 seconds
    // const redirectTimeout = setTimeout(() => {
    //   if(redirect){window.location.href = `${process.env.REACT_APP_BASE_URL}/reset-password`}
    //   else{
    //     window.location.href = `${process.env.REACT_APP_BASE_URL}/signin`
    //   } // Replace with your desired URL
    // }, 2000);

    // Clear the timeout on component unmount to avoid memory leaks
    // return () => clearTimeout(redirectTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {redirect ? (
        <div className={`flex h-screen flex-col items-center justify-center`}>
          <div class="dots"></div>
          <br />
          <p>Redirecting to reset password page</p>
        </div>
      ) : (
        <div className={`flex h-screen items-center justify-center`}>
          <p className={`flex align-middle text-lg`}>
            <img src={gif} alt="" className="rounded-full bg-gray-600 p-2" />
            <span>&nbsp;Your email ID is verified successfully!</span>
          </p>
        </div>
      )}
    </>
  );
};

export default Verify;
