import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <>
      <Link to ={props.path}>
        <div className="cursor-pointer rounded-xl border-b-4 border-l-2 border-solid border-gray-700 bg-gray-50 px-8 py-4 shadow-inner hover:shadow-md dark:bg-gray-900   dark:hover:shadow-md">
          <p className="text-lg font-semibold text-gray-850 dark:text-gray-100">
            {props.orgName}
          </p>
          <div>
            <p className="w-fit rounded-e-full bg-gray-200 px-4 py-1 text-sm">
              {props.count}
            </p>
            <p></p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Card;
