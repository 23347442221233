import React, { useCallback, useContext, useEffect, useState } from "react";
import ReportsList from "./components/ReportsList";
import { Dialog } from "primereact/dialog";
import Schedule from "./components/Schedule";
import Generate from "./components/Generate";
import { FaCalendarPlus, FaChartLine, FaFileContract } from "react-icons/fa";
import Cookies from "js-cookie";
import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import { AppContext } from "context/AppContext";
import Quick from "./components/Quick";

const Reports = () => {
  const [isGenerateDialogVisible, setIsGenerateDialogVisible] = useState(false);
  const [isScheduleDialogVisible, setIsScheduleDialogVisible] = useState(false);
  const [isQuickDialogVisible, setIsQuickDialogVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const token = Cookies.get("token");
  const { reports } = useContext(AppContext);
  const fetchData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getReportsAll`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const formattedData = res.data.data.map((item, index) => ({
          ...item,
          serialNo: index + 1,
        }));
        setLoaded(true);
        setData(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, reports]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openDialog1 = () => {
    setIsGenerateDialogVisible(true);
  };

  const closeDialog1 = () => {
    setIsGenerateDialogVisible(false);
  };

  const openDialog2 = () => {
    setIsScheduleDialogVisible(true);
  };

  const closeDialog2 = () => {
    setIsScheduleDialogVisible(false);
  };

  const openDialog3 = () => {
    setIsQuickDialogVisible(true);
  };

  const closeDialog3 = () => {
    setIsQuickDialogVisible(false);
  };

  return (
    <div>
      <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
        Reports
      </h4>
      <div className="flex gap-2">
        <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog1}
        >
          <FaChartLine />
          &nbsp;Generate
        </button>
        <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog2}
        >
          <FaCalendarPlus className="mr-2 inline-block text-xl" />
          Schedule
        </button>
        <button
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
          onClick={openDialog3}
        >
          <FaFileContract className="mr-2 inline-block text-xl" />
          Quick
        </button>
      </div>
      {loaded ? (
        <ReportsList data={data} />
      ) : (
        <div className="mt-6">
          <PreloaderList />
        </div>
      )}
      <Dialog
        visible={isGenerateDialogVisible}
        onHide={closeDialog1}
        style={{ width: "45%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Generate close={closeDialog1} />
      </Dialog>
      <Dialog
        visible={isScheduleDialogVisible}
        onHide={closeDialog2}
        style={{ width: "40rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Schedule close={closeDialog2} />
      </Dialog>
      <Dialog
        visible={isQuickDialogVisible}
        onHide={closeDialog3}
        style={{ width: "40rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <Quick close={closeDialog3} />
      </Dialog>
    </div>
  );
};

export default Reports;
