import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useContext, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Tag } from "primereact/tag";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { AppContext } from "context/AppContext";
import { MdDeleteOutline } from "react-icons/md";

const InactivityTriggerList = ({ inacData }) => {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editVisible, setEditVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const toastRef = useRef(null);
  const token = Cookies.get("token");
  const { updateTrigger } = useContext(AppContext);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="my-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const openEditDialog = (rowData) => {
    setEditData(rowData);
    setEditVisible(true);
  };

  const closeEditDialog = () => {
    setEditVisible(false);
  };

  const renderStatusCellInac = (rowData) => {
    const tagValue = rowData?.inactive_status === 1 ? "Active" : "Deactive";
    const tagSeverity = rowData?.inactive_status === 1 ? "success" : "danger";

    return <Tag value={tagValue} severity={tagSeverity} />;
  };

  const openDeleteDialog = (rowData) => {
    setDeleteVisible(true);
    setDeleteId(rowData.inactiveDeviceID);
  };

  const closeDeleteDialog = () => {
    setDeleteVisible(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex text-lg">
        <FaRegEdit
          title="Edit"
          onClick={() => openEditDialog(rowData)}
          className="mr-2 cursor-pointer text-gray-700"
        />
        <RiDeleteBin6Line
          title="Delete"
          onClick={() => {
            rowData.inactive_status === 2
              ? openDeleteDialog(rowData)
              : console.log();
          }}
          className={`mx-2 cursor-pointer ${
            rowData.inactive_status === 2 ? "text-red-600" : "text-gray-500"
          }`}
        />
      </div>
    );
  };

  const handleChange = (name, value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editData) {
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL1}/editInactiveDevices/${editData.inactiveDeviceID}`,
          editData,
          {
            headers: { Authorization: token },
          }
        )
        .then((res) => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 3000,
          });
          closeEditDialog();
          updateTrigger();
        })
        .catch((err) => {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail:
              `Trigger ${editData.trigger_name} failed to edit` ||
              err.response?.data.message,
            life: 3000,
          });
        });
    }
  };

  const handleDelete = () => {
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/deleteInactiveDevices/${deleteId}`,
        {},
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail:
            `Inactivity trigger deleted successfully!` || res.data.message,
          life: 3000,
        });
        setDeleteVisible(false);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail:
            `Inactivity trigger failed to delete` || err.response?.data.message,
          life: 3000,
        });
        setDeleteVisible(false);
      });
  };

  const timeOptions = [
    {
      label: "6 hours",
      value: 6,
    },
    {
      label: "12 hours",
      value: 12,
    },
    {
      label: "24 hours",
      value: 24,
    },
    {
      label: "48 hours",
      value: 48,
    },
    {
      label: "72 hours",
      value: 72,
    },
  ];

  const stateOptions = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  return (
    <>
      <Toast ref={toastRef} position="top-right" />
      <DataTable
        value={inacData}
        paginator
        dataKey="inactiveDeviceID"
        header={header}
        rows={20}
        removableSort
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[20, 35, 50]}
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={[
          "vehicle_name",
          "inactive_time",
          "whatsapp_number",
        ]}
        emptyMessage="No alert triggers found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <Column
          field="serialNo"
          header="#"
          className="border-b pl-5 text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{
            minWidth: "4rem",
            paddingTop: "5px",
            paddingLeft: "2rem",
            paddingBottom: "5px",
          }}
          bodyStyle={{
            textAlign: "left",
            overflow: "visible",
            paddingTop: "0px",
            paddingLeft: "2rem",
            paddingBottom: "0px",
          }}
        ></Column>
        <Column
          field="vehicle_name"
          header="Vehicle Name"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
        <Column
          field="inactive_time"
          header="Interval"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          body={(rowData) => {
            return (
              <>
                <Tag
                  className="my-1 bg-gray-200 py-0 font-semibold text-gray-800"
                  icon="pi pi-stopwatch"
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <span style={{ fontSize: "12px" }}>
                    {rowData.inactive_time} hours
                  </span>
                </Tag>
              </>
            );
          }}
        />
        <Column
          header="Status"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          body={renderStatusCellInac}
        ></Column>
        <Column
          field="whatsapp_number"
          header="WhatsApp Number"
          sortable
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        />
        <Column
          body={actionBodyTemplate}
          header="Action"
          className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
          style={{ minWidth: "8rem", padding: "5px" }}
          bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
        ></Column>
      </DataTable>
      <Dialog
        visible={editVisible}
        onHide={closeEditDialog}
        style={{
          width: "30%",
          height: "fit-content",
        }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit}>
          <div className="mt-8 grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <span className={"p-float-label"}>
              <InputText
                id="whatsapp_number"
                name="whatsapp_number"
                onChange={(e) => {
                  handleChange("whatsapp_number", e.target.value);
                }}
                className="border py-1.5 pl-2 text-sm"
                value={editData?.whatsapp_number}
                // className={`border py-1.5 pl-2 text-sm ${
                //   formErrors.whatsapp_number ? "border-red-600" : ""
                // }`}
              />
              <label htmlFor="whatsapp_number" className="dark:text-gray-300">
                Whatsapp Number
              </label>
            </span>
            <span className={"p-float-label"}>
              <Dropdown
                id="time"
                name="time"
                optionLabel="label"
                optionValue="value"
                options={timeOptions}
                onChange={(e) => {
                  handleChange("inactive_time", e.target.value);
                }}
                className={`h-8 border`}
                value={editData?.inactive_time}
              />
              <label htmlFor="contact_id" className="dark:text-gray-300">
                Time Interval
              </label>
            </span>
            <span className={"p-float-label mt-8"}>
              <Dropdown
                id="inactive_status"
                name="inactive_status"
                optionLabel="label"
                optionValue="value"
                options={stateOptions}
                onChange={(e) => {
                  handleChange("inactive_status", e.target.value);
                }}
                value={editData?.inactive_status}
                className="h-8 border"
              />
              <label htmlFor="inactive_status" className="dark:text-gray-300">
                Select Status
              </label>
            </span>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={deleteVisible}
        onHide={closeDeleteDialog}
        header="Confirm Deletion"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleDelete}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={closeDeleteDialog}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          <span className="text-sm font-semibold">
            Are you sure you want to delete {}?
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default InactivityTriggerList;
