import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import AddALCOHOLFS from "./components/AddALCOHOLFS";
import AddDMSFS from "./components/AddDMSFS";
import AddFeatureSet from "./components/AddFeatureSet";
import axios from "axios";
import Cookies from "js-cookie";
import FeatureList from "./components/FeatureList";
import { FiPlus } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import AddFeatureSetRoot from "./components/AddFeatureSetRoot";
import AddDMSFSRoot from "./components/AddDMSFSRoot";
import AddALCOHOLFSRoot from "./components/AddALCOHOLFSRoot";
import { Toast } from "primereact/toast";
import { MdFileUpload } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";

const FeatureSet = () => {
  const [dms, setDMS] = useState([]);
  const [cas, setCAS] = useState([]);
  const [alc, setALC] = useState([]);
  const [featureset, setFeatureset] = useState([]);
  const [org, setOrg] = useState([]);
  const [publishData, setPublishData] = useState([]);
  const token = Cookies.get("token");
  const user_type = Cookies.get("user_type");
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isPublishDialogVisible, setIsPublishDialogVisible] = useState(false);
  const toastRef = useRef();

  const openDialog = () => {
    setIsDialogVisible(true);
  };

  const closeDialog = () => {
    setIsDialogVisible(false);
  };

  const openPublishDialog = () => {
    setIsPublishDialogVisible(true);
  };

  const closePublishDialog = () => {
    setIsPublishDialogVisible(false);
    setPublishData([]);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getAllDevices`,

        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        const devices = res.data.data[0].deviceData;
        const filteredCASDevices = devices.filter(
          (device) => device.device_type_id === 1
        );
        setCAS(filteredCASDevices);
        const filteredDMSDevices = devices.filter(
          (device) => device.device_type_id === 3
        );
        setDMS(filteredDMSDevices);
        const filteredAlcDevices = devices.filter(
          (device) => device.device_type_id === 4
        );
        setALC(filteredAlcDevices);
      })

      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getFeatureset`,

        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setFeatureset(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(`${process.env.REACT_APP_AWS_URL1}/organizationDetailsGet`, null, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setOrg(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fsOptions = () => {
    return featureset?.map((item, index) => ({
      key: index,
      label: item.featureset_name,
      value: item.featureset_data,
    }));
  };

  const deviceTypes = [
    { label: "ECU", value: 1 },
    { label: "IOT", value: 2 },
    { label: "DMS", value: 3 },
    { label: "DMS Alcohol", value: 4 },
  ];

  const orgOptions = () => {
    return org?.map((el) => ({
      label: el.orgName,
      value: el.orgId,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPublishData({ ...publishData, [name]: value });
  };

  const handlePublish = (e) => {
    e.preventDefault();
    if (
      publishData.featureset_data &&
      publishData.device_type &&
      publishData.org_id
    ) {
      axios
        .post(
          `${process.env.REACT_APP_AWS_URL2}/rootPublishFS`,
          { ...publishData },
          {
            headers: { Authorization: token },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail:
                "Featureset published on the selected device type for selected organizations !",
              life: 3000,
            });
            closePublishDialog();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="flex justify-between">
        <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
          Feature Set
        </h4>
      </div>
      {user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e" ? (
        <>
          <div className="flex gap-2">
            <button
              className="flex h-7 items-center rounded-lg bg-blue-500 px-2 text-left text-sm font-normal text-white hover:bg-blue-600"
              onClick={openDialog}
            >
              <FiPlus />
              &nbsp;New Featureset
            </button>
            <button
              className="flex h-7 items-center rounded-lg bg-blue-500 px-2 text-left text-sm font-normal text-white hover:bg-blue-600"
              onClick={openPublishDialog}
            >
              <MdFileUpload />
              &nbsp;Publish Featureset
            </button>
          </div>

          <Dialog
            visible={isDialogVisible}
            onHide={closeDialog}
            style={{ width: "60%", height: "fit-content" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Fill the details"
            modal
            className="p-fluid dark:bg-gray-900"
          >
            <TabView>
              <TabPanel header="Collision Avoidance" className="text-sm">
                <AddFeatureSetRoot
                  closeDialog={closeDialog}
                  toastRef={toastRef}
                />
              </TabPanel>
              <TabPanel header="Alcohol detection" className="text-sm">
                <AddALCOHOLFSRoot
                  closeDialog={closeDialog}
                  toastRef={toastRef}
                />
              </TabPanel>
              <TabPanel header="Driver Monitoring" className="text-sm">
                <AddDMSFSRoot closeDialog={closeDialog} toastRef={toastRef} />
              </TabPanel>
            </TabView>
          </Dialog>
          <Dialog
            visible={isPublishDialogVisible}
            onHide={closePublishDialog}
            style={{ width: "40%", height: "fit-content" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Fill the details"
            modal
            className="p-fluid dark:bg-gray-900"
          >
            <form onSubmit={handlePublish}>
              <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                <div>
                  <span className={"p-float-label mt-6"}>
                    <Dropdown
                      id="featureset_data"
                      name="featureset_data"
                      optionLabel="label"
                      optionValue="value"
                      options={fsOptions()}
                      onChange={handleChange}
                      className={`h-8 border`}
                      value={publishData.featureset_data}
                    />
                    <label
                      htmlFor="featureset_data"
                      className="dark:text-gray-300"
                    >
                      Select Featureset
                    </label>
                  </span>
                </div>
                <div>
                  <span className={"p-float-label mt-6"}>
                    <Dropdown
                      id="device_type"
                      name="device_type"
                      optionLabel="label"
                      optionValue="value"
                      options={deviceTypes}
                      onChange={handleChange}
                      className={`h-8 border`}
                      value={publishData.device_type}
                    />
                    <label htmlFor="device_type" className="dark:text-gray-300">
                      Select Device Type
                    </label>
                  </span>
                </div>
                <div>
                  <span className={"p-float-label mt-6"}>
                    <Dropdown
                      id="org_id"
                      name="org_id"
                      optionLabel="label"
                      optionValue="value"
                      options={orgOptions()}
                      onChange={handleChange}
                      className={`h-8 border`}
                      value={publishData.org_id}
                    />
                    <label htmlFor="org_id" className="dark:text-gray-300">
                      Select Organization
                    </label>
                  </span>
                </div>
              </div>
              <div className="mt-6 flex justify-end px-5">
                <button
                  type="submit"
                  className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
                >
                  Publish
                </button>
              </div>
            </form>
          </Dialog>
          <FeatureList />
        </>
      ) : (
        <TabView className="mt-4 rounded-[20px] bg-gray-300 dark:bg-navy-800">
          <TabPanel header="Collision Avoidance" className="text-sm">
            <AddFeatureSet cas={cas} toastRef={toastRef} />
          </TabPanel>
          <TabPanel header="Alcohol Detection" className="text-sm">
            <AddALCOHOLFS alc={alc} toastRef={toastRef} />
          </TabPanel>
          <TabPanel header="Driver Monitoring" className="text-sm">
            <AddDMSFS dms={dms} toastRef={toastRef} />
          </TabPanel>
        </TabView>
      )}

      {/* </Dialog> */}

      {/* <FeatureList /> */}
    </>
  );
};

export default FeatureSet;
