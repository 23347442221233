/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import logo from "../../../../assets/img/logo.png";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
// import ReactApexChart from "react-apexcharts";
import { useReactToPrint } from "react-to-print";
import { BiLogoSlackOld } from "react-icons/bi";
import ColumnChart from "./ColumnChart";

const Report = () => {
  const token = Cookies.get("token");
  const { report_uuid } = useParams();
  const [date, setDate] = useState();
  const [title, setTitle] = useState();
  const [fromdate, setFromDate] = useState();
  const [todate, setToDate] = useState();
  // const [vehicles, setVehicles] = useState({});
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const tableRef = useRef(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getReport/${report_uuid}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        const parsedData = JSON.parse(res.data.data[0]?.report_chart_data);
        setChartData(parsedData || {});
        setFromDate(res.data.data[0].from_date);
        setToDate(res.data.data[0].to_date);
        setTitle(res.data.data[0].title);
        setDate(res.data.data[0].report_created_at);
        // setVehicles(JSON.parse(res.data.data[0].reports_data) || {});
        setTableData(JSON.parse(res.data.data[0].reports_data) || {});

        setEvents(res.data.data[0].events_type);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, report_uuid]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;

    return { formattedDate, formattedTime };
  };

  const downloadPDF = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: title + "@" + formatTimestamp(date).formattedDate,
  });

  const convertEpochTime = (timestamp) => {
    const tripEndTime = new Date(timestamp * 1000);
    return tripEndTime.toLocaleString();
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  const [chartDataa, setChartDataa] = useState({}); // State to hold chart data

  useEffect(() => {
    const timeout = setTimeout(() => {
      const vehicles = Object.keys(chartData);
      const vehicleChartData = Object.values(chartData);

      let data = {};

      vehicles.map((item, index) => {
        const chartCategories = Object.keys(vehicleChartData[index]);
        const chartSeriesData = Object.values(vehicleChartData[index]);

        let chatJson = {
          [item]: {
            options: {
              chart: {
                id: "chart-" + index,
              },
              xaxis: {
                categories: chartCategories,
              },
            },
            series: [
              {
                name: "Total",
                data: chartSeriesData,
              },
            ],
          },
        };
        // data.push(chatJson);
        return (data = { ...data, ...chatJson });
      });
      // Set the chart data state
      setChartDataa(data);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [events]);

  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowChart(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div ref={tableRef}>
        <div className="flex justify-between px-4 py-2.5">
          <img src={logo} className="w-[177px]" alt="logo" />
          <div className="flex flex-col">
            <span>Date: {formatTimestamp(date).formattedDate}</span>
            <span>Time: {formatTimestamp(date).formattedTime}</span>
          </div>
        </div>
        <div className="card bg-white">
          <div className="text-center">
            <p
              className="pt-3 text-base font-medium"
              style={{ textShadow: "1px 1px 1px #ddd" }}
            >
              {title}
            </p>
            <i>
              <p className="py-1 text-sm font-normal">
                {formatTimestamp(fromdate).formattedDate} to{" "}
                {formatTimestamp(todate).formattedDate}
              </p>
            </i>
          </div>

          <div>
            <div className="bg-gray-500 px-4 py-2">
              <p
                className="text-base font-medium"
                style={{ textShadow: "1px 1px 1px #ddd" }}
              >
                Trip Event Statistics
              </p>
            </div>
            <div className="relative mt-10 flex-wrap  overflow-x-auto">
              <div className="flex flex-wrap justify-around">
                {showChart &&
                  Object.keys(chartDataa).map((key) => (
                    <div key={key}>
                      <p className="flex items-center">
                        <BiLogoSlackOld className="text-blue-600" />
                        &nbsp;&nbsp;{key}
                      </p>
                      <ColumnChart
                        options={chartDataa[key].options}
                        series={chartDataa[key].series}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="bg-gray-500 px-4 py-2">
            <p
              className="text-base font-medium"
              style={{ textShadow: "1px 1px 1px #ddd" }}
            >
              Trip Event Details
            </p>
          </div>
          {Object.keys(tableData).map((data, index) => (
            <div key={index} className="p-8">
              <p className="flex items-center">
                <BiLogoSlackOld className="text-blue-600" />
                &nbsp;&nbsp;{data}
              </p>
              <table
                className="mx-6 my-4 text-center text-sm font-light"
                style={{ width: "-webkit-fill-available" }}
              >
                <thead className="dark:border-neutral-500 dark:text-neutral-800 border border-b bg-gray-100 font-medium">
                  <tr>
                    <th scope="col" className="border px-2 py-1">
                      #
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Trip ID
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Device ID
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Distance
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Duration
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Total alerts
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      Date
                    </th>
                    <th scope="col" className="border px-2 py-1">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-50">
                  {Object.values(tableData[data].tripData).map(
                    (trip, index) => (
                      <tr
                        className="dark:border-neutral-500 border-b"
                        key={index}
                      >
                        <td className="whitespace-nowrap border px-2 py-1 font-medium">
                          {/* {JSON.stringify(tableData[data].tripData)} */}
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {" "}
                          {trip.trip_id}
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {trip.device_id}
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {trip.total_distance.toFixed(2)} KM
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {formatDuration(parseInt(trip.duration))}
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {trip.total_alerts}
                        </td>
                        <td className="whitespace-nowrap border px-2 py-1">
                          {" "}
                          {convertEpochTime(trip.trip_start_time)}
                        </td>
                        <td className="px-2 py-1">
                          {" "}
                          <a
                            href={`${process.env.REACT_APP_BASE_URL}/trips/completed-trip/${trip.trip_id}`}
                            target="_blank"
                            className="text-blue-600 underline"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center">
        <Button
          label="Download"
          icon="pi pi-cloud-download"
          severity="secondary"
          className="mx-2 my-4 rounded-md bg-blueSecondary px-4 py-2 text-white dark:!bg-gray-100 dark:!text-gray-850"
          onClick={downloadPDF}
        />
        <Link to="/reports">
          <Button
            label="Go Back"
            icon="pi pi-arrow-circle-left"
            severity="Primary"
            className="mx-2 my-4 rounded-md bg-gray-700 px-4 py-2 text-white dark:!bg-gray-100 dark:!text-gray-850"
          />
        </Link>
      </div>
    </>
  );
};

export default Report;
