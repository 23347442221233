import axios from "axios";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";

const AddFeatureSet = ({ cas, toastRef }) => {
  const [data, setData] = useState({});
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [fields, setFields] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const token = Cookies.get("token");
  const org_id = Cookies.get("org_id");
  const [publishPopUpVisible, setPublishPopUpVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio") {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (type === "checkbox") {
      setData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  //function to restructure data
  function restructureData(data) {
    const restructuredData = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const [mainKey, subKey] = key.split("--");
        const value = parseFloat(data[key]);

        if (subKey) {
          restructuredData[subKey] = restructuredData[subKey] || {};
          restructuredData[subKey][mainKey] = value;
        }
      }
    }

    return restructuredData;
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    // setPublish(true)

    let structuredData = await restructureData(data);

    setLoaded(false);
    if (selectedDevices.length > 0) {
      // setPublishPopUpVisible(true)
      try {
        const promises = selectedDevices.map((el) => {
          return axios.post(
            `${process.env.REACT_APP_AWS_URL2}/featuresetPublish`,
            {
              device_id: el,
              featureset_data: JSON.stringify(structuredData),
              device_type: 1,
              org_id: org_id,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          );
        });

        await Promise.all(promises);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Featureset has been updated for selected devices`,
          life: 3000,
        });
        setSelectedDevices([]);
        setPublishPopUpVisible(false);
        setLoaded(true);
      } catch (err) {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: `Featureset has not been updated for selected devices`,
          life: 3000,
        });
        setLoaded(true);
      }
    } else {
      setPublishPopUpVisible(false);
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `Select device to add featureset`,
        life: 3000,
      });
      setLoaded(true);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/${"CAS"}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoaded(true);
        let sortedFields = res.data.data[0].optionsdata.sort((a, b) => {
          return a.featureset_option_index - b.featureset_option_index;
        });
        setFields(sortedFields);

        let defaultData = res.data.data[0].optionsdata.reduce((acc, el) => {
          let uniqueKey = `${el.featureset_option_name}--${el.featureset_group_by}`;
          acc[uniqueKey] = el.featureset_option_default_value;
          return acc;
        }, {});
        setData(defaultData);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, [token]);

  const CasOptionsList = () => {
    return cas?.map((el) => ({
      label: el.device_id,
      value: el.device_id,
    }));
  };

  if (!loaded) {
    return <PreloaderList />;
  }
  const closePublishDialog = () => {
    setPublishPopUpVisible(false);
  };
  return (
    <div>
      {/* <form> */}
      <div className="rounded-lg">
        <div className="flex justify-between gap-3 rounded px-5 py-2">
          <div className="mx-auto w-[50%]">
            <span className="p-float-label">
              <MultiSelect
                id="device_id"
                options={CasOptionsList()}
                optionLabel="label"
                optionValue="value"
                className={`p-dropdown !dark:hover:text-gray-300 h-11 w-full border text-sm dark:bg-gray-900 `}
                name="device_id"
                selectAllLabel="Select All Devices"
                onChange={(e) => setSelectedDevices(e.value)}
                display="chip"
                value={selectedDevices}
              />
              <label htmlFor="device_id" className="dark:text-gray-300">
                Select Devices
              </label>
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {Object.entries(
          fields.reduce((acc, options) => {
            let dropdownOptions = [];
            try {
              if (typeof options.featureset_option_dropmenu === "string") {
                dropdownOptions = JSON.parse(
                  options.featureset_option_dropmenu
                );
                if (!Array.isArray(dropdownOptions)) {
                  throw new Error("Dropdown options is not an array");
                }
              } else if (Array.isArray(options.featureset_option_dropmenu)) {
                dropdownOptions = options.featureset_option_dropmenu;
              } else {
                throw new Error("Dropdown options is not a string or array");
              }
            } catch (error) {
              dropdownOptions = [];
            }

            const groupIndex = options.featureset_option_index;
            if (!acc[groupIndex]) {
              acc[groupIndex] = [];
            }

            acc[groupIndex].push(
              <div key={options.featureset_option_id}>
                {options.featureset_option_input_type === "dropdown" ? (
                  <div className="mt-8">
                    <span className="p-float-label">
                      <Dropdown
                        id={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ]
                        }
                        options={dropdownOptions}
                        className="h-9 w-full border dark:bg-gray-900"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: `${options.featureset_option_name}--${options.featureset_group_by}`,
                              value: e.value,
                            },
                          })
                        }
                        optionLabel="label"
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        className="dark:text-gray-300"
                      >
                        {options.featureset_option_label}
                      </label>
                    </span>
                  </div>
                ) : options.featureset_option_input_type === "radio" ? (
                  <div
                    key={options.featureset_option_id}
                    className="mt-2 w-full"
                  >
                    <label className="text-base text-gray-700  dark:text-gray-300">
                      {options.featureset_option_label}
                    </label>
                    <div className="mt-1">
                      <input
                        type="radio"
                        id={`${options.featureset_option_name}-enable`}
                        name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value="1"
                        checked={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ] === "1"
                        }
                        onChange={handleChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}-enable`}
                        className="dark:text-gray-300"
                      >
                        Enable
                      </label>
                      <input
                        type="radio"
                        id={`${options.featureset_option_name}-disable`}
                        name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                        value="0"
                        checked={
                          data[
                            `${options.featureset_option_name}--${options.featureset_group_by}`
                          ] === "0"
                        }
                        onChange={handleChange}
                        style={{ marginLeft: "15px", marginRight: "5px" }}
                      />
                      <label
                        htmlFor={`${options.featureset_option_name}-disable`}
                        className="dark:text-gray-300"
                      >
                        Disable
                      </label>
                    </div>
                  </div>
                ) : (
                  <div key={options.featureset_option_id} className=" w-[1/3]">
                    <div className="mt-8">
                      <span className="p-float-label">
                        <InputText
                          type={options.featureset_option_input_type}
                          name={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          // placeholder={options.featureset_option_placeholder}
                          value={
                            data[
                              `${options.featureset_option_name}--${options.featureset_group_by}`
                            ]
                          }
                          onChange={handleChange}
                          className="h-9 w-full border pl-2 dark:bg-gray-900 dark:text-gray-300"
                        />
                        <label
                          htmlFor={`${options.featureset_option_name}--${options.featureset_group_by}`}
                          className="dark:text-gray-300"
                        >
                          {options.featureset_option_label}
                        </label>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );

            return acc;
          }, {})
        ).map(([groupIndex, elements]) => (
          <div key={groupIndex}>
            <div className="grid grid-cols-3 gap-4 rounded border-[1px] bg-[#E9ECEF] p-3 dark:border-none dark:bg-navy-900 2xl:grid-cols-3">
              {elements}
            </div>
            <br />
          </div>
        ))}
      </div>
      {/* Submit Button */}
      <div className="mt-6 flex justify-end">
        <button
          // type="submit" setPublishPopUpVisible(true)
          onClick={() => {
            setPublishPopUpVisible(true);
          }}
          className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        >
          Publish featureset
        </button>
      </div>
      {/* </form> */}
      {/* onSubmit={handleSubmit} */}
      <Dialog
        visible={publishPopUpVisible}
        onHide={closePublishDialog}
        header="Confirm Publish"
        footer={
          <div>
            <Button
              label="Publish"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={handleSubmit}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={closePublishDialog}
            />
          </div>
        }
      >
        <div className="flex items-center">
          {/* <MdDeleteOutline className="text-2xl text-blue-400" /> */}
          <span className="text-sm font-semibold">
            Are you sure you want to publish ?
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default AddFeatureSet;
