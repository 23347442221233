import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import Cookies from "js-cookie";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import PreloaderList from "components/skeleton-preloader/PreloaderList";

const TripList = ({ orgId, trips, orderby }) => {
  const token = Cookies.get("token");
  const orgID = Cookies.get("org_id");
  const user_type = Cookies.get("user_type");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [tripData, setTripData] = useState([]);
  const [totalRecord, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [loader, setLoader] = useState(true);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  const header = (
    <div className="mb-2 flex justify-between dark:bg-gray-950">
      <span className="flex items-center">
        <input type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
        <label className="ml-1 text-sm font-normal dark:text-gray-600">
          Exclude unwanted trips{" "}
          <span className="text-xs text-gray-700">
            (whose distance is less than 1 km)
          </span>
        </label>
      </span>
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  };

  useEffect(() => {
    if (user_type === "3df557db-9e3c-11ee-9fc8-0a33c87d103e") {
      const fetchData = async () => {
        const response = await axios
          .post(
            `${process.env.REACT_APP_AWS_URL2}/allTripsList`,
            {
              org_id: orgId ? orgId : "",
              ORDER: orderby,
              LIMIT: rows.toString(),
              OFFSET: (currentPage * rows).toString(),
              Trip_Status: trips === "ot" ? "0" : "1",
            },
            { headers: { Authorization: token } }
          )
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
        const formattedData = response?.data?.data?.map((item, index) => {
          let endTimeFormatted;
          if (item.trip_end_time === "0") {
            endTimeFormatted = "Ongoing";
          } else {
            endTimeFormatted = new Date(
              item.trip_end_time * 1000
            ).toLocaleString();
          }
          return {
            ...item,
            serialNo: index + 1,
            key: index + 1,
            startTime: new Date(item.trip_start_time * 1000).toLocaleString(),
            distance: Math.round(item.total_distance * 100) / 100,
            duration: item.duration,
            endTime: endTimeFormatted,
          };
        });
        if (formattedData) {
          setLoader(false);
        }
        setTripData(formattedData);
        setTotalRecords(response?.data?.data[0]?.total_count || 0);
      };

      fetchData();
    } else {
      const fetchData = async () => {
        const response = await axios
          .post(
            `${process.env.REACT_APP_AWS_URL2}/allTripsList`,
            {
              org_id: orgID,
              ORDER: orderby,
              LIMIT: rows.toString(),
              OFFSET: (currentPage * rows).toString(),
              Trip_Status: trips === "ot" ? "0" : "1",
            },
            { headers: { Authorization: token } }
          )
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
        const formattedData = response?.data?.data?.map((item, index) => {
          let endTimeFormatted;
          if (item.trip_end_time === "0") {
            endTimeFormatted = "Ongoing";
          } else {
            endTimeFormatted = new Date(
              item.trip_end_time * 1000
            ).toLocaleString();
          }
          return {
            ...item,
            serialNo: index + 1,
            key: index + 1,
            startTime: new Date(item.trip_start_time * 1000).toLocaleString(),
            distance: Math.round(item.total_distance * 100) / 100,
            duration: item.duration,
            endTime: endTimeFormatted,
          };
        });
        if (formattedData) {
          setLoader(false);
        }
        setTripData(formattedData);
        setTotalRecords(response?.data.data[0]?.total_count || 0);
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, orgID, trips, orgId, orderby, currentPage, checked, rows]);

  const onPageChange = (event) => {
    setCurrentPage(event.page);
    setRows(event.rows);
  };

  const redirect = (selectedProduct) => {
    const url1 = `trips/completed-trip/${selectedProduct?.trip_id}`;
    const url2 = `trips/ongoing-trip/${selectedProduct?.trip_id}`;
    if (selectedProduct?.trip_status === 1) {
      window.open(url1, "_blank");
    } else {
      window.open(url2, "_blank");
    }
  };

  useEffect(() => {
    if (selectedProduct !== null) {
      redirect(selectedProduct);
    }
  }, [selectedProduct]);

  const handleSelectionChange = (e) => {
    setSelectedProduct(e.value);
  };

  return (
    <>
      {loader ? (
        <PreloaderList />
      ) : (
        <>
          <DataTable
            removableSort
            value={
              checked ? tripData?.filter((item) => item.distance > 1) : tripData
            }
            dataKey="trip_id"
            selectionMode="single"
            selection={selectedProduct}
            onSelectionChange={handleSelectionChange}
            header={header}
            filters={filters}
            globalFilterFields={[
              "vehicle_reg_number",
              "startTime",
              "endTime",
              "vehicle_name",
              "distance",
              "duration",
              "orgName",
            ]}
            // totalRecords={totalRecord}
            emptyMessage="No trips found."
            // onPage={onPageChange}
            // first={currentPage * rows}
          >
            <Column
              key="trip_id"
              header="#"
              className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{
                minWidth: "4rem",
                padding: "5px 0px 5px 2rem",
              }}
              bodyStyle={{
                textAlign: "left",
                overflow: "visible",
                padding: "0px 0px 0px 2rem",
              }}
              body={(rowData) => <span>{tripData?.indexOf(rowData) + 1}</span>}
            />
            <Column
              header="Vehicle Number"
              field="vehicle_reg_number"
              style={{ minWidth: "6rem", padding: "5px" }}
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              // body={(rowData) => {
              //   return (
              //     <p className="text-sm">
              //       {rowData.trip_id.split("-")[0].toUpperCase()}
              //     </p>
              //   );
              // }}
            />
            <Column
              field="vehicle_name"
              header="Vehicle Name"
              style={{ minWidth: "10rem", padding: "5px" }}
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            />
            <Column
              field="startTime"
              header="Start Time"
              style={{ minWidth: "10rem", padding: "5px" }}
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              body={(rowData) => {
                return (
                  <Tag
                    className="mr-2 bg-green-100 text-gray-800"
                    style={{
                      width: "fit-content",
                      height: "25px",
                    }}
                    value={rowData.startTime}
                    icon="pi pi-caret-right"
                  />
                );
              }}
            />
            <Column
              field="endTime"
              header="End Time"
              style={{ minWidth: "11rem", padding: "5px" }}
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              body={(rowData) => {
                return (
                  <Tag
                    className={`mr-2 text-gray-800  ${
                      rowData.trip_status === 0 ? "bg-green-100" : "bg-red-200"
                    }`}
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                    }}
                    icon="pi pi-stop-circle"
                    value={
                      rowData.trip_status === 0
                        ? rowData.endTime
                        : rowData.endTime
                    }
                  />
                );
              }}
            />
            <Column
              field="distance"
              header="Distance"
              sortable
              style={{ minWidth: "6rem", padding: "5px" }}
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              body={(rowData) => {
                return <>{rowData.distance + " km"}</>;
              }}
            />
            <Column
              field="duration"
              header="Duration"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "5rem", padding: "5px" }}
              body={(rowData) => {
                return formatDuration(rowData.duration);
              }}
            />
          </DataTable>
          <div className="flex items-center justify-center">
            <Paginator
              first={currentPage * rows}
              rows={rows}
              totalRecords={totalRecord}
              onPageChange={onPageChange}
              rowsPerPageOptions={[20, 45, 60]}
            />
            <p className="text-xs text-gray-700">
              Showing {rows} trips of {totalRecord}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default TripList;
