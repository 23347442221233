const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <h5 className="mb-4 text-center font-medium text-gray-600 sm:!mb-0 md:text-sm">
        <div className="mb-4 text-center text-gray-700 sm:!mb-0 md:text-base">
          <span className="text-sm">©{1900 + new Date().getYear()} &nbsp;</span>
          <a
            href="https://starkenn.com/"
            className="text-sm hover:underline"
            target="blank"
          >
            Starkenn Technologies
          </a>
          &nbsp;&nbsp;
          <div className="heartbeat text-sm">
            <span>❤️</span>
          </div>
          <span className="text-xs">&nbsp;All Rights Reserved.</span>
        </div>
      </h5>
      <div>
        <ul className="footer-content flex flex-wrap items-start gap-3 sm:flex-wrap md:gap-10">
          <li>
            <a
              target="blank"
              href="https://www.starkenn.com/contact-us"
              className="flex flex-col text-sm font-medium text-gray-700 hover:text-gray-600"
            >
              <span>Support</span>
              <span>support@starkenn.com</span>
            </a>
          </li>
          <li>
            <button className="text-sm font-medium text-gray-700 hover:text-gray-600">
              <a href="https://www.starkenn.com/terms-of-use" target="blank">
                Terms & Conditions
              </a>
            </button>
          </li>
          <li>
            <button className="text-sm font-medium text-gray-700 hover:text-gray-600">
              <a href="https://www.starkenn.com/privacy-policy" target="blank">
                Privacy Policy
              </a>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
