import axios from "axios";
import Cookies from "js-cookie";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import PreloaderList from "components/skeleton-preloader/PreloaderList";
import CorelationListRoot from "./components/CorelationList";
import { AppContext } from "context/AppContext";

const CorelationsRoot = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedSim, setSelectedSim] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [sim, setSim] = useState([]);
  const token = Cookies.get("token");
  const org_id = Cookies.get("org_id");
  const toastRef = useRef();
  const { corel, updateCorel } = useContext(AppContext);

  const openDialog = () => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setSelectedSim(null);
    setSelectedDevice(null);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getUnlikedDeviceData_STD/${org_id}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setDevices(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getUnlikedSimData_STD/${org_id}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setSim(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${process.env.REACT_APP_AWS_URL2}/getCoRelationsForSTD`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.status === 200) {
          const formattedData = res.data.data.map((item, index) => ({
            ...item,
            serialNo: index + 1,
            key: index + 1,
          }));
          setData(formattedData);
          setLoaded(true);
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [token, org_id, corel]);

  const deviceOptions = () => {
    return devices?.map((el) => ({
      label: el.device_id,
      value: el.device_id,
    }));
  };

  const simOptions = () => {
    return sim?.map((el) => ({
      label: el.sim_number,
      value: el.sim_number,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/addCoRelations`,
        {
          corel_type: "STD",
          corel_with: selectedSim,
          corel_to: selectedDevice,
          device_type_id: null,
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        const sim_number = simOptions().filter(
          (item) => item.value === selectedSim
        );
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Sim number ${sim_number[0].label} is successfully assigned to ${selectedDevice}`,
          life: 3000,
        });
        updateCorel();
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
        Connections
      </h4>
      <button
        className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        onClick={openDialog}
      >
        <FiPlus />
        &nbsp;New Connection
      </button>
      {loaded ? (
        <CorelationListRoot data={data} toast={toastRef} />
      ) : (
        <PreloaderList />
      )}
      <Dialog
        visible={dialogVisible}
        onHide={closeDialog}
        style={{ width: "40%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        modal
        header="Assign simcard to device"
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmit}>
          <div className="mt-8 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
            <span className="p-float-label">
              <Dropdown
                id="device"
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(e.value)}
                options={deviceOptions()}
                optionLabel="label"
                filter
                className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
              />
              <label htmlFor="device" className="dark:text-gray-300">
                Select a device
              </label>
            </span>

            <span className="p-float-label">
              <Dropdown
                id="sim"
                value={selectedSim}
                filter
                onChange={(e) => setSelectedSim(e.value)}
                options={simOptions()}
                optionLabel="label"
                className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
              />
              <label htmlFor="sim" className="dark:text-gray-300">
                Select a simcard
              </label>
            </span>
          </div>

          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Assign
            </button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default CorelationsRoot;
