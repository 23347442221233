import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { FilterMatchMode } from "primereact/api";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Cookies from "js-cookie";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";

export default function VehiclesList({ data, tag, onEditOrDelete }) {
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const toast = useRef(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [validationErrors, setValidationErrors] = useState({
    vehicle_name: false,
    device_type_id: false,
    vehicle_type: false,
    vehicle_featureset: false,
  });
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [addData, setAddData] = useState({
    vehicle_name: "",
    vehicle_reg_number: "",
    vehicle_type_id: "",
    vehicle_type_name: "",
  });
  const [EditDialogVisible, setEditDialogVisible] = useState(false);
  const org_id = Cookies.get("org_id");
  const user_type = Cookies.get("user_type");
  const token = Cookies.get("token");
  const toastRef = useRef(null);
  //global search
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearSearch = () => {
    setGlobalFilterValue("");
    const _filters = { ...filters };
    _filters["global"].value = null;
    setFilters(_filters);
  };

  //---> Complete functionality for Edit

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/fetchLookup`,
        { FetchType: "VehicleTypeLookup" },
        { headers: { Authorization: token } }
      )
      .then((lookupResponse) => {
        setVehicleTypes(lookupResponse.data.data);
      });
  }, [token]);

  const vehiclesOptions = () => {
    return vehicleTypes?.map((el) => ({
      label: el.vehicleTypeName,
      value: el.vehicleTypeId,
    }));
  };

  const vehiclesActiveOptions = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 2 },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const EditDialogBox = (rowData) => {
    // vehicleTypes.map((el)=>{
    //   if(el.vehicleTypeName === rowData.vehicle_type_name){
    //     rowData.vehicle_type_name = el.vehicleTypeId;
    //   }
    // })

    setAddData(rowData);

    setEditDialogVisible(true);
  };

  //TODO handling the edit function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !addData.vehicle_name ||
      !addData.vehicle_reg_number ||
      !addData.vehicle_type_name
    ) {
      setValidationErrors({
        vehicle_name: !addData.vehicle_name,
        vehicle_reg_number: !addData.vehicle_reg_number,
        vehicle_type_name: !addData.vehicle_type_name,
      });

      // Display toast for validation error
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: "All fields are required",
        life: 3000,
      });

      return; // Stop further processing
    }

    const vt = addData.vehicle_type;
    vehicleTypes.forEach((el) => {
      if (el.vehicleTypeId === vt) {
        addData.vehicle_type_name = el.vehicleTypeName;
      }
    });
    axios
      .put(
        `${process.env.REACT_APP_AWS_URL1}/updateVehicle/${addData.vehicle_id}`,
        { ...addData, org_id },
        {
          headers: { Authorization: token },
        }
      )
      .then(() => {
        // setDevices(addData);
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `Vehicle ${addData.vehicle_name} added successfully`,
          life: 3000,
        });
        setEditDialogVisible(false);
        onEditOrDelete(addData);
      })
      .catch((err) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };
  //FIXME above function edit
  //---> Edit functionality ends here

  const deleteVehicle = (rowData) => {
    setDeleteDialogVisible(true);
    setDeleteData(rowData);
  };

  const actionBodyTemplate = (rowData) => {
    const vehicleId = rowData.vehicle_id;
    return (
      <div className="flex text-lg">
        <Link to={`${vehicleId}`}>
          <FaRegEye title="View" className="cursor-pointer text-gray-700" />
        </Link>
        {user_type !== "3df557db-9e3c-11ee-9fc8-0a33c87d103e" && (
          <>
            <FaRegEdit
              title="Edit"
              onClick={() => {
                EditDialogBox(rowData);
              }}
              className="mx-4 cursor-pointer text-gray-700"
            />
            <RiDeleteBin6Line
              title="Delete"
              onClick={() => {
                rowData.vehicle_active === 2
                  ? deleteVehicle(rowData)
                  : console.log();
              }}
              className={`cursor-pointer ${
                rowData.vehicle_active === 2 ? "text-red-600" : "text-gray-500"
              }`}
            />
          </>
        )}
      </div>
    );
  };
  //

  const getSeverity = (data) => {
    switch (data.vehicle_active) {
      case 1:
        return "success";

      case 2:
        return "danger";

      default:
        return null;
    }
  };

  const header = (
    <div className="my-2 flex justify-end  dark:bg-gray-950">
      <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
        <i className="pi pi-search pl-2 dark:text-gray-300" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
        />
        {globalFilterValue && (
          <Button
            icon="pi pi-times"
            className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
            onClick={clearSearch}
          />
        )}
      </span>
    </div>
  );

  //--->Complete functionality for delete

  const handleConfirmDelete = (vehicleId) => {
    try {
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL1}/deleteVehicle/${vehicleId}`,
          {},
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then(() => {
          setDeleteDialogVisible(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `Vehicle ${vehicleId} deleted successfully`,
            life: 3000,
          });
          onEditOrDelete(vehicleId);
        });
    } catch (err) {
      setDeleteDialogVisible(false);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: `Vehicle ${vehicleId} can't be deleted`,
        life: 3000,
      });
    }
  };

  const DeleteDialogBox = () => {
    return (
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => {
          setDeleteDialogVisible(false);
        }}
        header="Confirm Delete"
        footer={
          <div>
            <Button
              label="Delete"
              className="mr-2 bg-red-500 px-2 py-1 text-xs text-white hover:bg-red-400 dark:hover:bg-red-500 dark:hover:text-white"
              onClick={() => {
                handleConfirmDelete(deleteData.vehicle_id);
              }}
            />
            <Button
              label="Cancel"
              className="bg-gray-700 px-2 py-1 text-xs text-white dark:text-gray-850 dark:hover:bg-gray-600 dark:hover:text-gray-850"
              onClick={() => {
                setDeleteDialogVisible(false);
              }}
            />
          </div>
        }
      >
        <div className="flex items-center">
          <MdDeleteOutline className="text-2xl text-blue-400" />
          Are you sure you want to delete this vehicle?
        </div>
      </Dialog>
    );
  };
  //---> delete functionality ends here

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastRef} />
      <DeleteDialogBox />
      <div className="card rounded-lg bg-none dark:bg-gray-950">
        <DataTable
          removableSort
          value={tag ? data.filter((item) => item.tag === tag) : data}
          selection={selectedVehicles}
          onSelectionChange={(e) => setSelectedVehicles(e.value)}
          dataKey="vehicle_id"
          paginator
          rows={20}
          rowsPerPageOptions={[20, 35, 50]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} vehicles"
          filterDisplay="menu"
          filters={filters}
          globalFilterFields={[
            "vehicle_name",
            "vehicle_reg_number",
            "org_name",
            "dms",
            "iot",
            "ecu",
            "tag",
            "vehicle_type_name",
          ]}
          emptyMessage="No vehicles found."
          header={header}
        >
          <Column
            key="serialNo"
            field="serialNo"
            header="#"
            className="border-b pl-4 text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{
              minWidth: "4rem",
              padding: "5px 0 5px 2rem",
            }}
            bodyStyle={{
              textAlign: "left",
              overflow: "visible",
              padding: "0px 2rem",
            }}
          ></Column>
          <Column
            key="vehicle_name"
            field="vehicle_name"
            header="Vehicle Name"
            sortable
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "10rem", padding: "5px" }}
            bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          ></Column>
          <Column
            field="vehicle_reg_number"
            header="Registration No."
            sortable
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "10rem", padding: "5px" }}
            bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}
          ></Column>
          {data[0]?.org_name && (
            <Column
              key="org_name"
              field="org_name"
              header="Organization Name"
              sortable
              className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
              style={{ minWidth: "15rem", padding: "5px" }}
            ></Column>
          )}
          <Column
            field="vehicle_type_name"
            header="Tag"
            body={(rowData) => (
              <Tag
                className="mr-2 bg-gray-200 text-gray-800"
                icon="pi pi-tag"
                style={{
                  width: "fit-content",
                  height: "25px",
                }}
                value={rowData.tag}
              />
            )}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "8rem", padding: "5px" }}
          ></Column>
          <Column
            header="Status"
            field="vehicle_status"
            sortable
            body={(rowData) => (
              <Tag
                className="h-5 rounded-sm text-xs font-normal"
                value={rowData.vehicle_status}
                severity={getSeverity(rowData)}
              ></Tag>
            )}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "7rem", padding: "5px" }}
          ></Column>
          {/* <Column
            field="vehicleLinked"
            header="Linked to user"
            sortable
            body={availabilityTemplate}
            bodyStyle={{
              textAlign: "left",
              overflow: "visible",
              padding: "0px 2rem",
            }}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{ minWidth: "10rem", padding: "5px", textAlign: "center" }}
          ></Column> */}
          <Column
            key="action"
            body={actionBodyTemplate}
            header="Action"
            exportable={false}
            className="border-b text-sm dark:bg-navy-800 dark:text-gray-200"
            style={{
              minWidth: "8rem",
              padding: "5px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          ></Column>
        </DataTable>
      </div>

      {/* <Dialog
        visible={EditDialogVisible}
        onHide={setEditDialogVisible(false)}
        header="Completed Trips"
        className="v-view"
        style={{ width: "82vw" }}
      >
        <VehicleTrips myData={myData} />
      </Dialog> */}

      {/* Edit Dialog here */}
      <Dialog
        visible={EditDialogVisible}
        onHide={() => {
          setEditDialogVisible(false);
        }}
        style={{ width: "40%", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form className="mx-auto" onSubmit={handleSubmit}>
          <div className="mt-5 grid grid-cols-2 gap-y-8 md:grid-cols-2">
            <span className="p-float-label">
              <InputText
                id="vehicle_name"
                name="vehicle_name"
                value={addData.vehicle_name}
                onChange={(e) => handleChange(e, "vehicle_name")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.vehicle_name ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="vehicle_name" className="dark:text-gray-300">
                Vehicle Name
              </label>
            </span>

            <span className="p-float-label">
              <InputText
                id="vehicle_reg_number"
                name="vehicle_reg_number"
                value={addData.vehicle_reg_number}
                onChange={(e) => handleChange(e, "vehicle_reg_number")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.vehicle_reg_number ? "border-red-600" : ""
                }`}
              />
              <label
                htmlFor="vehicle_reg_number"
                className="dark:text-gray-300"
              >
                Vehicle Registration Number
              </label>
            </span>

            <div
              className={`mx-auto w-full ${
                validationErrors.device_type_id ? "p-error" : ""
              }`}
            >
              <span className="p-float-label">
                <Dropdown
                  id="vehicle_type"
                  name="vehicle_type_id"
                  options={vehiclesOptions()}
                  className={`p-dropdown h-9 border text-sm ${
                    validationErrors.vehicle_type_name ? "border-red-600" : ""
                  }`}
                  onChange={handleChange}
                  value={addData.vehicle_type_id}
                />
                <label htmlFor="device_type" className="dark:text-gray-300">
                  Vehicle Type
                </label>
              </span>
              {validationErrors.vehicle_type_name && (
                <small className="text-red-600">Vehicle Type is required</small>
              )}
            </div>

            <div
              className={`mx-auto w-full ${
                validationErrors.device_type_id ? "p-error" : ""
              }`}
            >
              <span className="p-float-label">
                <Dropdown
                  id="vehicle_active"
                  name="vehicle_active"
                  options={vehiclesActiveOptions}
                  optionLabel="label"
                  optionValue="value"
                  className={`p-dropdown h-9 border text-sm`}
                  onChange={handleChange}
                  value={addData.vehicle_active}
                />
                <label htmlFor="vehicle_active" className="dark:text-gray-300">
                  Vehicle Status
                </label>
              </span>
            </div>
            <span className="p-float-label">
              <InputText
                id="tag"
                name="tag"
                value={addData.tag}
                onChange={(e) => handleChange(e, "tag")}
                className={`border py-1.5 pl-2 text-sm ${
                  validationErrors.tag ? "border-red-600" : ""
                }`}
              />
              <label htmlFor="tag" className="dark:text-gray-300">
                Tag
              </label>
            </span>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Update
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
