import axios from "axios";
import { AppContext } from "context/AppContext";
import Cookies from "js-cookie";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";

const UTV = ({ toastRef, closeDialog, assignDriver = "No" }) => {
  console.log("assignDriver:::", assignDriver);
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  console.log(
    "selectedUser,selectedVehicles:::::",
    selectedVehicles,
    selectedUser
  );
  const [users, setUsers] = useState([]);
  const org_id = Cookies.get("org_id");
  const token = Cookies.get("token");
  const { updateCorel } = useContext(AppContext);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_AWS_URL2}/getUnlikedVehiclesRelations/${org_id}`,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getUsers`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setUsers(res.data.data[0].userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, org_id]);

  const vehicleOptions = () => {
    return vehicles?.map((el) => ({
      label: el.vehicle_name + " " + el.vehicle_reg_number,
      value: el.vehicle_id,
    }));
  };

  const userOptions = () => {
    return users?.map((el) => ({
      label: el.first_name + " " + el.last_name,
      value: el.user_id,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //Add data to coRelations
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL2}/addCoRelations`,
        {
          corel_type: "UTV",
          corel_with: selectedUser,
          corel_to: selectedVehicles,
          device_type_id: null,
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        const selectedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === selectedVehicles
        );
        const selectedUserName = users.find(
          (user) => user.user_id === selectedUser
        );
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `${selectedVehicle.vehicle_name} is successfully assigned to ${selectedUserName.first_name} ${selectedUserName.last_name}`,
          life: 3000,
        });
        closeDialog();
        updateCorel();
      })
      .catch((err) => {
        if (err.response && err.response.data.message) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      });
  };

  const selectedDeviceTemplate = (option, props) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const deviceOptionTemplate = (option) => {
    return (
      <div className="align-items-center flex">
        <div>{option.label}</div>
      </div>
    );
  };

  //Assigmnet From Driver
  const handleSubmitDriverSupervisor = () => {
    // e.preventDefault();

    //Add data to coRelations
    axios
      .post(
        `${process.env.REACT_APP_AWS_URL1}/addSubUserAssignment`,
        {
          user_id: selectedUser,
          vehicle_id: selectedVehicles,
        },
        { headers: { Authorization: token } }
      )
      .then(() => {
        const selectedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === selectedVehicles
        );
        const selectedUserName = users.find(
          (user) => user.user_id === selectedUser
        );
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: `${selectedVehicle.vehicle_name} is successfully assigned to ${selectedUserName.first_name} ${selectedUserName.last_name}`,
          life: 3000,
        });
        closeDialog();
        updateCorel();
      })
      .catch((err) => {
        if (err.response && err.response.data.message) {
          toastRef.current.show({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      });
  };
  return (
    <>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
        <span className="p-float-label">
          <Dropdown
            id="vehicle"
            value={selectedVehicles}
            onChange={(e) => setSelectedVehicles(e.value)}
            options={vehicleOptions()}
            optionLabel="label"
            filter
            className={`p-dropdown !dark:hover:text-gray-300 h-9 w-full border border-gray-300 text-sm dark:bg-gray-900 `}
          />
          <label htmlFor="vehicle" className="dark:text-gray-300">
            Select a Vehicle
          </label>
        </span>
        <span className="p-float-label">
          <Dropdown
            id="device"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.value)}
            options={userOptions()}
            optionLabel="label"
            filter
            valueTemplate={selectedDeviceTemplate}
            itemTemplate={deviceOptionTemplate}
            className={`p-dropdown h-9 w-[100%] border border-gray-300 text-sm`}
          />
          <label htmlFor="device" className="dark:text-gray-300">
            Select a User
          </label>
        </span>
      </div>
      <div className="flex justify-end p-4">
        {assignDriver === "yes" ? (
          <>
            {" "}
            <button
              // type="submit"
              onClick={handleSubmitDriverSupervisor}
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Assign
            </button>
          </>
        ) : (
          <>
            <button
              // type="submit" handleSubmit
              onClick={handleSubmit}
              className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
            >
              Assign
            </button>
          </>
        )}
      </div>
      {/* </form> */}
    </>
  );
};

export default UTV;
