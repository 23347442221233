import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "./auth/SignIn";
import Profile from "./auth/Profile";
import AdminLayout from "layouts/admin";
import Verify from "./auth/EmailVerify";
import ResetPassword from "./auth/ResetPassword";
import AdminProtected from "authorization/AdminProteted";
import Report from "./admin/reports/components/Report";
import ScheduledReport from "./admin/reports/components/ScheduledReport";
import QuickReportView from "./admin/reports/components/QuickReportView";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/" element={<Navigate to="/signin" replace />} />
      <Route path="/profile" element={<Profile />} />
      <Route
        path="/*"
        element={
          <AdminProtected>
            <AdminLayout />
          </AdminProtected>
        }
      />
      <Route path="/verify" element={<Verify />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="report/:report_uuid" element={<Report />} />
      <Route
        path="scheduled-report/:report_uuid"
        element={<ScheduledReport />}
      />
      <Route path="report/quick-report" element={<QuickReportView />} />
    </Routes>
  );
};

export default AllRoutes;
