import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { FiPlus } from "react-icons/fi";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { MdAddCircle } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";
import Cookies from "js-cookie";
import axios from "axios";
import CasFeaturesetOptions from "./Components/CasOptionsList";
import DmsFeatureSetOptions from "./Components/DmsOptionsList";
import AlcoholFetureSetOptions from "./Components/AlcoholOptionsList";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import PreloaderList from "components/skeleton-preloader/PreloaderList";

function FeaturesetOption() {
  const token = Cookies.get("token");
  const [detailsOptions, setDetailsOptions] = useState([
    { featureset_option_dropmenu: [] },
  ]); // Initialize with an empty array
  const [menuOptions, setmenuOptions] = useState([{ id: 1 }]);
  const [addFilterDialog, setAddFilterDialog] = useState(false);
  const [submittedNew, setSubmittedNew] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const toastRef = useRef(null);

  const handleSubmitOptions = (e) => {
    e.preventDefault();
    detailsOptions[0].featureset_option_dropmenu = JSON.stringify(menuOptions);
    if (detailsOptions[0].featureset_option_input_type !== "dropdown") {
      detailsOptions[0].featureset_option_dropmenu = [];
    }
    //make the api call for saving the featureset options
    try {
      axios
        .post(
          `${process.env.REACT_APP_AWS_URL2}/addFeaturesetOptions`,
          detailsOptions[0],
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          setSubmittedNew(!submittedNew);
          //TODO close the popup and before that add the data to a field for the useEffect dependencies
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail:
              "Featureset Option Added Successfully" || response.data?.message,
            life: 3000,
          });
          onHideFilter();
        });
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Cannot add the Featureset Option, Kindly check it once and if error continues then Contact Us",
        life: 3000,
      });
      onHideFilter();
    }
  };

  const onHideFilter = () => {
    setAddFilterDialog(false);
    setDetailsOptions([{ featureset_option_dropmenu: [] }]); // Reset detailsOptions when hiding the filter dialog
    setmenuOptions([{ id: 1 }]);
  };

  const handleDetailsOptions = (index, field, value) => {
    // Create a copy of the existing detailsOptions array
    const newDetailsOptions = [...detailsOptions];
    // If the index is within the array bounds, update the specific field
    if (newDetailsOptions[index]) {
      newDetailsOptions[index][field] = value;
    } else {
      // If the index is out of bounds, create a new object at that index and set the field
      newDetailsOptions[index] = { [field]: value };
    }
    // Update the state with the modified detailsOptions array
    setDetailsOptions(newDetailsOptions);
  };

  const handleMenuOptions = (index, field, value) => {
    // Create a copy of the existing detailsOptions array
    const newMenuOptions = [...menuOptions];
    // If the index is within the array bounds, update the specific field
    if (newMenuOptions[index]) {
      newMenuOptions[index][field] = value;
    } else {
      // If the index is out of bounds, create a new object at that index and set the field
      newMenuOptions[index] = { [field]: value };
    }
    // Update the state with the modified detailsOptions array
    setmenuOptions(newMenuOptions);
  };

  const handleAddMoreMenu = () => {
    // Add a new empty object to the detailsOptions array
    setmenuOptions([...menuOptions, { id: menuOptions.length + 1 }]);
  };

  const featuresetOptionsForList = [
    { label: "Collision Avoidance", value: "CAS" },
    { label: "Alcohol detection", value: "ALCOHOL" },
    { label: "Driver Monitoring", value: "DMS" },
  ];

  const InputType = [
    { label: "Number", value: "number" },
    { label: "Radio", value: "radio" },
    { label: "Dropdown", value: "dropdown" },
  ];

  //TODO for the CAS data here --get API
  //--------------
  //------------------------------------------------------------
  //Storing the data of Options got from the api call
  const [CASdata, setCASData] = useState(null);
  const [DMSdata, setDMSData] = useState(null);
  const [Alcoholdata, setAlcoholData] = useState(null);

  //value to be changed after every time the value will be changed
  const [CASFrequentData, setCASFrequentData] = useState(null);

  //changed data here

  //TODO API to fetch all the CAS Feature Options
  useEffect(() => {
    try {
      //------fetcing the CAS Options--------//
      axios
        .get(`${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/CAS`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          let formattedData = response.data.data[0].optionsdata.map(
            (item, index) => ({
              ...item,
              serialNo: index + 1,
              key: index + 1,
            })
          );
          setCASData(formattedData);
          setIsLoaded(true);
        });

      //---------fetching the DMS Options-----------//
      axios
        .get(`${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/DMS`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          let formattedData = response.data.data[0].optionsdata.map(
            (item, index) => ({
              ...item,
              serialNo: index + 1,
              key: index + 1,
            })
          );
          setDMSData(formattedData);
          setIsLoaded(true);
        });

      //----------fetching the Alcohol Options----------//
      axios
        .get(`${process.env.REACT_APP_AWS_URL2}/getFeaturesetOptions/ALCOHOL`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          let formattedData = response.data.data[0].optionsdata.map(
            (item, index) => ({
              ...item,
              serialNo: index + 1,
              key: index + 1,
            })
          );
          setAlcoholData(formattedData);
          setIsLoaded(true);
        });
    } catch (error) {
      console.log(error);
    }
  }, [CASFrequentData, token, submittedNew]);

  //FIXME - Adding the delete functionality here
  const DeleteFeaturesetOption = (id) => {
    try {
      //API call to delete FS-Option
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL2}/deleteFeaturesetOptions/${id}`,
          {},
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          //getting the response here
          if (
            response.data.message === "Featureset option deleted successfully!"
          ) {
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: "Featureset Option Deleted Successfully",
              life: 3000,
            });

            setCASFrequentData([{ id: id }]);
          } else {
            console.log(response);
          }
        });
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Cannot Delete the Featureset Option, Kindly check it once and if error continues then Contact Us",
        life: 3000,
      });
    }
  };

  //FIXME API to Edit the featureset Options
  const onSubmitEditFSoption = (id, Editdata) => {
    try {
      if (Editdata.featureset_option_input_type !== "dropdown") {
        Editdata.featureset_option_dropmenu = [];
      }
      axios
        .put(
          `${process.env.REACT_APP_AWS_URL2}/editFeaturesetOptions/${id}`,
          Editdata,
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          //-----after getting the response----
          if (
            response.data.message === "FeaturesetOption updated successfully!"
          ) {
            toastRef.current.show({
              severity: "success",
              summary: "Success",
              detail: "Featureset option updated successfully!",
              life: 3000,
            });

            setCASFrequentData(Editdata);
          } else {
            console.log(response);
          }
        });
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "Cannot Edit the Featureset Option, Kindly check it once and if error continues then Contact Us",
        life: 3000,
      });
    }
  };

  //-----Concept for the Search Bar
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  // Clear Search
  const clearSearch = () => {
    setGlobalFilterValue(""); // Clear the search input value
    const _filters = { ...filters };
    _filters["global"].value = null; // Clear the global filter value
    setFilters(_filters);
  };

  //Searchbox
  const renderHeader = () => {
    return (
      <div className="my-2 flex justify-end  dark:bg-gray-950">
        <span className="p-input-icon-left flex rounded-lg border-[1px] border-gray-300">
          <i className="pi pi-search pl-2" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="searchbox h-[30px] w-[25vw] cursor-pointer border py-2 pl-8 text-sm font-normal dark:bg-gray-950 dark:text-gray-50"
          />
          {globalFilterValue && (
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-text py-auto h-[30px] dark:text-gray-50 dark:hover:text-gray-50"
              onClick={clearSearch}
            />
          )}
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div className="flex justify-between">
        <h4 className="text-dark pt-1 text-xl font-semibold dark:text-white">
          Feature Set Options
        </h4>
      </div>
      <button
        className="flex items-center rounded-lg bg-blue-500 px-2 py-1 text-left text-sm font-normal text-white hover:bg-blue-600"
        onClick={() => {
          setAddFilterDialog(true);
        }}
      >
        <FiPlus />
        &nbsp;New Featureset Option
      </button>

      <Dialog
        visible={addFilterDialog}
        onHide={() => {
          onHideFilter();
        }}
        style={{ width: "35vw", height: "fit-content" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Fill the details"
        modal
        className="p-fluid dark:bg-gray-900"
      >
        <form onSubmit={handleSubmitOptions}>
          <div>
            {detailsOptions.map((option, index) => (
              <div key={index}>
                <div className="mt-8 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
                  <span className="p-float-label">
                    <InputText
                      id={`featureset_option_name`}
                      name={`featureset_option_name`}
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_option_name",
                          e.target.value
                        )
                      }
                    />
                    <label
                      htmlFor={`featureset_option_name`}
                      className="dark:text-gray-300"
                    >
                      Name
                    </label>
                  </span>
                  <span className="p-float-label">
                    <InputText
                      id="featureset_option_label"
                      name="featureset_option_label"
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_option_label",
                          e.target.value
                        )
                      }
                    />
                    <label
                      htmlFor="featureset_option_label"
                      className="dark:text-gray-300"
                    >
                      Label
                    </label>
                  </span>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
                  <span className="p-float-label">
                    <InputText
                      id="featureset_option_placeholder"
                      name="featureset_option_placeholder"
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_option_placeholder",
                          e.target.value
                        )
                      }
                    />
                    <label
                      htmlFor="featureset_option_placeholder"
                      className="dark:text-gray-300"
                    >
                      Placeholder
                    </label>
                  </span>

                  <span className="p-float-label">
                    <Dropdown
                      id="featureset_option_input_type"
                      name="featureset_option_input_type"
                      options={InputType}
                      className={`p-dropdown h-9 border text-sm `}
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_option_input_type",
                          e.value
                        )
                      }
                      value={
                        detailsOptions[index]?.featureset_option_input_type ||
                        null
                      }
                    />
                    <label
                      htmlFor="featureset_option_for"
                      className="dark:text-gray-300"
                    >
                      Input type
                    </label>
                  </span>
                </div>
                {detailsOptions[index]?.featureset_option_input_type ===
                "dropdown" ? (
                  <div>
                    {menuOptions?.map((MenuOptions, key) => (
                      <>
                        <div className="mt-6 grid grid-cols-2 gap-4 xl:grid-cols-2">
                          <span className="p-float-label">
                            <InputText
                              id="featureset_option_dropmenu_key"
                              name="featureset_option_dropmenu_key"
                              className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                              onChange={(e) => {
                                handleMenuOptions(key, "label", e.target.value);
                              }}
                            />
                            <label
                              htmlFor="featureset_option_dropmenu_key"
                              className="dark:text-gray-300"
                            >
                              Option Label
                            </label>
                          </span>
                          <span className="p-float-label">
                            <InputText
                              id="featureset_option_dropmenu_value"
                              name="featureset_option_dropmenu_value"
                              className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                              onChange={(e) => {
                                handleMenuOptions(key, "value", e.target.value);
                              }}
                            />
                            <label
                              htmlFor="featureset_option_dropmenu_value"
                              className="dark:text-gray-300"
                            >
                              Option Value
                            </label>
                          </span>
                        </div>
                      </>
                    ))}

                    <MdAddCircle
                      className="align-center mt-2 w-[100%] cursor-pointer text-lg text-gray-700"
                      onClick={handleAddMoreMenu}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="mt-6 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
                  <span className="p-float-label">
                    {detailsOptions[index].featureset_option_input_type ===
                    "radio" ? (
                      <InputText
                        type="number"
                        id="featureset_option_default_value"
                        name="featureset_option_default_value"
                        className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                        min={0}
                        max={1}
                        onChange={(e) =>
                          handleDetailsOptions(
                            index,
                            "featureset_option_default_value",
                            e.target.value
                          )
                        }
                        defaultValue={0}
                      />
                    ) : (
                      <InputText
                        type="number"
                        id="featureset_option_default_value"
                        name="featureset_option_default_value"
                        className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                        onChange={(e) =>
                          handleDetailsOptions(
                            index,
                            "featureset_option_default_value",
                            e.target.value
                          )
                        }
                      />
                    )}

                    <label
                      htmlFor="featureset_option_default_value"
                      className="dark:text-gray-300"
                    >
                      Default Value
                    </label>
                  </span>
                  <span className="p-float-label">
                    <InputText
                      id="featureset_group_by"
                      name="featureset_group_by"
                      className="border py-1.5 pl-2 text-sm dark:bg-navy-200"
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_group_by",
                          e.target.value
                        )
                      }
                    />
                    <label
                      htmlFor="featureset_group_by"
                      className="dark:text-gray-300"
                    >
                      Group by
                    </label>
                  </span>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
                  <span className="p-float-label">
                    <Dropdown
                      id="featureset_option_for"
                      name="featureset_option_for"
                      options={featuresetOptionsForList}
                      className={`p-dropdown h-9 border text-sm`}
                      onChange={(e) =>
                        handleDetailsOptions(
                          index,
                          "featureset_option_for",
                          e.value
                        )
                      }
                      value={
                        detailsOptions[index]?.featureset_option_for || null
                      }
                    />
                    <label
                      htmlFor="featureset_option_for"
                      className="dark:text-gray-300"
                    >
                      Option for
                    </label>
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-center">
            <button
              type="submit"
              className="ml-auto rounded bg-blue-500 px-2 py-1 text-sm font-semibold text-white hover:bg-blue-600"
            >
              Add Options
            </button>
          </div>
        </form>
      </Dialog>
      {isLoaded ? (
        <TabView className="mt-4 rounded-xl bg-white py-4">
          <TabPanel header="Collision Avoidance">
            <CasFeaturesetOptions
              data={CASdata}
              onDelete={DeleteFeaturesetOption}
              onEdit={onSubmitEditFSoption}
              header={header}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Driver Monitoring">
            <DmsFeatureSetOptions
              data={DMSdata}
              onDelete={DeleteFeaturesetOption}
              onEdit={onSubmitEditFSoption}
              header={header}
              filters={filters}
            />
          </TabPanel>
          <TabPanel header="Alcohol detection">
            <AlcoholFetureSetOptions
              data={Alcoholdata}
              onDelete={DeleteFeaturesetOption}
              onEdit={onSubmitEditFSoption}
              header={header}
              filters={filters}
            />
          </TabPanel>
        </TabView>
      ) : (
        <div className="mt-6">
          <PreloaderList />
        </div>
      )}
    </>
  );
}

export default FeaturesetOption;
