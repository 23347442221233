import React, { useContext, useEffect, useRef, useState } from "react";
// import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
// import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
import Cookies from "js-cookie";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { AppContext } from "context/AppContext";
import { FiLoader } from "react-icons/fi";

const Schedule = ({ close }) => {
  const [vehicles, setVehicles] = useState([]);
  const token = Cookies.get("token");
  const user_uuid = Cookies.get("user_uuid");
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [titleValid, setTitleValid] = useState(true);
  const [vehicleValid, setVehicleValid] = useState(true);
  const [eventValid, setEventValid] = useState(true);
  const [contactValid, setContactValid] = useState(true);
  const toastRef = useRef(null);
  const [selectedVehicleNames, setSelectedVehicleNames] = useState([]);
  const [selectedEventNames, setSelectedEventNames] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const repeat_types = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
  ];

  const [repeatType, setRepeatType] = useState(null);
  const [repeatTypeValid, setRepeatTypeValid] = useState(true);
  const events = [
    { name: "Alarm", code: "ALM" },
    { name: "Break Data", code: "BRK" },
    { name: "Accelarator Cut Data", code: "ACC" },
    { name: "Limp Mode Data", code: "LMP" },
    { name: "Accident Data", code: "ACD" },
    { name: "DMS", code: "DMS" },
    { name: "Notification Data", code: "NTF" },
  ];
  const { updateReports } = useContext(AppContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getAllVehicles`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setVehicles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title.trim() === "") {
      setTitleValid(false);
    } else {
      setTitleValid(true);
    }

    // Check and set the vehicle validity
    if (!selectedVehicles || selectedVehicles.length === 0) {
      setVehicleValid(false);
    } else {
      setVehicleValid(true);
    }

    // Check and set the event validity
    if (!selectedEvents || selectedEvents.length === 0) {
      setEventValid(false);
    } else {
      setEventValid(true);
    }

    // Check and set the contact validity
    if (!selectedContacts || selectedContacts.length === 0) {
      setContactValid(false);
    } else {
      setContactValid(true);
    }

    if (!repeatType || repeatType.length === 0) {
      setRepeatTypeValid(false);
    } else {
      setRepeatTypeValid(true);
    }

    // Check if any field is invalid
    if (
      !titleValid ||
      !vehicleValid ||
      !eventValid ||
      !contactValid ||
      !repeatTypeValid
    ) {
      toastRef.current.show({
        severity: "warn",
        summary: "Fill Required Fields",
        detail: "Please fill in all the required details.",
        life: 3000,
      });
      return;
    }
    const requestData = {
      title: title,
      sch_type: repeatType,
      vehicle_id: selectedVehicles,
      events: selectedEvents,
      contact: selectedContacts,
    };
    axios
      .post(`${process.env.REACT_APP_AWS_URL2}/addSchReports`, requestData, {
        headers: { authorization: `${token}` },
      })
      .then((res) => {
        setSubmitted(true);
        updateReports();
        setTimeout(() => {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: "Report scheduled successfully" || res.data?.message,
            life: 1000,
          });
        }, 2000);
        setTimeout(() => {
          close();
        }, 3000);
      })
      .catch((error) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 3000,
        });
      });
  };

  const vehicleOptions = () => {
    const optionsArray = [];
    vehicles.forEach((el) => {
      optionsArray.push({
        key: el.vehicle_id,
        name: el.vehicle_name + " [" + el.vehicle_reg_number + "]",
        code: el.vehicle_id,
      });
    });
    return optionsArray;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AWS_URL1}/getContact`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        setContacts(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token, user_uuid]);

  const contactOptions = () => {
    const optionsArray = [];
    const emailContacts = contacts.filter(
      (item) => item.contact_type === "Email"
    );

    emailContacts.forEach((el) => {
      optionsArray.push({
        key: el.contact_id,
        label: el.contact_name,
        code: el.contact_info,
      });
    });
    return optionsArray;
  };
  return (
    <>
      <Toast ref={toastRef} className="toast-custom" position="top-right" />
      <div>
        <p className="text-right text-xs text-red-500">
          All Fields Are Required<span> **</span>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mt-8">
            <span className="p-float-label">
              <InputText
                id="title"
                name="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleValid(e.target.value.trim() !== "");
                }}
                className={`border py-1.5 pl-2 text-sm ${
                  !titleValid ? "border-red-600" : ""
                }`}
              />
              <label
                htmlFor="title"
                className="text-gray-600 dark:text-gray-150"
              >
                Title
              </label>
            </span>
            {!titleValid && (
              <small className="text-red-500">Title is required.</small>
            )}
          </div>
          <div className="grid grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div className="mt-8 flex-auto">
              <span className="p-float-label">
                <MultiSelect
                  value={selectedVehicleNames}
                  options={vehicleOptions()}
                  onChange={(e) => {
                    setSelectedVehicleNames(e.value);
                    setSelectedVehicles(
                      e.target.value.map((vehicle) => vehicle.code)
                    );

                    setVehicleValid(e.target.value.length > 0);
                  }}
                  filter
                  optionLabel="name"
                  className={`h-10 border pl-2 text-sm dark:bg-gray-900 ${
                    !vehicleValid ? "border-red-600" : ""
                  }`}
                />

                <label
                  htmlFor="vehicle"
                  className="text-gray-600 dark:text-gray-150"
                >
                  Select Vehicles
                </label>
              </span>
              {!vehicleValid && (
                <small className="text-red-500">Vehicle is required.</small>
              )}
            </div>
            <div className="mt-8">
              <span className="p-float-label">
                <MultiSelect
                  value={selectedEventNames}
                  options={events}
                  onChange={(e) => {
                    setSelectedEventNames(e.value);
                    setSelectedEvents(
                      e.target.value.map((event) => event.code)
                    );
                    setEventValid(e.target.value);
                  }}
                  selectAllLabel="Select All Events"
                  optionLabel="name"
                  className={`h-10 border pl-2 text-sm dark:bg-gray-900 ${
                    !eventValid ? "border-red-600" : ""
                  }`}
                />
                <label
                  htmlFor="vehicle"
                  className="text-gray-600 dark:text-gray-150"
                >
                  Select Events
                </label>
              </span>
              {!eventValid && (
                <small className="text-red-500">Event is required.</small>
              )}
            </div>
            <div className="mt-8">
              <span className="p-float-label">
                <Dropdown
                  value={selectedContacts}
                  options={contactOptions()}
                  onChange={(e) => {
                    setSelectedContacts(e.target.value);
                    setContactValid(e.target.value);
                  }}
                  optionLabel="label"
                  optionValue="code"
                  className={`h-10 border pl-2 text-sm${
                    !contactValid ? "border-red-600" : ""
                  }`}
                />
                <label
                  htmlFor="vehicle"
                  className="text-gray-600 dark:text-gray-150"
                >
                  Select Recipients
                </label>
              </span>
              {!contactValid && (
                <small className="text-red-500">Recipient is required.</small>
              )}
            </div>
            <div className="mt-8">
              <span className="p-float-label">
                <Dropdown
                  value={repeatType}
                  options={repeat_types}
                  optionLabel="label"
                  onChange={(e) => {
                    setRepeatType(e.target.value);
                    setRepeatTypeValid(e.target.value);
                  }}
                  placeholder="Select Repeat Type"
                  className={`h-10 border pl-2 text-sm${
                    !repeatTypeValid && "border-red-600"
                  }`}
                />
                <label
                  htmlFor="repeat_type" // This should match the id of the Dropdown
                  className="text-gray-600 dark:text-gray-150"
                >
                  Repeat Type
                </label>
              </span>
              {!repeatTypeValid && (
                <small className="text-red-500">Repeat Type is required.</small>
              )}
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className={`rounded-lg bg-blue-500 px-2 py-1 text-left text-sm text-white dark:bg-gray-150 dark:font-bold dark:text-blue-800 ${
                submitted
                  ? "cursor-not-allowed bg-blue-200 text-blue-500"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              } `}
              disabled={submitted}
              type="submit"
            >
              {submitted ? (
                <>
                  Scheduling...{" "}
                  <FiLoader className="inline-block animate-spin text-gray-100" />
                </>
              ) : (
                <>Schedule</>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Schedule;
