import { Skeleton } from "primereact/skeleton";
import React from "react";

const Maps = () => {
  return (
    <>
      <Skeleton width="20rem" height="8rem" className="mb-3"></Skeleton>
      <Skeleton width="20rem" height="8rem" className="mb-3"></Skeleton>
      <Skeleton width="20rem" height="8rem" className="mb-3"></Skeleton>
      <Skeleton width="20rem" height="8rem" className="mb-3"></Skeleton>
    </>
  );
};

export default Maps;
