const TruckIcon = (rotation) => {
  // Ensure the rotation is in the range 0 to 360 degrees
  const validRotation = ((rotation.rotation % 360) + 360) % 360;
  // console.log("validRotation:::::", validRotation);
  const svgString = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
<g id="OBJECTS" transform="rotate(${
    validRotation + 90
  } 250 250)"> <!-- Rotate the truck icon based on prop -->
  <g>
    <g>
       <!-- <rect x="" y="" style="fill:#181B1C;" width="19.247" height="38.268"/> -->
      <path style="fill:#575756;" d="M100.09,221.299H51.06c-0.589,0-1.071-0.482-1.071-1.071v-48.207c0-0.589,0.482-1.071,1.071-1.071
        h49.03c0.589,0,1.071,0.482,1.071,1.071v48.207C101.161,220.817,100.679,221.299,100.09,221.299z"/>
      <g>
        <rect x="102.82" y="130.848" style="fill:#f47c62 ;" width="240.315" height="120.237"/>
      </g>
      <rect x="65.95" y="173.123" style="fill:#F48E30;" width="34.181" height="46.48"/>
      <rect x="52.567" y="173.123" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 118.5172 392.726)" style="fill:#EE692E;" width="13.383" height="46.48"/>
      <path style="fill:#262B2D;" d="M64.495,218.632h-7.796c-0.394,0-0.713-0.319-0.713-0.713V175.21c0-0.394,0.319-0.713,0.713-0.713
        h7.796c0.394,0,0.713,0.319,0.713,0.713v42.709C65.208,218.313,64.889,218.632,64.495,218.632z"/>
      <g>
        <path style="fill:#181B1C;" d="M61.03,217.919V175.21c0-0.394,0.319-0.713,0.713-0.713h-5.044c-0.394,0-0.713,0.319-0.713,0.713
          v42.709c0,0.394,0.319,0.713,0.713,0.713h5.044C61.349,218.632,61.03,218.313,61.03,217.919z"/>
      </g>
      <rect x="82.01" y="173.123" style="fill:#F6A032;" width="18.121" height="46.48"/>
      <g>
        <line style="fill:none;stroke:#262B2D;stroke-width:0.5353;stroke-miterlimit:10;" x1="64.469" y1="219.606" x2="64.469" y2="223.385"/>
        <g>
          <path style="fill:#575756;" d="M64.763,229.126h-0.575c-0.361,0-0.657-0.382-0.657-0.849v-5.553
            c0-0.467,0.296-0.849,0.657-0.849h0.575V229.126z"/>
          <rect x="64.188" y="221.876" style="fill:#706F6F;" width="0.575" height="7.251"/>
        </g>
      </g>
      <g>
        <line style="fill:none;stroke:#262B2D;stroke-width:0.5353;stroke-miterlimit:10;" x1="64.469" y1="173.124" x2="64.469" y2="169.344"/>
        <g>
          <path style="fill:#575756;" d="M64.763,163.603h-0.575c-0.361,0-0.657,0.382-0.657,0.849v5.553
            c0,0.467,0.296,0.849,0.657,0.849h0.575V163.603z"/>
          <rect x="64.188" y="163.603" style="fill:#706F6F;" width="0.575" height="7.251"/>
        </g>
      </g>
    </g>
    <line style="fill:none;stroke:#575756;stroke-width:0.5353;stroke-linecap:round;stroke-miterlimit:10;" x1="56.851" y1="179.813" x2="56.851" y2="193.492"/>
    <line style="fill:none;stroke:#575756;stroke-width:0.5353;stroke-linecap:round;stroke-miterlimit:10;" x1="56.851" y1="200.116" x2="56.851" y2="213.795"/>
  </g>
</g>
</svg>`;
  return svgString;
};
export default TruckIcon;

/*
const TruckIcon = ({ rotation }:any) => {
  const svgString = `
  <svg xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:dc="http://purl.org/dc/elements/1.1/" id="svg1106" viewBox="0 0 464.05 970.73" version="1.0">
    <defs id="defs1108">
      <linearGradient id="linearGradient1257">
        <stop id="stop1258" stop-color="#ff0a31" offset="0"/>
        <stop id="stop1259" stop-color="#c60200" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient1253">
        <stop id="stop1254" stop-color="#6b5635" offset="0"/>
        <stop id="stop1255" stop-color="#fff" offset="1"/>
      </linearGradient>
      <radialGradient id="radialGradient2569" fx="208.68" fy="107.57" xlink:href="#linearGradient1253" gradientUnits="userSpaceOnUse" cy="106.33" cx="209.53" gradientTransform="matrix(.98293 0 0 1.0174 -141.49 -36.096)" r="88.778"/>
      <radialGradient id="radialGradient2572" fx="538.56" fy="113.43" xlink:href="#linearGradient1253" gradientUnits="userSpaceOnUse" cy="113.83" cx="537.79" gradientTransform="matrix(1.0195 0 0 .98091 -141.49 -36.096)" r="110.57"/>
      <linearGradient id="linearGradient2575" y2="313.28" xlink:href="#linearGradient1257" gradientUnits="userSpaceOnUse" x2="193.83" gradientTransform="matrix(.90597 0 0 1.1038 -141.49 -36.096)" y1="313.28" x1="156.18"/>
      <linearGradient id="linearGradient2578" y2="310.08" xlink:href="#linearGradient1257" gradientUnits="userSpaceOnUse" x2="681" gradientTransform="matrix(.88920 0 0 1.1246 -141.49 -36.096)" y1="310.08" x1="643.23"/>
      <radialGradient id="radialGradient2584" xlink:href="#linearGradient1257" gradientUnits="userSpaceOnUse" cy="346.26" cx="565.16" gradientTransform="matrix(.66292 0 0 1.5085 -141.49 -36.096)" r="420.99"/>
    </defs>
    <g transform="rotate(${rotation}, 232.025, 485.365)"> <!-- Center of rotation based on SVG viewBox -->
      <path id="path1228" d="m231.35 2.0918c175.61 6.1579 228.86 30.31 209.41 288.39-3.05 7.13-6.11 14.26-9.16 21.38 3.6 119.1 7.2 238.92 0 357.29 11.69 12.77 6.11 29.86 9.16 44.79 5 38.45 7.12 92.73 2.04 145.57-8.5 118.7-132.17 107.84-202.57 109.93-78.45-1.75-184.26 23.84-213.76-108.92-2.884-61.2-3.604-110.16-0.005-177.12 2.714-5.09 5.429-10.17 8.143-15.26-3.277-117.65-7.274-233.14-1.194-357.26-3.599-9.84-7.198-19.68-10.797-29.51-13.196-254.33 36.949-270.4 208.73-279.28z" fill-rule="evenodd" stroke="#000" stroke-width=".75" fill="url(#radialGradient2584)"/>
      <path id="path1234" d="m431.6 98.096c-37.32-74.309-122.49-83.47-203.59-85.506-86.18 3.733-154.04 5.429-197.47 96.7 11.872-34.606 0.336-104.84 198.49-108.92 179.84 3.3981 190.69 51.58 202.57 97.726z" fill-rule="evenodd" stroke="#000" stroke-width=".75" fill="#bf0000"/>
      <path id="path1229" d="m62.092 234.5c80.418-91.28 260.59-99.08 338.97 1.02-5.43 47.5-10.86 95-16.28 142.51-73.97-16.63-190.7-34.27-304.36 5.09-6.113-49.54-12.22-99.08-18.328-148.62z" fill-rule="evenodd" fill-opacity=".83065" stroke="#000" stroke-width="1pt"/>
      <path id="path1230" d="m93.648 706.82c99.082 10.52 191.03 16.96 278.91-5.09v145.56c-87.2 58.02-192.73 54.97-276.88 3.05-0.675-47.84-1.353-95.68-2.032-143.52z" fill-rule="evenodd" fill-opacity=".83065" stroke="#000" stroke-width="1pt"/>
      <path id="path1232" d="m440.25 292.01c23.24 10.69 27.15 35.63 19.85 41.23-8.14-0.85-20.36-12.39-28.5-14.25-0.17-2.38-0.34-4.75-0.51-7.13 3.39-6.45 5.77-13.4 9.16-19.85z" fill-rule="evenodd" fill-opacity=".75" stroke="#000" stroke-width="1pt" fill="url(#linearGradient2578)"/>
      <path id="path1233" d="m25.21 289.54c-23.691 9.66-28.686 34.4-21.645 40.31 8.173-0.49 20.884-11.47 29.102-12.98 0.274-2.37 0.548-4.73 0.822-7.1-3.106-6.59-5.173-13.64-8.279-20.23z" fill-rule="evenodd" fill-opacity=".75" stroke="#000" stroke-width="1pt" fill="url(#linearGradient2575)"/>
      <path id="path1235" d="m381.72 45.164c-24.94 6.107-20.87 19.34 3.06 39.699 19.51 14.081 31.38 16.457 32.57-5.09-1.7-12.554-30.37-33.252-35.63-34.609z" fill-opacity=".75" fill="url(#radialGradient2572)" fill-rule="evenodd"/>
      <path id="path1236" d="m83.154 44.172c25.346 4.076 22.356 17.594 0.157 39.816-18.311 15.608-29.957 18.932-32.878-2.447 0.679-12.65 27.588-35.593 32.721-37.369z" fill-rule="evenodd" fill-opacity=".75" stroke="#000" stroke-width="1pt" fill="url(#radialGradient2569)"/>
      <path id="path1237" d="m49.877 270.13c-9.161 147.26-7.125 283.32-2.036 425.49 4.072 25.11 7.126 51.24 11.197 76.34 12.385 69.91 24.817 133.93 47.743 196.97-1.788 18.79-4.274 37.57-8.671 55.41-11.79 42.16-20.27 89.9-43.41 130.23-12.69 23.98-28.86 46.32-45.42 67.88-47.02 49.54-92.98 97.24-143.07 145.55-4.764 4.67-10.29 9.24-15.38 13.79 46.95 16.2 90.53 35.98 138.85 52.55 14.68 4.38 31.58 4.86 47.35 3.21 10.67-1.15 21.15-3.58 30.36-8.91 5.11-2.77 9.62-6.69 13.76-10.44 24.66-23.52 47.96-47.42 72.43-71.65 43.23-42.86 88.04-84.97 134.61-122.91-41.27-10.77-93.37-25.7-139.07-33.43-4.61-16.56-7.71-33.52-11.45-50.27-17.29-77.71-32.47-157.23-51.43-235.73-10.78-43.43-22.63-85.61-35.63-128.17-4.06-15.42-10.42-30.31-18.86-44.06-15.98-25.24-44.14-35.67-67.84-51.69-34.48-23.51-79.92-39.66-126.29-44.07-13.48-1.9-28.36-2.29-41.83-1.43z" fill-opacity=".75" stroke="#000" stroke-width="1pt" fill="url(#linearGradient2575)"/>
    </g>
  </svg>
`;
  return svgString;
};
export default TruckIcon

*/
