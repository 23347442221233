import React from "react";
import ReactApexChart from "react-apexcharts";

const GraphFuel = ({ data }) => {
  const options = {
    series: [
      {
        name: "Fuel",
        // data: [
        //   1.4, 2, 2.5, 1.5, 2.5, 2.8, 2.5, 2.9, 2.3, 2.6, 3.8, 1.5, 2.5, 2.8, 3.8,
        //   1.7, 2.2, 2.4, 2.1, 2.7, 2.9, 3.2, 2.6, 2.3, 2.8, 2.5, 3.1, 2.7, 3.4, 2.6, 2.9
        // ]

        data: data.fuel,
      },
    ],
    chart: {
      height: 350,
      type: "area",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#281997"],
    stroke: {
      width: [2],
    },
    xaxis: {
      type: "datetime",
      categories: data.time,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#281997",
        },
        labels: {
          style: {
            colors: "#281997",
          },
          formatter: function (val) {
            return `${val.toFixed(1)} Lit`; // Format to 1 decimal and append "Liters"
          },
        },
        title: {
          text: "Fuel",
          style: {
            color: "#281997",
          },
        },
      },
    ],
    tooltip: {
      enabled: true, // Enable the tooltip
      // x: {
      //   format: 'yyyy-MM-dd', // Format the x-axis date in tooltip
      // },
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)}liters`; // Custom formatting for y-axis value (Fuel in liters)
        },
        title: {
          formatter: () => "Fuel: ", // Tooltip title for y-axis
        },
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="area"
      height={350}
    />
  );
};

export default GraphFuel;
