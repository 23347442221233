import React, { useState, useEffect, useRef } from "react";
import { Badge } from "primereact/badge";
import Chart from "react-apexcharts";
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";

function QuickReportView() {
  const [reportData, setReportData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [summary, setSummary] = useState(null);

  const tableRef = useRef(null);
  const downloadPDF = useReactToPrint({
    content: () => tableRef.current,
    documentTitle: headers?.title,
  });
  // Helper function to convert seconds into HH:MM:SS format
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    return `${String(hours).padStart(2, "0")} Hr : ${String(minutes).padStart(
      2,
      "0"
    )} Mins : ${String(seconds).padStart(2, "0")} Sec`;
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("quickReportData");
    if (storedData) {
      const parseData = JSON.parse(storedData);
      setReportData(parseData);
      setHeaders({
        title: parseData[0],
        fromDate: parseData[1],
        toDate: parseData[2],
      });

      const mergeSumVehi = parseData[4].map((item1) => {
        const vehicleData = parseData[5].find(
          (item) => item.vehicle_id === item1.vehicle_id
        );
        const eventData = parseData[3].filter(
          (item) => item.vehicle_id === item1.vehicle_id
        );
        return { ...item1, vehicleData, eventData };
      });

      setSummary(mergeSumVehi);
    }

    // Set up event listener to remove localStorage data when tab is closed
    const handleUnload = () => {
      localStorage.removeItem("quickReportData");
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      // Also remove localStorage item when the component unmounts
      localStorage.removeItem("reportData");
    };
  }, []);

  if (!reportData) {
    return <div className="m-10">No Data Found!</div>;
  }

  const SummaryContent = ({ summary }) => {
    return (
      <>
        {summary.map((item, index) => (
          <div key={index} className="box align-self-center bg-white p-3">
            <div className="flex justify-between">
              <h1 className="mr-3">{item.vehicleData.vehicle_name}</h1>
              <Badge
                value={item.vehicleData.vehicle_reg_number}
                severity="contrast"
              ></Badge>
            </div>
            <h4 className="mt-4">
              <b>Total KM:</b> {item.totalDistance.toFixed(2)} KM
            </h4>
            <h4>
              <b>Duration:</b> {formatDuration(item.timeDuration)}
            </h4>

            <div className="mt-4">
              <ChartComponent eventData={item.eventData} />
            </div>
          </div>
        ))}
      </>
    );
  };

  const ChartComponent = (eventData) => {
    const options = {
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: eventData.eventData.map((item) => item.event),
        title: {
          text: "Event Types",
        },
      },
      yaxis: {
        title: {
          text: "Event Count",
        },
      },
    };

    const series = [
      {
        name: "Event Count",
        data: eventData.eventData.map((item) => item.eventCount),
      },
    ];

    return <Chart options={options} series={series} type="bar" height={350} />;
  };

  return (
    <div className="m-10" ref={tableRef}>
      <h4 className="text-center text-2xl text-gray-700">Summary Report</h4>
      <h6 className="mt-3 text-center text-xl">
        <b>Title:</b> {headers?.title}
      </h6>
      <div className="mt-3 flex justify-center gap-8">
        <p className="mt-2">
          <b>From Date:</b> {formatDate(headers?.fromDate)}
        </p>
        <p className="mt-2">
          <b>To Date:</b> {formatDate(headers?.toDate)}
        </p>
      </div>

      <div className="flex items-center justify-center">
        <div className="mt-4 grid w-[60%] grid-cols-1 gap-8 md:grid-cols-1">
          <SummaryContent summary={summary} />
        </div>
      </div>

      <div className="text-center">
        <Button
          label="Download"
          icon="pi pi-cloud-download"
          severity="secondary"
          className="mx-2 my-4 rounded-md bg-blueSecondary px-4 py-2 text-white dark:!bg-gray-100 dark:!text-gray-850"
          onClick={downloadPDF}
        />
      </div>
    </div>
  );
}

export default QuickReportView;
