import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import TruckIcon from "./TruckIcon";
import Cookies from "js-cookie";
// import iconLocation from "../../../../assets/img/3.png"
import iconLocation from "../../../../assets/img/icons/cvn.png";

const TripPlayBack = ({ tripId }) => {
  const token = Cookies.get("token");
  const [route, setRoute] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [speed, setSpeed] = useState(50);
  const [infoWindowContent, setInfoWindowContent] = useState({
    speed: 0,
    tripId: "",
    time: null,
  });
  // setIsLimp(!isLimp)}
  const [isLimp, setIsLimp] = useState(false);
  const [markerPosition, setMarkerPosition] = useState();
  const [activeTab, setActiveTab] = useState("10x");
  const [rotation, setRotation] = useState(0);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // const [selectedMarker, setSelectedMarker] = useState(null);
  const svgString = TruckIcon({ rotation });
  const encodeSvgToBase64 = (svg) => `data:image/svg+xml;base64,${btoa(svg)}`;
  const encodedIcon = encodeSvgToBase64(svgString);
  const mapRef = useRef(null); // Reference for the map instance

  const containerStyle = {
    width: "100%",
    height: "350px",
    borderRadius: "10px",
    border: "2px solid #8994BC",
  };

  useEffect(() => {
    const getTripData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_AWS_URL2}/tripDetails/${tripId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const points = response.data.data.location_data.map((location) => ({
          lat: location.latitude,
          lng: location.longitude,
          speed: location.speed,
          time: location.Timestamp,
        }));

        const alertsData1 = response.data.data.alerts.map((data) => {
          const { latitude, longitude } = data;
          return {
            lat: latitude,
            lng: longitude,
            jsonData: JSON.parse(data.jsonData),
          };
        });
        setMarkerPosition(alertsData1);
        setRoute(points);
        setCurrentPosition(points[0]);

        if (points.length > 0) {
          updateZoomLevel(points); // Fit the map bounds based on the points
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    getTripData();
  }, [tripId, token]);

  useEffect(() => {
    let interval;

    if (isAnimating && route.length > 0) {
      const intervalTime = 1000 / (speed / 10);

      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < route.length) {
            setCurrentPosition(route[nextIndex]);
            setInfoWindowContent((prevData) => {
              const time = route[nextIndex]?.time;
              const formattedTime = time
                ? new Date(time * 1000)
                    .toLocaleString("en-US", {
                      day: "2-digit",
                      month: "short",
                      year: "2-digit",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })
                    .replace(",", "")
                : null;

              return {
                ...prevData,
                speed: route[nextIndex]?.speed || prevData.speed,
                time: formattedTime,
              };
            });
            return nextIndex;
          } else {
            setIsAnimating(false);
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, intervalTime);
    }

    return () => clearInterval(interval);
  }, [isAnimating, route, speed]);

  const handleSliderChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    setCurrentIndex(newIndex);
    setCurrentPosition(route[newIndex]);
    setInfoWindowContent((prevData) => ({
      ...prevData,
      speed: route[newIndex]?.speed,
    }));
  };

  const getMapCenter = () => {
    if (route.length === 0) return { lat: 0, lng: 0 };

    const lats = route.map((point) => point.lat);
    const lngs = route.map((point) => point.lng);

    const latMin = Math.min(...lats);
    const latMax = Math.max(...lats);
    const lngMin = Math.min(...lngs);
    const lngMax = Math.max(...lngs);

    return {
      lat: (latMin + latMax) / 2,
      lng: (lngMin + lngMax) / 2,
    };
  };

  const updateZoomLevel = (points) => {
    if (mapRef.current && points.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      points.forEach((point) => {
        bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
      });
      mapRef.current.fitBounds(bounds); // Automatically fit map to route bounds
    }
  };

  useEffect(() => {
    if (route.length > 1 && currentIndex < route.length - 1) {
      const from = route[currentIndex];
      const to = route[currentIndex + 1];
      const angle = calculateBearing(from, to);
      setRotation(angle);
    }
  }, [currentIndex, route]);

  const calculateBearing = (from, to) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    const toDegrees = (radians) => radians * (180 / Math.PI);

    const lat1 = toRadians(from.lat);
    const lon1 = toRadians(from.lng);
    const lat2 = toRadians(to.lat);
    const lon2 = toRadians(to.lng);

    const dLon = lon2 - lon1;

    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const bearing = Math.atan2(y, x);

    return (toDegrees(bearing) + 360) % 360;
  };
  const getFormatedTime = () => {
    const time = selectedMarker.jsonData.timestamp;
    const formattedTime = time
      ? new Date(time * 1000)
          .toLocaleString("en-US", {
            day: "2-digit",
            month: "short",
            year: "2-digit",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "")
      : null;

    return formattedTime;
  };
  return (
    <div>
      <div className="relative z-0 w-full">
        <div className="absolute left-[10px] top-[90px] z-10 w-fit rounded-md bg-white p-2 shadow-md">
          <div className="flex-col">
            <div>
              <p className="text-xs">Speed</p>
              <p className="text-xs font-bold">
                {infoWindowContent.speed} km/hr
              </p>
            </div>
            <div>
              <p className="text-xs">Time</p>
              <p className="text-xs font-bold">
                {infoWindowContent.time ? `${infoWindowContent.time}` : "_"}
              </p>
            </div>
          </div>
        </div>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={getMapCenter()}
            onLoad={(map) => (mapRef.current = map)} // Capture map reference
          >
            {route.length > 0 && (
              <Polyline
                path={route}
                options={{
                  strokeColor: "#0075ff",
                  strokeOpacity: 0.8,
                  strokeWeight: 5,
                }}
              />
            )}

            {currentPosition && (
              <Marker
                position={currentPosition}
                icon={{
                  url: encodedIcon,
                  scaledSize: new window.google.maps.Size(50, 50),
                  anchor: new window.google.maps.Point(25, 25),
                }}
              />
            )}
            {isLimp && (
              <>
                {markerPosition?.map((data) => (
                  // <></>
                  <Marker
                    position={{ lat: data.lat, lng: data.lng }}
                    icon={{
                      url: iconLocation,
                      scaledSize: new window.google.maps.Size(25, 25),
                      anchor: new window.google.maps.Point(25, 25),
                    }}
                    onClick={() => setSelectedMarker(data)} // Set selected marker on click
                  ></Marker>
                ))}

                {selectedMarker && (
                  // <div className="w-[900px]">
                  <InfoWindow
                    position={{
                      lat: selectedMarker.lat,
                      lng: selectedMarker.lng,
                    }}
                    onCloseClick={() => setSelectedMarker(null)} // Close the InfoWindow
                  >
                    <div className="w-[200px] space-y-2">
                      <div className="flex w-full">
                        <p className="w-1/2 font-bold">Alert Type:</p>
                        <p className="w-1/2">
                          {selectedMarker?.jsonData?.data?.alert_type}
                        </p>
                      </div>
                      <div className="flex w-full">
                        <p className="w-1/2 font-bold">Severity:</p>
                        <p className="w-1/2">
                          {selectedMarker?.jsonData?.data?.severity}
                        </p>
                      </div>
                      <div className="flex w-full">
                        <p className="w-1/2 font-bold">Speed:</p>
                        <p className="w-1/2">{`${selectedMarker?.jsonData?.data?.speed} Km/hr`}</p>
                      </div>
                      <div className="flex w-full">
                        <p className="w-1/2 font-bold">Time:</p>
                        <p className="w-1/2">{getFormatedTime()}</p>
                      </div>
                    </div>
                  </InfoWindow>
                  // </div>
                )}
              </>
            )}

            {/* <Marker
                position={markerPosition}
                icon={{
                  url: iconLocation,
                  scaledSize: new window.google.maps.Size(50, 50),
                  anchor: new window.google.maps.Point(25, 25),
                }} 
              /> */}
          </GoogleMap>
        </LoadScript>
      </div>
      <div>
        {" "}
        <div className="mt-3 flex items-center justify-between gap-4">
          <button
            onClick={() => setIsAnimating(!isAnimating)}
            className={`rounded-lg px-2 py-0.5 text-sm text-white ${
              isAnimating
                ? "bg-red-600 hover:bg-red-700"
                : "bg-green-600 hover:bg-green-700"
            } transition-colors duration-300`}
          >
            {isAnimating ? "Pause" : "Play"}
          </button>
          <button
            onClick={() => {
              setIsAnimating(false);
              setCurrentIndex(0);
              setCurrentPosition(route[0]);

              setInfoWindowContent((prevData) => {
                const data = {
                  ...prevData,
                  speed: route[0].speed,
                };
                return data;
              });
            }}
            className="rounded-lg bg-blue-600 px-2 py-0.5 text-sm text-white transition-colors duration-300 hover:bg-blue-700"
          >
            Reset
          </button>
          <button
            onClick={() => setIsLimp(!isLimp)}
            className={`w-auto text-nowrap rounded-lg px-2 py-0.5 text-center text-sm text-white ${
              isLimp
                ? "bg-red-600 hover:bg-red-700"
                : "bg-green-600 hover:bg-green-700"
            } transition-colors duration-300`}
          >
            {isLimp ? "Hide Alerts" : "Show Alerts"}
          </button>
          {/* Speed Control Buttons activeTab, setActiveTab*/}
          <button
            onClick={() => {
              setSpeed(50);
              setActiveTab("50x");
            }}
            className={`flex rounded-lg ${
              activeTab === "50x" ? "bg-blue-600" : "bg-blue-400 text-gray-800"
            }  px-2 py-0.5 text-sm text-white transition-colors duration-300`}
          >
            1x&nbsp;Speed
          </button>
          <button
            onClick={() => {
              setSpeed(100);
              setActiveTab("100x");
            }}
            className={`flex rounded-lg ${
              activeTab === "100x" ? "bg-blue-600" : "bg-blue-400 text-gray-800"
            }  px-2 py-0.5 text-sm text-white transition-colors duration-300`}
          >
            2x&nbsp;Speed
          </button>
          <button
            onClick={() => {
              setSpeed(200);
              setActiveTab("200x");
            }}
            className={`flex rounded-lg ${
              activeTab === "200x" ? "bg-blue-600" : "bg-blue-400 text-gray-800"
            }  px-2 py-0.5 text-sm text-white transition-colors duration-300`}
          >
            3x&nbsp;Speed
          </button>
          <input
            type="range"
            min="0"
            max={route.length - 1}
            value={currentIndex}
            onChange={handleSliderChange}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default TripPlayBack;
